import React from 'react';
import { Link } from 'react-router-dom';
import { WrenchIcon, TrashIcon } from '@heroicons/react/20/solid'; // Removed ChevronDownIcon

// Helper function to combine class names
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Main actions for Configure
function Actions({ actions }) {
  return (
    <div>
      {actions.map((action) => {
        const buttonClasses = classNames(
          'w-full block text-center text-sm px-4 py-2 rounded-md transition-colors duration-200',
          'bg-violet-500 text-white hover:bg-violet-600 flex items-center justify-center' // Updated to violet theme
        );
        
        return (
          <div key={action.name} className="mb-4 last:mb-0">
            <div className="mt-4">
              {action.link ? (
                <Link to={action.link} className={buttonClasses}>
                  {action.name}
                </Link>
              ) : (
                <button onClick={action.action} className={buttonClasses}>
                  {action.name}
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

// Additional actions
function AdditionalActions({ moreActions }) {
  return (
    <div>
      {moreActions.map((action) => {
        const buttonClasses = classNames(
          'w-full block text-center text-sm px-4 py-2 rounded-md transition',
          'border border-gray-300 text-black hover:border-gray-400 flex items-center justify-center' // Same style as 'More' button
        );

        return (
          <div key={action.name} className="mb-2 last:mb-0">
            {action.link ? (
              <Link to={action.link} className={buttonClasses}>
                {action.name === 'Delete' && (
                  <TrashIcon className="h-3 w-3 text-red-500 mr-2" />
                )}
                {action.name}
              </Link>
            ) : (
              <button onClick={action.action} className={buttonClasses}>
                {action.name === 'Delete' && (
                  <TrashIcon className="h-4 w-4 text-red-500 mr-2" />
                )}
                {action.name}
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default function ActionsCard({ actions }) {
  // Filter the actions for Configure, and other actions for the dropdown
  const mainActions = actions.filter(action => ['Open Editor'].includes(action.name));
  const moreActions = actions.filter(action => ['Play', 'Change name', 'Change status', 'Delete'].includes(action.name));

  // Sort so that Play is first, Delete is last, and other actions in between
  moreActions.sort((a, b) => {
    if (a.name === 'Play') return -1;
    if (b.name === 'Delete') return -1;
    if (a.name === 'Delete') return 1;
    return 0;
  });

  return (
    <div className="bg-white shadow-sm rounded-xl px-5 pt-3 pb-5">
      <div className="text-xs uppercase font-semibold text-gray-400">
        Quick Actions
      </div>
      <Actions actions={mainActions} />
      {moreActions.length > 0 && (
        <div className="mt-4">
          <AdditionalActions moreActions={moreActions} />
        </div>
      )}
    </div>
  );
}