// services/campaignService.js

import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path based on your file structure

/**
 * Fetch campaigns by user ID and status.
 *
 * @param {string} companyId - The company's ID.
 * @param {string} status - The status of the campaigns to fetch (e.g., 'live').
 * @returns {Promise<Array>} - A promise that resolves to an array of campaign objects.
 */
export async function fetchCampaignsByUserAndStatus(companyId, status) {
  try {
    const q = query(
      collection(db, 'campaign'),
      where('companyId', '==', companyId),
      where('status', '==', status)
    );
    const querySnapshot = await getDocs(q);
    const records = [];

    querySnapshot.forEach((doc) => {
      records.push({ id: doc.id, ...doc.data() });
    });

    return records;
  } catch (error) {
    console.error('Error fetching campaigns: ', error);
    throw error; // Re-throw the error to be handled by the calling function
  }
}
