import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import ModalBasic from '../components/ModalBasic';
import { TrashIcon } from '@heroicons/react/20/solid';

export default function DeleteCampaign({ campaignId }) {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleDelete = async () => {
    const docRef = doc(db, "campaign", campaignId);
    try {
      await deleteDoc(docRef);
      navigate("/");
    } catch (error) {
      console.error("Error deleting campaign:", error);
    }
  };

  // Helper function to open modal with delay
  const openDeleteModal = () => {
    // Add 300ms delay before showing modal
    setTimeout(() => {
      setModalOpen(true);
    }, 300);
  };

  return (
    <>
      <div className="mt-6">
        <button
          onClick={openDeleteModal}
          className="w-full flex items-center justify-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-red-700 hover:bg-red-50 transition-colors duration-200"
        >
          <TrashIcon className="h-4 w-4 mr-2" />
          Delete Campaign
        </button>
      </div>

      <ModalBasic
        id="delete-campaign-modal"
        title="Delete Campaign"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      >
        <div className="p-6">
          <div className="space-y-6">
            <p className="text-sm text-gray-600">
              Are you sure you want to delete this campaign? This action cannot be undone.
            </p>
            <div className="flex flex-wrap justify-end space-x-2">
              <button
                className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="btn-sm bg-red-500 hover:bg-red-600 text-white"
                onClick={() => {
                  handleDelete();
                  setModalOpen(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </ModalBasic>
    </>
  );
}
