import React, { useState, useEffect, useRef } from 'react';
import WebFont from 'webfontloader';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

const fontList = [
  'Default',  // Default option
  'Roboto', 
  'Open Sans', 
  'Lato', 
  'Montserrat', 
  'Oswald',
  'Poppins',      
  'Raleway',      
  'Merriweather', 
  'Nunito',       
  'Ubuntu',       
  'PT Sans',      
  'Playfair Display', 
  'Dancing Script', 
  'Cinzel',        
  'Fira Sans',     
  'Crimson Text',  
  'Source Sans Pro', 
  'Bebas Neue',    
  'Rubik',         
  'Josefin Sans',  
];

const CustomFontPicker = ({ label, subtitle, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  
  const { styles, attributes } = usePopper(buttonRef.current, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4], // 4px gap
        },
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      },
    ],
  });

  // Load all fonts except the default
  useEffect(() => {
    WebFont.load({
      google: {
        families: fontList.filter(font => font !== 'Default')
      },
    });
  }, []);

  const handleFontChange = (font) => {
    setIsOpen(false);
    if (font === 'Default') {
      onChange(null);
    } else {
      onChange(font);
    }
  };

  return (
    <div className="relative inline-block text-left w-full">
      <label className="block text-sm font-medium text-gray-900 dark:text-gray-100">{label}</label>
      {subtitle && (
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">{subtitle}</p>
      )}
      <div>
        <button 
          ref={buttonRef}
          type="button"
          onClick={() => setIsOpen(!isOpen)} 
          className="relative mt-2 block w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm flex items-center justify-between"
        >
          <span style={{ fontFamily: value !== 'Default' ? value : 'inherit' }}>
            {value || 'Default'}
          </span>
          <span className="absolute right-3 text-gray-300 flex items-center">
            ▼
          </span>
        </button>

        {isOpen && (
          <Portal>
            <div 
              className="fixed inset-0 bg-transparent"
              onClick={() => setIsOpen(false)}
            />
            <div 
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="bg-white dark:bg-gray-800 shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-[9999]"
            >
              <div className="max-h-60 overflow-auto" style={{ width: buttonRef.current?.offsetWidth }}>
                {fontList.map((font, index) => (
                  <div
                    key={index}
                    className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100 dark:hover:bg-gray-700"
                    onClick={() => handleFontChange(font)}
                    style={{ fontFamily: font !== 'Default' ? font : 'inherit' }}
                  >
                    {font}
                  </div>
                ))}
              </div>
            </div>
          </Portal>
        )}
      </div>
    </div>
  );
};

const Portal = ({ children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  return mounted ? createPortal(
    children,
    document.body
  ) : null;
};

export default CustomFontPicker;