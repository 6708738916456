import React, { useState, useEffect } from 'react';
import { FiLink, FiCode, FiCheck } from 'react-icons/fi';
import { 
  FaInstagram as FaInstagramIcon, 
  FaXTwitter as FaXTwitterIcon, 
  FaFacebookF as FaFacebookFIcon, 
  FaLinkedinIn as FaLinkedinInIcon 
} from 'react-icons/fa6';
import ModalBasic from '../components/ModalBasic';

const ButtonContent = ({ text, isCopied }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(isCopied);
  }, [isCopied]);

  const checkIconStyle = {
    position: 'absolute',
    left: 0,
    opacity: isAnimating ? 1 : 0,
    transform: `translateY(${isAnimating ? '0' : '10px'})`,
    transition: 'all 500ms ease-in-out'
  };

  const textStyle = {
    marginLeft: isAnimating ? '24px' : '0',
    transition: 'all 500ms ease-in-out'
  };

  return (
    <span className="relative inline-flex items-center justify-center" style={{ width: '120px' }}>
      <span style={checkIconStyle}>
        <FiCheck className="text-green-400" />
      </span>
      <span style={textStyle}>
        {text}
      </span>
    </span>
  );
};

const Embed = ({ campaignId }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleCopy = async (href, index) => {
    try {
      await navigator.clipboard.writeText(href);
      setCopiedIndex(index);
      setTimeout(() => setCopiedIndex(null), 3000);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleModalCopy = async (href) => {
    try {
      await navigator.clipboard.writeText(href);
      setSelectedPlatform(prev => ({ ...prev, copied: true }));
      
      setTimeout(() => {
        setSelectedPlatform(prev => prev ? { ...prev, copied: false } : null);
      }, 3000);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleModalOpen = (isOpen) => {
    setModalOpen(isOpen);
    if (!isOpen) {
      setSelectedPlatform(prev => prev ? { ...prev, copied: false } : null);
    }
  };

  const renderModalContent = () => {
    if (!selectedPlatform) return null;

    if (selectedPlatform.name === 'Embed') {
      return (
        <div className="p-6">
          <div className="space-y-6">
            <div className="space-y-2">
              <p className="text-sm font-medium text-gray-700">Add this code to your website:</p>
              <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                <pre className="text-sm text-gray-600 whitespace-pre-wrap break-all">
                  {`<div style="position: relative; padding-bottom: 56.25%; height: 0;">
  <iframe 
    src="https://app.smilesflow.com/play/${campaignId}"
    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
    allow="web-share"
  ></iframe>
</div>`}
                </pre>
              </div>
              <button 
                onClick={() => handleModalCopy(`<div style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe src="https://app.smilesflow.com/play/${campaignId}" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" allow="web-share"></iframe></div>`)}
                className="w-full text-center mt-3 inline-flex items-center justify-center px-3 py-2 text-sm font-semibold text-white bg-violet-500 hover:bg-violet-600 rounded-md transition-colors duration-200"
              >
                <ButtonContent 
                  text={selectedPlatform.copied ? 'Code Copied!' : 'Copy Code'} 
                  isCopied={selectedPlatform.copied} 
                />
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="p-6">
        <div className="space-y-6">
          <div className="space-y-4">
            <p className="text-sm font-medium text-gray-700">
              Share your game on {selectedPlatform.name}:
            </p>
            <ol className="space-y-3">
              {selectedPlatform.steps.map((step, index) => (
                <li key={index} className="flex items-start">
                  <span className="flex items-center justify-center w-6 h-6 rounded-full bg-violet-100 text-violet-600 text-sm font-semibold mr-3">
                    {index + 1}
                  </span>
                  <span className="text-sm text-gray-600">{step}</span>
                </li>
              ))}
            </ol>
            <button 
              onClick={() => handleModalCopy(`https://app.smilesflow.com/play/${campaignId}`)}
              className="w-full text-center mt-4 inline-flex items-center justify-center px-3 py-2 text-sm font-semibold text-white bg-violet-500 hover:bg-violet-600 rounded-md transition-colors duration-200"
            >
              <ButtonContent 
                text={selectedPlatform.copied ? 'Link Copied!' : 'Copy Link'} 
                isCopied={selectedPlatform.copied} 
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const platforms = [
    {
      name: 'Copy Link',
      icon: <FiLink className="text-lg text-gray-600" />,
      href: `https://app.smilesflow.com/play/${campaignId}`,
      steps: [],
    },
    {
      name: 'Embed',
      icon: <FiCode className="text-lg text-gray-600" />,
      href: `<iframe src="https://app.smilesflow.com/play/${campaignId}" allow="web-share"></iframe>`,
      steps: [],
    },
    {
      name: 'Instagram',
      icon: <FaInstagramIcon className="text-lg text-[#E4405F]" />,
      href: `https://app.smilesflow.com/play/${campaignId}`,
      steps: [
        "Copy your game link",
        "Add to a story using the link functionality",
      ],
    },
    {
      name: 'X',
      icon: <FaXTwitterIcon className="text-lg text-black" />,
      href: `https://app.smilesflow.com/play/${campaignId}`,
      steps: [
        "Copy your game link",
        "Create a new post",
        "Paste the link - it will automatically create a preview card"
      ],
    },
    {
      name: 'Facebook',
      icon: <FaFacebookFIcon className="text-lg text-[#1877F2]" />,
      href: `https://app.smilesflow.com/play/${campaignId}`,
      steps: [
        "Copy your game link",
        "Create a new post",
        "Paste the link - it will automatically create a preview card",
        "Add your message and post"
      ],
    },
    {
      name: 'LinkedIn',
      icon: <FaLinkedinInIcon className="text-lg text-[#0A66C2]" />,
      href: `https://app.smilesflow.com/play/${campaignId}`,
      steps: [
        "Copy your game link",
        "Create a new post",
        "Paste the link - it will automatically create a preview card",
        "Add your message and post"
      ],
    }
  ];

  const handlePlatformClick = (platform, index) => {
    if (platform.name === 'Copy Link') {
      handleCopy(platform.href, index);
    } else {
      setSelectedPlatform(platform);
      setTimeout(() => {
        handleModalOpen(true);
      }, 300);
    }
  };

  return (
    <>
      <div className="bg-white shadow-sm rounded-xl pl-5 pt-5 pb-7 mt-5">
        <div className="text-xs uppercase font-semibold text-gray-400">
          Embed / Share
        </div>
        <div className="overflow-x-auto scrollbar-hide pb-2">
          <div className="flex gap-5 mt-6 min-w-max">
            {platforms.map((platform, index) => (
              <button
                key={platform.name}
                onClick={() => handlePlatformClick(platform, index)}
                className={`flex flex-col items-center ${index === platforms.length - 1 ? 'mr-5' : ''}`}
              >
                <div className={`w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center mb-2 
                  transition-all duration-200 ease-in-out
                  ${copiedIndex === index ? 'bg-green-100' : 'hover:bg-gray-200'}`}>
                  {platform.name === 'Copy Link' ? (
                    copiedIndex === index ? (
                      <FiCheck className="text-lg text-green-600" />
                    ) : (
                      <FiLink className="text-lg text-gray-600" />
                    )
                  ) : (
                    platform.icon
                  )}
                </div>
                <span className="text-gray-600 text-xs transition-all duration-200 ease-in-out whitespace-nowrap">
                  {platform.name}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>

      <ModalBasic 
        id="platform-share-modal"
        title={selectedPlatform ? `Share on ${selectedPlatform.name}` : ''}
        modalOpen={modalOpen}
        setModalOpen={handleModalOpen}
      >
        {renderModalContent()}
      </ModalBasic>
    </>
  );
};

export default Embed;
