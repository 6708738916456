import React, { useState } from 'react';
import FormInput from './FormInput';
import { Switch } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ShareUrlForm = ({ handleChange, options }) => {
  const [shareOverrideEnabled, setShareOverrideEnabled] = useState(options.shareOverrideEnabled || false);

  const handleSwitchChange = (enabled) => {
    setShareOverrideEnabled(enabled);
    const event = {
      target: {
        name: 'shareOverrideEnabled',
        value: enabled,
      },
    };
    handleChange(event);
  };

  return (
    <div className="space-y-4">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <label className="text-sm font-medium text-gray-900">
            Custom Share URL
          </label>
          <p className="text-sm text-gray-500">
            Override where users are directed when sharing your game
          </p>
        </div>
        <Switch
          checked={shareOverrideEnabled}
          onChange={handleSwitchChange}
          className={classNames(
            shareOverrideEnabled ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Enable custom share URL</span>
          <span
            aria-hidden="true"
            className={classNames(
              shareOverrideEnabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      </div>

      {/* Form Section */}
      <div className={classNames(
        'rounded-lg border border-gray-200 transition-all duration-500 ease-in-out relative overflow-hidden',
        !shareOverrideEnabled && 'opacity-50'
      )}>
        <div className="p-4">
          <div className="relative">
            <FormInput
              label="Share URL"
              name="share_url"
              onChange={handleChange}
              placeholder="https://yoururl.com"
              options={options}
              value={options.share_url || ''}
              mandatory={shareOverrideEnabled}
              maxLength={2048}
              subtitle="The URL where users will be directed when sharing your game"
            />

            {/* Overlay when disabled - matched with CallToActionForm */}
            {!shareOverrideEnabled && (
              <div className="absolute inset-0 bg-white/60 rounded-lg transition-all duration-500 ease-in-out z-10" />
            )}
          </div>
        </div>
      </div>

      {/* Help Text */}
      <div className="flex items-center gap-2 text-sm text-gray-500">
        <InformationCircleIcon className="h-4 w-4 flex-shrink-0" />
        <span>By default, shares will link to your game page</span>
      </div>
    </div>
  );
};

export default ShareUrlForm;