import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import ModalBasic from '../components/ModalBasic';
import ChangeStatusModal from './ChangeStatusModal';
import UpdateCampaignName from './updateCampaignName';
import { statuses } from '../data/StatusColours';
import { gameTypes } from '../data/GameTypes';
import { motion } from 'framer-motion';
import { Popover, Transition } from '@headlessui/react';

const getGameTypeColors = (gameTypeId) => {
  const colorMap = {
    "pindrop": {
      bg: "bg-red-100 dark:bg-red-900/60",
      icon: "text-red-500 dark:text-red-400",
    },
    "hint": {
      bg: "bg-amber-100 dark:bg-amber-900/60",
      icon: "text-amber-500 dark:text-amber-400",
    },
    "quiz": {
      bg: "bg-blue-100 dark:bg-blue-900/60",
      icon: "text-blue-500 dark:text-blue-400",
    },
    "mapselect": {
      bg: "bg-emerald-100 dark:bg-emerald-900/60",
      icon: "text-emerald-500 dark:text-emerald-400",
    },
    "snake": {
      bg: "bg-purple-100 dark:bg-purple-900/60",
      icon: "text-purple-500 dark:text-purple-400",
    }
  };
  return colorMap[gameTypeId] || colorMap["quiz"];
};

export default function CampaignHeader({ title, name, status, gameType, onStatusChange, onNameChange }) {
  const navigate = useNavigate();
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [localStatus, setLocalStatus] = useState(status);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Update local status when prop changes
  useEffect(() => {
    setLocalStatus(status);
  }, [status]);

  const handleStatusChange = async (newStatus) => {
    setIsTransitioning(true);
    setLocalStatus(newStatus);
    
    await new Promise(resolve => setTimeout(resolve, 300));
    onStatusChange(newStatus);
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 200);
  };

  const handleNameChange = (newName) => {
    setTimeout(() => {
      onNameChange(newName);
      setIsNameModalOpen(false);
    }, 200);
  };

  const handleNameClick = () => {
    setTimeout(() => {
      setIsNameModalOpen(true);
    }, 500);
  };

  return (
    <>
      {/* Go back button */}
      <div className="flex items-center pb-4">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-sm font-medium text-violet-500 hover:text-violet-600 transition-colors duration-200"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" /> Go back
        </button>
      </div>

      {/* Main Content */}
      <div className="flex flex-col gap-y-4 py-2">
        {/* Title Row with Game Type Icon and Status */}
        <div className="flex items-center justify-between">
          {/* Left side: Icon and Title */}
          <div className="flex items-center gap-x-4">
            {/* Game Type Icon */}
            {(() => {
              const gameTypeData = gameTypes.find(type => type.id === gameType);
              const colors = getGameTypeColors(gameType);
              
              return gameTypeData?.icon && (
                <div className={`p-2.5 rounded-lg ${colors.bg}`}>
                  <gameTypeData.icon className={`w-5 h-5 ${colors.icon}`} />
                </div>
              );
            })()}

            {/* Campaign Name */}
            <div className="relative group">
              <button 
                onClick={handleNameClick}
                className="text-left hover:text-gray-900 transition-colors duration-200"
              >
                <h1 className="text-3xl font-semibold leading-7 text-gray-900">
                  {title}
                </h1>
              </button>
              <div className="absolute left-0 bottom-full mb-2 hidden w-max rounded-md bg-gray-800 text-white text-xs px-2 py-1 group-hover:block">
                Change name
              </div>
            </div>
          </div>

          {/* Updated Right side: Status Button */}
          <Popover className="relative">
            {({ open, close }) => (
              <>
                <Popover.Button
                  as={motion.button}
                  className={`
                    inline-flex items-center gap-x-2 rounded-md px-4 py-2.5 text-sm font-semibold
                    ${statuses[localStatus]} hover:bg-opacity-75 shadow-md
                    transition-all duration-200 ease-in-out
                    hover:scale-105 hover:shadow-lg
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500
                  `}
                  animate={{ opacity: isTransitioning ? 0.7 : 1 }}
                  transition={{ duration: 0.2 }}
                >
                  <span className="h-2.5 w-2.5 rounded-full bg-current" aria-hidden="true" />
                  <span className="capitalize">{localStatus}</span>
                  <svg 
                    className={`h-5 w-5 text-current opacity-60 transform transition-transform duration-200 ${open ? 'rotate-180' : ''}`} 
                    viewBox="0 0 20 20" 
                    fill="currentColor"
                  >
                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                  </svg>
                </Popover.Button>

                <Transition
                  enter="transition duration-200 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-150 ease-in"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Popover.Panel 
                    className="absolute right-0 z-10 mt-2"
                    static
                  >
                    <ChangeStatusModal
                      currentStatus={status}
                      onChange={handleStatusChange}
                      close={close}
                    />
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>

        {/* Name (not clickable) */}
        <p className="text-xl text-gray-700">{name}</p>
      </div>

      {/* Name Change Modal */}
      <ModalBasic
        id="campaign-name-modal"
        title="Change Campaign Name"
        modalOpen={isNameModalOpen}
        setModalOpen={setIsNameModalOpen}
      >
        <UpdateCampaignName 
          currentName={title}
          onClose={() => setIsNameModalOpen(false)}
          onUpdateCampaignName={handleNameChange}
        />
      </ModalBasic>
    </>
  );
}
