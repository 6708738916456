import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { statuses } from '../data/StatusColours';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ChangeStatusSelector({ currentStatus, onChange, close }) {
  const statusDescriptions = {
    ready: "Campaign is ready for embedding",
    live: "Actively collecting responses",
    complete: "Campaign has finished collecting",
    draft: "Still being configured"
  };

  const handleChange = (value) => {
    onChange(value);
    close(); // Close the popover after selection
  };

  return (
    <div className="w-72 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
      <RadioGroup 
        value={currentStatus} 
        onChange={handleChange}
        className="divide-y divide-gray-100"
      >
        <div className="space-y-0">
          {Object.keys(statuses).map((statusKey) => (
            <RadioGroup.Option
              key={statusKey}
              value={statusKey}
              className={({ checked }) =>
                classNames(
                  'relative w-full cursor-pointer p-4',
                  'transition-all duration-200 ease-in-out',
                  'first:rounded-t-lg last:rounded-b-lg',
                  'hover:bg-gray-50',
                  checked ? statuses[statusKey] : 'bg-white'
                )
              }
            >
              {({ checked }) => (
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className={classNames(
                          'font-medium',
                          checked ? 'text-gray-900' : 'text-gray-700'
                        )}
                      >
                        <span className="capitalize">{statusKey}</span>
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="p"
                        className={`mt-1 ${checked ? 'text-gray-800' : 'text-gray-500'}`}
                      >
                        {statusDescriptions[statusKey]}
                      </RadioGroup.Description>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'flex-shrink-0 ml-3',
                      checked ? 'text-gray-900' : 'text-gray-400'
                    )}
                  >
                    <span 
                      className={classNames(
                        'h-2.5 w-2.5 rounded-full',
                        'inline-block',
                        checked ? 'bg-current' : 'bg-gray-300'
                      )} 
                    />
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}