import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useUser } from './userContext';
import DefaultLoading from './common/DefaultLoading';
import SubscriptionModal from './Onboarding/SubscriptionModal';
import { getAuth, signOut } from 'firebase/auth';

const PrivateRoute = ({ children }) => {
  const { user, loading, subscriptionStatus, refreshUser } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    let modalTimeout;

    const checkSubscriptionStatus = () => {
      if (loading || subscriptionStatus === undefined || !user) {
        return;
      }

      setIsReady(true);

      const shouldShowModal = 
        location.search.includes('session_id') || 
        subscriptionStatus === 'inactive' ||
        !['active', 'trialing', 'payment_overdue'].includes(subscriptionStatus);

      if (shouldShowModal) {
        modalTimeout = setTimeout(() => {
          setShowModal(true);
        }, 500);
      } else {
        console.log('Should not show modal. Status:', subscriptionStatus);
        setShowModal(false);
      }
    };

    checkSubscriptionStatus();

    return () => {
      clearTimeout(modalTimeout);
    };
  }, [user, subscriptionStatus, location.search, refreshUser, loading]);

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('User signed out');
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/');
  };

  if (loading || !isReady) {
    return <DefaultLoading />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  console.log('Rendering PrivateRoute. showModal:', showModal);

  return (
    <>
      {children}

      <SubscriptionModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleSignOut={handleSignOut}
        onClose={handleModalClose}
      />
    </>
  );
};

export default PrivateRoute;
