import React, { useState, useEffect } from 'react';
import { HexColorPicker } from "react-colorful";
import { usePopper } from 'react-popper';
import { adjustColor } from '../utils/themeUtils';
import Slider from '@mui/material/Slider';

const ColorPicker = ({ 
  label, 
  color, 
  onChange,
  opacity,
  onOpacityChange,
  showOpacity = false,
  isActive, 
  onToggle,
  showPreview = true
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'preventOverflow', options: { padding: 8 } },
    ],
  });

  const ensureValidHex = (colorValue) => {
    if (!colorValue) return '#000000';
    if (typeof colorValue !== 'string') return '#000000';
    return colorValue.startsWith('#') ? colorValue : `#${colorValue}`;
  };

  const [localColor, setLocalColor] = useState(ensureValidHex(color));

  useEffect(() => {
    setLocalColor(ensureValidHex(color));
  }, [color]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isActive && 
          popperElement && 
          !popperElement.contains(event.target) &&
          !referenceElement.contains(event.target)) {
        onToggle();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isActive, onToggle, popperElement, referenceElement]);

  const handleColorChange = (newColor) => {
    const validColor = ensureValidHex(newColor);
    setLocalColor(validColor);
    onChange(validColor);
  };

  const handleInputChange = (e) => {
    const newColor = e.target.value;
    if (/^#[0-9A-F]{6}$/i.test(newColor)) {
      setLocalColor(newColor);
      onChange(newColor);
    }
  };

  const handleOpacityChange = (_, newValue) => {
    onOpacityChange?.(newValue / 100);
  };

  const safeColor = ensureValidHex(localColor);
  const opacityPercentage = Math.round((opacity === undefined || opacity === null ? 1 : opacity) * 100);
  const previewOpacity = opacity === undefined || opacity === null ? 1 : opacity;

  return (
    <div>
      <div className="flex items-center gap-3">
        {showPreview && (
          <button
            type="button"
            ref={setReferenceElement}
            className="w-8 h-8 rounded-md border shadow-sm cursor-pointer flex-shrink-0 transition-all hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-indigo-500"
            style={{ 
              backgroundColor: safeColor,
              border: `1px solid ${adjustColor(safeColor, -20)}`,
              opacity: previewOpacity
            }}
            onClick={onToggle}
            aria-label="Toggle color picker"
          />
        )}
        <label className="text-sm font-medium text-gray-700 flex-grow">{label}</label>
      </div>
      
      {isActive && (
        <div 
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-50 animate-popup"
        >
          <div className="p-3 bg-white rounded-lg shadow-lg border space-y-4">
            <HexColorPicker 
              color={safeColor} 
              onChange={handleColorChange}
            />
            <input
              type="text"
              value={safeColor}
              onChange={handleInputChange}
              className="w-full px-2.5 py-1.5 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="#000000"
            />
            
            {showOpacity && (
              <div className="space-y-1">
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-600">Opacity</span>
                  <span className="text-sm text-gray-500">{opacityPercentage}%</span>
                </div>
                <Slider
                  size="small"
                  value={opacityPercentage}
                  onChange={handleOpacityChange}
                  min={0}
                  max={100}
                  sx={{
                    color: 'rgb(99 102 241)',
                    '& .MuiSlider-thumb': {
                      width: 14,
                      height: 14,
                      backgroundColor: '#fff',
                      border: '2px solid currentColor',
                      '&:hover, &.Mui-focusVisible': {
                        boxShadow: '0 0 0 8px rgba(99, 102, 241, 0.16)',
                      },
                    },
                    '& .MuiSlider-track': {
                      height: 4,
                    },
                    '& .MuiSlider-rail': {
                      height: 4,
                      opacity: 0.2,
                      backgroundColor: '#bfbfbf',
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorPicker; 