import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../utils/Transition';
import ModalBasic from './ModalBasic';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getAuth } from 'firebase/auth';
import { motion, AnimatePresence } from 'framer-motion';

function DropdownHelp({ align = 'right', position = 'below' }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const auth = getAuth();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const handleFeedbackSubmit = async (e) => {
    console.log('Submit clicked'); // Debug log
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      console.log('Checking auth...'); // Debug log
      if (!auth.currentUser?.email) {
        throw new Error('You must be logged in to submit feedback');
      }

      console.log('Checking feedback text...'); // Debug log
      if (!feedbackText.trim()) {
        throw new Error('Please enter your feedback');
      }

      console.log('Submitting feedback:', {
        type: feedbackType,
        message: feedbackText.trim(),
        userEmail: auth.currentUser.email,
        createdAt: new Date(),
        status: 'new'
      });

      await addDoc(collection(db, 'feedback'), {
        type: feedbackType,
        message: feedbackText.trim(),
        userEmail: auth.currentUser.email,
        createdAt: new Date(),
        status: 'new'
      });

      console.log('Feedback submitted successfully'); // Debug log

      setIsSubmitting(false);
      setShowThankYou(true);
      
      setTimeout(() => {
        setShowThankYou(false);
        setFeedbackOpen(false);
        setFeedbackText('');
        setDropdownOpen(false);
      }, 2000);

    } catch (err) {
      console.error('Feedback submission error:', err);
      setError(err.message);
      setIsSubmitting(false);
    }
  };

  const getModalTitle = () => {
    switch (feedbackType) {
      case 'game_idea': return 'Submit Game Idea';
      case 'feedback': return 'Send Feedback';
      case 'issue': return 'Report Issue';
      default: return '';
    }
  };

  // Helper function to open modal with delay
  const openFeedbackModal = (type) => {
    setFeedbackType(type);
    setDropdownOpen(false);
    // Add 500ms delay before showing modal
    setTimeout(() => {
      setFeedbackOpen(true);
    }, 500);
  };

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center hover:bg-gray-100 lg:hover:bg-gray-200 dark:hover:bg-gray-700/50 dark:lg:hover:bg-gray-800 rounded-full ${dropdownOpen && 'bg-gray-200 dark:bg-gray-800'}`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Need help?</span>
        <svg
          className="fill-current text-gray-500/80 dark:text-gray-400/80"
          width={16}
          height={16}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9 7.5a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0v-4ZM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
          <path
            fillRule="evenodd"
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm6-8A6 6 0 1 1 2 8a6 6 0 0 1 12 0Z"
          />
        </svg>
      </button>

      <Transition
        className={`origin-${position === 'above' ? 'bottom' : 'top'}-${align} z-10 absolute ${
          position === 'above' ? 'bottom-full mb-1' : 'top-full mt-1'
        } min-w-44 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700/60 py-1.5 rounded-lg shadow-lg overflow-hidden ${
          align === 'right' ? 'right-0' : 'left-0'
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown}>
          <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase pt-1.5 pb-2 px-3">
            Need help?
          </div>
          <ul>
            {/* Submit Game Idea */}
            <li>
              <button
                className="w-full font-medium text-sm text-violet-500 hover:text-violet-600 dark:hover:text-violet-400 flex items-center py-1 px-3"
                onClick={() => openFeedbackModal('game_idea')}
              >
                <svg className="w-3 h-3 fill-current text-violet-500 shrink-0 mr-2" viewBox="0 0 12 12">
                  <path d="M11 1H1a1 1 0 00-1 1v6a1 1 0 001 1h3.586l2.707 2.707a1 1 0 001.414 0L11.414 9H11a1 1 0 001-1V2a1 1 0 00-1-1z" />
                </svg>
                <span>Submit Game Idea</span>
              </button>
            </li>

            {/* Feedback */}
            <li>
              <button
                className="w-full font-medium text-sm text-violet-500 hover:text-violet-600 dark:hover:text-violet-400 flex items-center py-1 px-3"
                onClick={() => openFeedbackModal('feedback')}
              >
                <svg className="w-3 h-3 fill-current text-violet-500 shrink-0 mr-2" viewBox="0 0 12 12">
                  <path d="M10.5 0h-9A1.5 1.5 0 000 1.5v9A1.5 1.5 0 001.5 12h9a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0010.5 0zM10 7L8.207 5.207l-3 3-1.414-1.414 3-3L5 2h5v5z" />
                </svg>
                <span>Send Feedback</span>
              </button>
            </li>

            {/* Report Issue */}
            <li>
              <button
                className="w-full font-medium text-sm text-violet-500 hover:text-violet-600 dark:hover:text-violet-400 flex items-center py-1 px-3"
                onClick={() => openFeedbackModal('issue')}
              >
                <svg className="w-3 h-3 fill-current text-violet-500 shrink-0 mr-2" viewBox="0 0 12 12">
                  <path d="M6 0C2.7 0 0 2.7 0 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 11c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm1-7.5v4c0 .6-.4 1-1 1s-1-.4-1-1v-4c0-.6.4-1 1-1s1 .4 1 1zm0 5.5c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1z" />
                </svg>
                <span>Report Issue</span>
              </button>
            </li>
          </ul>
        </div>
      </Transition>

      <ModalBasic
        id="feedback-modal"
        title={getModalTitle()}
        modalOpen={feedbackOpen}
        setModalOpen={setFeedbackOpen}
      >
        <div className="px-5 py-4">
          <AnimatePresence mode="wait">
            {showThankYou ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="flex flex-col items-center justify-center py-8"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.2 }}
                  className="w-16 h-16 mb-4 rounded-full bg-emerald-100 dark:bg-emerald-900/30 flex items-center justify-center"
                >
                  <svg className="w-8 h-8 text-emerald-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </motion.div>
                <motion.h3
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                  className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-2"
                >
                  Thank you!
                </motion.h3>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6 }}
                  className="text-gray-500 dark:text-gray-400 text-center"
                >
                  Your {feedbackType.replace('_', ' ')} has been submitted successfully.
                </motion.p>
              </motion.div>
            ) : (
              <motion.form
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onSubmit={handleFeedbackSubmit}
                className="space-y-3"
              >
                <div className="space-y-3">
                  <div>
                    <textarea
                      className="form-textarea w-full px-2 py-1 text-sm dark:text-slate-300 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 focus:border-violet-500 dark:focus:border-violet-500"
                      rows="4"
                      maxLength={1000}
                      value={feedbackText}
                      onChange={(e) => setFeedbackText(e.target.value)}
                      placeholder={
                        feedbackType === 'game_idea' ? "Describe your game idea..." :
                        feedbackType === 'feedback' ? "What's on your mind?" :
                        "Describe the issue you're experiencing..."
                      }
                    />
                    <div className="mt-1 text-xs text-slate-500 dark:text-slate-400 flex justify-end">
                      {feedbackText.length}/1000
                    </div>
                  </div>

                  {error && (
                    <div className="text-sm text-rose-500">
                      {error}
                    </div>
                  )}
                  
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      type="button"
                      className="btn-sm border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300"
                      onClick={() => {
                        setFeedbackOpen(false);
                        setFeedbackText('');
                        setError('');
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn-sm bg-violet-500 hover:bg-violet-600 text-white disabled:opacity-50 disabled:cursor-not-allowed"
                      disabled={isSubmitting || !feedbackText.trim()}
                      onClick={(e) => {
                        console.log('Submit button clicked'); // Debug log
                        handleFeedbackSubmit(e);
                      }}
                    >
                      {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </div>
              </motion.form>
            )}
          </AnimatePresence>
        </div>
      </ModalBasic>
    </div>
  );
}

export default DropdownHelp;