import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebase';
import { doc, getDoc, onSnapshot, updateDoc, collection, query, where, getDocs, orderBy } from 'firebase/firestore';

const UserContext = createContext();

const SELECTED_COMPANY_KEY = 'selectedCompanyId';

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [businessProfile, setBusinessProfile] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [hasActiveTrial, setHasActiveTrial] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [trialEndDate, setTrialEndDate] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [agencyId, setAgencyId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true); // Start loading when authentication state changes

      if (currentUser) {
        setUser(currentUser);
        await loadUserProfile(currentUser.uid);
      } else {
        resetUserState();
        setLoading(false); // Stop loading if no user is logged in
      }
    });

    return () => unsubscribeAuth();
  }, [navigate]);

  // Add effect to check localStorage on initial load
  useEffect(() => {
    const loadSelectedCompany = async () => {
      const selectedCompanyId = localStorage.getItem(SELECTED_COMPANY_KEY);
      if (selectedCompanyId && user) {
        // Only load if we have both a stored ID and a logged-in user
        const companyDoc = await getDoc(doc(db, 'company', selectedCompanyId));
        if (companyDoc.exists()) {
          setBusinessProfile({ id: companyDoc.id, ...companyDoc.data() });
        }
      }
    };

    loadSelectedCompany();
  }, [user]); // Depend on user to ensure we have authentication

  useEffect(() => {
    if (user?.companyId) {
      setAgencyId(user.companyId);
    } else if (businessProfile?.id && !businessProfile.parentCompany) {
      // If businessProfile is loaded and it's not a client (no parentCompany)
      setAgencyId(businessProfile.id);
    }
  }, [user, businessProfile]);

  const loadUserProfile = async (userId) => {
    let retryCount = 0;
    const maxRetries = 5;
    const retryDelay = 1000;

    const fetchWithRetry = async () => {
      try {
        console.log(`Attempting to fetch user profile (Attempt ${retryCount + 1}/${maxRetries})`);
        const userProfileData = await fetchUserProfile(userId);
        
        if (userProfileData) {
          setUserProfile(userProfileData);
          console.log('User profile found:', userProfileData);

          // Check localStorage for selected company before using user's companyId
          const selectedCompanyId = localStorage.getItem(SELECTED_COMPANY_KEY);
          const companyIdToUse = selectedCompanyId || userProfileData.companyId;

          if (companyIdToUse) {
            const businessDocRef = doc(db, 'company', companyIdToUse);
            const unsubscribeBusiness = onSnapshot(businessDocRef, async (snapshot) => {
              if (snapshot.exists()) {
                const businessData = { id: snapshot.id, ...snapshot.data() };
                setBusinessProfile(businessData);
                setIsOwner(businessData.ownerId === userId);
                
                // Find the parent agency for subscription checks
                const agencyId = businessData.parentCompany || businessData.id;
                const agencyDocRef = doc(db, 'company', agencyId);
                const agencySnapshot = await getDoc(agencyDocRef);
                
                if (agencySnapshot.exists()) {
                  // Setup subscription listener using the agency owner's ID
                  setupSubscriptionListener(agencySnapshot.data().ownerId);
                }

                // Setup campaigns listener
                const campaignsRef = collection(db, 'campaign');
                const campaignsQuery = query(
                  campaignsRef, 
                  where("companyId", "==", businessData.id),
                  orderBy("createdAt", "desc")
                );
                
                const unsubscribeCampaigns = onSnapshot(campaignsQuery, (snapshot) => {
                  const campaignsData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                  }));
                  setCampaigns(campaignsData);
                });

                checkActiveTrial(businessData);
                setLoading(false);

                return () => {
                  unsubscribeCampaigns();
                };
              } else {
                console.warn('No business data found for companyId:', companyIdToUse);
                retryOrError();
              }
            });
          } else {
            console.warn('No companyId found in user profile.');
            retryOrError();
          }
        } else {
          console.warn('No user profile data found.');
          retryOrError();
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        retryOrError();
      }
    };

    const retryOrError = () => {
      retryCount += 1;
      if (retryCount < maxRetries) {
        console.log(`Retrying to fetch user data... (${retryCount}/${maxRetries})`);
        setTimeout(fetchWithRetry, retryDelay);
      } else {
        console.error('Max retries reached. Redirecting to error.');
        setLoading(false); // Ensure loading is set to false to prevent indefinite loading
        navigate('/error');
      }
    };

    fetchWithRetry();
  };

  const setupSubscriptionListener = (ownerId) => {
    const subscriptionsRef = collection(db, 'users', ownerId, 'subscriptions');
    const allSubscriptionsQuery = query(subscriptionsRef);
    const activeSubscriptionsQuery = query(subscriptionsRef, where('status', 'in', ['trialing', 'active']));

    let hasEverHadSubscription = false;

    // First, check if the user has ever had a subscription
    getDocs(allSubscriptionsQuery).then((allDocs) => {
        hasEverHadSubscription = !allDocs.empty;
    }).catch((error) => {
        console.error('Error checking subscription history:', error);
    });

    const unsubscribe = onSnapshot(activeSubscriptionsQuery, async (snapshot) => {
      if (!snapshot.empty) {
        const doc = snapshot.docs[0];
        const subscriptionData = doc.data();
        console.log(doc.id, ' => ', subscriptionData);
        
        setSubscriptionStatus(subscriptionData.status);
        
        // Set subscription type based on the provided data structure
        if (subscriptionData.items && subscriptionData.items[0] && 
            subscriptionData.items[0].price && 
            subscriptionData.items[0].price.product && 
            subscriptionData.items[0].price.product.metadata && 
            subscriptionData.items[0].price.product.metadata.userType) {
          const userType = subscriptionData.items[0].price.product.metadata.userType;
          console.log('Subscription is type:', userType);
          setSubscriptionType(userType);
        } else {
          console.warn('Unable to determine subscription type from subscription data');
          setSubscriptionType('unknown');
        }
        
        if (subscriptionData.status === 'trialing' && subscriptionData.trial_end) {
          setTrialEndDate(subscriptionData.trial_end.toDate());
        } else {
          setTrialEndDate(null);
        }
        
        checkActiveTrial(subscriptionData.status, subscriptionData.trial_end);
      } else {
        if (hasEverHadSubscription) {
          setSubscriptionStatus('expired');
        } else {
          setSubscriptionStatus('inactive');
        }
        setSubscriptionType(null);
        setTrialEndDate(null);
        setHasActiveTrial(false);
      }
    }, (error) => {
      console.error('Error listening to subscription changes:', error);
      setSubscriptionStatus('error');
      setSubscriptionType(null);
      setTrialEndDate(null);
    });

    // Return the unsubscribe function
    return unsubscribe;
  };

  const checkActiveTrial = (status, trialEnd) => {
    if (status === 'trialing' && trialEnd) {
      const trialEndDate = trialEnd.toDate();
      const now = new Date();
      setHasActiveTrial(now < trialEndDate);
    } else {
      setHasActiveTrial(false);
    }
  };

  const resetUserState = () => {
    setUser(null);
    setUserProfile(null);
    setBusinessProfile(null);
    setIsOwner(false);
    setHasActiveTrial(false);
    setCampaigns([]);
    localStorage.removeItem(SELECTED_COMPANY_KEY); // Clear selected company on logout
  };

  const updateUserProfile = async (userId, data) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, data);
      console.log('User profile updated successfully');
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  const updateCompanyProfile = async (companyId, data) => {
    try {
      const companyDocRef = doc(db, 'company', companyId);
      await updateDoc(companyDocRef, data);
      console.log('Company profile updated successfully');
    } catch (error) {
      console.error('Error updating company profile:', error);
    }
  };

  const switchActiveClient = async (clientCompany) => {
    try {
      if (!clientCompany) {
        // If no client provided, revert to the user's original company
        localStorage.removeItem(SELECTED_COMPANY_KEY); // Clear selected company
        
        if (!userProfile?.companyId) {
          console.error('No original company ID available in user profile');
          return;
        }
  
        const agencyDoc = await getDoc(doc(db, 'company', userProfile.companyId));
        if (agencyDoc.exists()) {
          const businessData = { id: agencyDoc.id, ...agencyDoc.data() };
          setBusinessProfile(businessData);
          
          // Setup subscription listener using the agency owner's ID
          if (businessData.ownerId) {
            setupSubscriptionListener(businessData.ownerId);
          }
          
          // Reset campaigns for agency
          setupCampaignsListener(agencyDoc.id);
        } else {
          setBusinessProfile(null);
          setCampaigns([]);
        }
      } else {
        // Switch to the client's profile
        setBusinessProfile({ id: clientCompany.id, ...clientCompany });
        localStorage.setItem(SELECTED_COMPANY_KEY, clientCompany.id);
        
        // Get the parent agency for subscription checks
        if (!clientCompany.parentCompany) {
          console.error('No parent company ID available for client');
          return;
        }
  
        const agencyDoc = await getDoc(doc(db, 'company', clientCompany.parentCompany));
        if (agencyDoc.exists()) {
          const agencyData = agencyDoc.data();
          if (agencyData.ownerId) {
            setupSubscriptionListener(agencyData.ownerId);
          } else {
            console.error('No owner ID found for agency');
          }
        } else {
          console.error('Agency document not found');
        }
        
        // Reset campaigns for new client
        setupCampaignsListener(clientCompany.id);
      }
    } catch (error) {
      console.error('Error switching client:', error);
    }
  };

  const setupCampaignsListener = (companyId) => {
    const campaignsRef = collection(db, 'campaign');
    const campaignsQuery = query(
      campaignsRef, 
      where("companyId", "==", companyId),
      orderBy("createdAt", "desc")
    );
    
    return onSnapshot(campaignsQuery, (snapshot) => {
      const campaignsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCampaigns(campaignsData);
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        userProfile,
        businessProfile,
        isOwner,
        hasActiveTrial,
        loading,
        updateUserProfile,
        updateCompanyProfile,
        subscriptionStatus,
        subscriptionType,
        trialEndDate,
        campaigns,
        agencyId,
        switchActiveClient,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Helper function for fetching user profile data
const fetchUserProfile = async (userId) => {
  try {
    const userDocRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return userDoc.data();
    }
    console.error('No such user document found for userId:', userId);
    return null;
  } catch (error) {
    console.error('Error fetching user profile for userId:', userId, error);
    return null;
  }
};

export const useUser = () => useContext(UserContext);
