import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../userContext';
import CampaignsCard from '../partials/campaigns/CampaignsCard';
import Slideover from './Slideover';
import NewCampaign from './NewCampaign';
import DefaultLoading from '../common/DefaultLoading';
import { fetchCampaignsByUserAndStatus } from '../data/campaignService';
import { fetchCampaignStats } from '../data/statsService';
import DashboardCard04 from './DashboardChart';
import AISuggestions from '../partials/dashboard/AISuggestions';
import GetStartedWizard from './GetStartedWizard';

function DashboardSkeleton() {
  return (
    <div className="sm:px-4 pb-5 max-w-7xl mx-auto">
      {/* Header Skeleton */}
      <div className="mb-5 pb-3">
        <div className="w-48 h-8 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse" />
      </div>

      {/* Chart and Suggestions Grid */}
      <div className="grid grid-cols-12 gap-6 mb-4">
        {/* Chart Card Skeleton */}
        <div className="col-span-12 sm:col-span-8">
          <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-6">
            <div className="space-y-4">
              <div className="h-6 w-1/4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
              <div className="h-[300px] bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
            </div>
          </div>
        </div>
        
        {/* AI Suggestions Skeleton */}
        <div className="col-span-12 sm:col-span-4">
          <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-5">
            <div className="space-y-4">
              <div className="h-4 w-24 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
              <div className="h-10 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse" />
              <div className="h-4 w-24 bg-gray-200 dark:bg-gray-700 rounded animate-pulse mt-6" />
              <div className="h-[200px] bg-gray-200 dark:bg-gray-700 rounded-2xl animate-pulse" />
            </div>
          </div>
        </div>
      </div>

      {/* Live Campaigns Section Skeleton */}
      <div className="flex items-center justify-between mb-5 pt-5">
        <div className="flex items-center gap-3">
          <div className="w-24 h-8 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
          <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse" />
        </div>
      </div>

      {/* Campaign Cards Grid Skeleton */}
      <div className="grid grid-cols-12 gap-6">
        {[1, 2, 3].map((index) => (
          <div key={index} className="col-span-12 sm:col-span-6 lg:col-span-4">
            <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-5">
              <div className="space-y-4">
                {/* Header */}
                <div className="flex justify-between items-center">
                  <div className="w-32 h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                  <div className="w-8 h-8 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse" />
                </div>
                
                {/* Title */}
                <div className="w-3/4 h-6 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                
                {/* Stats */}
                <div className="grid grid-cols-2 gap-4">
                  <div className="h-16 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                  <div className="h-16 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                </div>
                
                {/* Footer */}
                <div className="flex justify-between items-center">
                  <div className="w-20 h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                  <div className="w-24 h-4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function Dashboard() {
  const { user, firstName, businessProfile } = useUser();
  const [campaigns, setCampaigns] = useState([]);
  const [isShowingAll, setIsShowingAll] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSlideoverOpen, setIsSlideoverOpen] = useState(false);
  const [campaignIds, setCampaignIds] = useState([]);

  useEffect(() => {
    async function loadCampaigns() {
      try {
        const liveCampaignsData = await fetchCampaignsByUserAndStatus(businessProfile.id, 'live');
        const completeCampaignsData = await fetchCampaignsByUserAndStatus(businessProfile.id, 'complete');

        const liveCampaignsWithStats = await Promise.all(
          liveCampaignsData.map(async (campaign) => {
            const stats = await fetchCampaignStats(campaign.id);
            return { ...campaign, stats };
          })
        );

        const sortedLiveCampaigns = liveCampaignsWithStats.sort(
          (a, b) => (b.stats.today?.playCount || 0) - (a.stats.today?.playCount || 0)
        );

        setCampaigns(sortedLiveCampaigns);
        setCampaignIds([...liveCampaignsData, ...completeCampaignsData].map(campaign => campaign.id));

      } catch (error) {
        console.error('Error loading campaigns: ', error);
        navigate('/');
      } finally {
        setIsLoading(false);
      }
    }

    if (user) {
      loadCampaigns();
    }
  }, [user, navigate]);

  const toggleSlideover = () => {
    setIsSlideoverOpen(!isSlideoverOpen);
  };

  const slideoverTitle = 'New campaign';

  const liveCampaignsCount = campaigns.length;
  const displayedCampaigns = isShowingAll ? campaigns : campaigns.slice(0, 3);

  if (isLoading) {
    return <DashboardSkeleton />;
  }

  return (
    <div className="sm:px-4 pb-5 max-w-7xl mx-auto">
      <div className="mb-5 pb-3">
        <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Dashboard</h1>
      </div>

      {/* Get Started Wizard */}
      {campaigns.length === 0 && (
        <GetStartedWizard 
          onCreateCampaign={toggleSlideover} 
        />
      )}

      <div className="grid grid-cols-12 gap-6 mb-4">
        {/* Chart Card */}
        <div className="col-span-12 sm:col-span-8 h-full">
          <div className="bg-white shadow-sm rounded-xl h-full">
            <DashboardCard04 campaignIds={campaignIds} />
          </div>
        </div>
        
        {/* AI Suggestions Card */}
        <div className="col-span-12 sm:col-span-4 h-full">
          <div className="h-full">
            <AISuggestions />
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mb-5 pt-5">
        <div className="flex items-center">
          <h2 className="text-2xl md:text-3xl text-gray-800 font-bold">Live</h2>
          <span className="ml-3 inline-flex items-center justify-center px-3 py-1 text-sm font-medium leading-none text-white bg-violet-500 rounded-full">
            {liveCampaignsCount}
          </span>
        </div>
        {liveCampaignsCount > 3 && !isShowingAll && (
          <button
            className="flex items-center gap-2 px-4 py-2 bg-violet-500 text-white hover:bg-violet-600 rounded-md transition-colors duration-200"
            onClick={() => setIsShowingAll(true)}
          >
            <span className="text-sm">Show all</span>
          </button>
        )}
      </div>

      {campaigns.length > 0 ? (
        <div className="grid grid-cols-12 gap-6">
          {displayedCampaigns.map((campaign) => (
            <CampaignsCard key={campaign.id} campaign={campaign} />
          ))}
        </div>
      ) : (
        <p className="text-gray-600">No live campaigns at the moment. Start creating your first campaign to see it here.</p>
      )}

      <Slideover isOpen={isSlideoverOpen} onClose={toggleSlideover} title={slideoverTitle}>
        <NewCampaign />
      </Slideover>
    </div>
  );
}

export default Dashboard;
