import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useUser } from '../userContext';

const EditClient = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [clientName, setClientName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { businessProfile, userProfile } = useUser();

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const clientDoc = await getDoc(doc(db, 'company', id));
        if (clientDoc.exists()) {
          setClientName(clientDoc.data().name);
        } else {
          setError('Client not found');
        }
      } catch (err) {
        setError('Error fetching client data');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClient();
  }, [id]);

  const isImpersonating = businessProfile?.id !== userProfile?.companyId;
  
  if (isImpersonating) {
    return (
      <div className="p-6 space-y-6 w-full">
        <div className="bg-yellow-100 w-full border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p className="font-bold">Not Applicable</p>
          <p>Client management is not available while impersonating a client.</p>
        </div>
      </div>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await updateDoc(doc(db, 'company', id), { name: clientName });
      setSuccess(true);
      setTimeout(() => {
        navigate('/clients');
      }, 1500);
    } catch (err) {
      setError('Error updating client');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="sm:px-4 pb-5 max-w-7xl mx-auto">
        <div className="flex items-center justify-center min-h-[200px]">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-violet-600"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="sm:px-4 pb-5 max-w-7xl mx-auto">
      {/* Header */}
      <div className="mb-5 pb-3 flex justify-between items-center">
        <div className="flex items-center gap-3">
          <Link
            to="/clients"
            className="inline-flex items-center justify-center p-2 rounded-lg text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-800"
          >
            <ArrowLeftIcon className="h-5 w-5" />
          </Link>
          <h1 className="text-2xl md:text-3xl text-gray-800 dark:text-white font-bold">Edit Client</h1>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-3">
        {/* Main Form */}
        <div className="md:col-span-2">
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="clientName" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Company Name
                </label>
                <input
                  type="text"
                  id="clientName"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  className="mt-1 block w-full rounded-lg border border-gray-300 dark:border-gray-600 py-2.5 px-3 shadow-sm focus:border-violet-500 focus:outline-none focus:ring-violet-500 dark:bg-gray-700 dark:text-white sm:text-sm"
                  required
                />
              </div>

              {error && (
                <div className="p-3 bg-red-50 dark:bg-red-900/30 rounded-lg">
                  <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
                </div>
              )}

              {success && (
                <div className="p-3 bg-green-50 dark:bg-green-900/30 rounded-lg">
                  <p className="text-sm text-green-600 dark:text-green-400">Client updated successfully!</p>
                </div>
              )}

              <div className="flex items-center justify-end gap-3">
                <Link
                  to="/clients"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-violet-600 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isLoading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      Updating...
                    </>
                  ) : (
                    'Update Client'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Sidebar - Could be used for additional options or information */}
        <div className="md:col-span-1">
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-4">Client Details</h2>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Update your client's company information here. Changes will be reflected across all associated campaigns and reports.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditClient;
