import React, { useEffect, useRef, useState } from 'react';
import RegenerateButton from '../campaign/RegenerateButton';
import { PulseLoader } from 'react-spinners';

const FormInput = ({
  type = "text",
  label,
  subtitle,
  name,
  onChange,
  placeholder,
  rows,
  options,
  maxLength = 50,
  mandatory = false,
  style = "",
  onOptionsUpdate,
  hideRegenerate = false
}) => {
  const [isRegenerating, setIsRegenerating] = useState(false);
  const hasValue = options[name] && options[name].trim().length > 0;
  const textareaRef = useRef(null);
  const [charCount, setCharCount] = useState(options[name]?.length || 0);

  const handleRegenerate = async (comment, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsRegenerating(true);

    try {
      const requestBody = {
        elementType: name,
        currentData: {
          [name]: options[name]
        },
        gameTypeId: 'hint',
        description: options.description || '',
        gameData: { options },
        comment: comment || ''
      };

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/app/regenerate-element`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to regenerate content');
      }

      onOptionsUpdate({
        ...options,
        [name]: data.generatedContent
      });

    } catch (error) {
      console.error('Error regenerating content:', error);
    } finally {
      setIsRegenerating(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setCharCount(value.length);
    onChange(e);
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [options[name]]);

  useEffect(() => {
    setCharCount(options[name]?.length || 0);
  }, [options[name]]);

  return (
    <div className={`${style}`}>
      <div className="flex items-center justify-between mb-1">
        <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
          {mandatory && <span className={hasValue ? 'text-gray-500' : 'text-red-500'}>*</span>} {label}
        </label>
        {!hideRegenerate && onOptionsUpdate && (
          <RegenerateButton 
            onClick={(comment, e) => handleRegenerate(comment, e)}
            isRegenerating={isRegenerating}
            type="button"
          />
        )}
      </div>
      {subtitle && <p className="text-sm text-gray-500 mb-2">{subtitle}</p>}
      <div className="mt-1 relative">
        {isRegenerating ? (
          <div className="flex items-center justify-center h-8">
            <PulseLoader color="#4B5563" size={8} />
          </div>
        ) : (
          <>
            {type === "textarea" ? (
              <textarea
                id={name}
                name={name}
                rows={rows || 1}
                maxLength={maxLength}
                value={options[name] || ''}
                onChange={handleChange}
                required={mandatory}
                className="block w-full text-md sm:text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 resize-none"
                placeholder={placeholder}
                ref={textareaRef}
              />
            ) : (
              <input
                type={type}
                id={name}
                name={name}
                value={options[name] || ''}
                maxLength={maxLength}
                onChange={handleChange}
                required={mandatory}
                className="block w-full text-md sm:text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                placeholder={placeholder}
              />
            )}
            <div className="absolute right-2 bottom-2 text-xs text-gray-400">
              {charCount}/{maxLength}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FormInput;