// Add at the top of the file
const DEFAULT_COLORS = {
  primary: '#4F46E5',
  secondary: '#4B5563',
  accent: '#7C3AED'
};

const DEFAULT_FONTS = {
  primary: 'Inter',
  secondary: 'Inter'
};

// At the top of the file, add these default theme values
const DEFAULT_THEME = {
  colors: {
    primary: '#4F46E5',
    secondary: '#4B5563',
    accent: '#7C3AED',
    background: '#FFFFFF',
    text: '#1F2937'
  },
  typography: {
    primaryFont: 'Inter',
    secondaryFont: 'Inter'
  }
};

// Color utility functions
const hexToRgb = (hex) => {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return { r, g, b };
};

const rgbToHex = (r, g, b) => {
  // Ensure values are numbers and within valid range (0-255)
  const safeValue = (val) => {
    const num = Number(val);
    if (isNaN(num)) return 0;
    return Math.max(0, Math.min(255, Math.round(num)));
  };

  try {
    const values = [r, g, b].map(safeValue);
    return `#${values.map(x => x.toString(16).padStart(2, '0')).join('')}`;
  } catch (error) {
    console.error('Error in rgbToHex:', error);
    return '#000000'; // Fallback to black if conversion fails
  }
};

// Calculate relative luminance for contrast calculations
const getLuminance = (hex) => {
  const { r, g, b } = hexToRgb(hex);
  const [rs, gs, bs] = [r, g, b].map(c => {
    c = c / 255;
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
};

// Calculate contrast ratio between two colors
const getContrastRatio = (color1, color2) => {
  const l1 = getLuminance(color1);
  const l2 = getLuminance(color2);
  const lighter = Math.max(l1, l2);
  const darker = Math.min(l1, l2);
  return (lighter + 0.05) / (darker + 0.05);
};

// Adjust color to meet minimum contrast ratio
const ensureContrast = (color, backgroundColor, minContrast = 4.5) => {
  let { r, g, b } = hexToRgb(color);
  const currentContrast = getContrastRatio(color, backgroundColor);
  
  if (currentContrast >= minContrast) return color;

  const targetLuminance = getLuminance(backgroundColor);
  const step = targetLuminance > 0.5 ? -5 : 5;
  
  while (getContrastRatio(rgbToHex(r, g, b), backgroundColor) < minContrast) {
    r = Math.max(0, Math.min(255, r + step));
    g = Math.max(0, Math.min(255, g + step));
    b = Math.max(0, Math.min(255, b + step));
  }

  return rgbToHex(r, g, b);
};

// Improved darken function with luminance preservation
const darkenColor = (hex, factor = 0.8) => {
  const { r, g, b } = hexToRgb(hex);
  const hsl = rgbToHsl(r, g, b);
  hsl.l = Math.max(0, hsl.l * factor);
  const rgb = hslToRgb(hsl.h, hsl.s, hsl.l);
  return rgbToHex(rgb.r, rgb.g, rgb.b);
};

// HSL conversion utilities (for better color manipulation)
const rgbToHsl = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  return { h, s, l };
};

const hslToRgb = (h, s, l) => {
  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1/6) return p + (q - p) * 6 * t;
      if (t < 1/2) return q;
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255)
  };
};

// Modify the getStylePresets function to be more defensive
export const getStylePresets = (gameTypeColor, businessProfile, gameType) => {

  try {
    // Use gameTypeColor as primary if available, otherwise fall back to defaults
    const theme = {
      colors: {
        primary: gameTypeColor || DEFAULT_THEME.colors.primary,
        secondary: businessProfile?.theme?.colors?.secondary || DEFAULT_THEME.colors.secondary,
        accent: businessProfile?.theme?.colors?.accent || DEFAULT_THEME.colors.accent
      },
      typography: {
        primaryFont: businessProfile?.theme?.typography?.primaryFont || DEFAULT_THEME.typography.primaryFont,
        secondaryFont: businessProfile?.theme?.typography?.secondaryFont || DEFAULT_THEME.typography.secondaryFont
      }
    };


    // Create a simplified preset structure
    return {
      default: {
        colors: {
          background: {
            gradient: {
              start: '#F3F4F6',
              end: '#E5E7EB'
            }
          },
          cardBackground: '#FFFFFF',
          cardOpacity: 0.9,
          titleText: '#1F2937',
          subtitleText: '#4B5563',
          buttonBackground: theme.colors.primary,
          buttonOpacity: 1,
          buttonText: '#FFFFFF'
        },
        typography: theme.typography
      },
      brand: {
        colors: {
          background: {
            gradient: {
              start: theme.colors.primary,
              end: darkenColor(theme.colors.primary)
            }
          },
          cardBackground: '#FFFFFF',
          cardOpacity: 0.95,
          titleText: theme.colors.primary,
          subtitleText: theme.colors.secondary,
          buttonBackground: theme.colors.primary,
          buttonOpacity: 1,
          buttonText: '#FFFFFF'
        },
        typography: theme.typography
      },
      transparent: {
        colors: {
          background: {
            gradient: {
              start: '#E2E8F0',
              end: '#CBD5E1'
            }
          },
          cardBackground: '#1F2937',
          cardOpacity: 0.6,
          titleText: '#FFFFFF',
          subtitleText: '#FFFFFF',
          buttonBackground: '#0F172A',
          buttonOpacity: 0.9,
          buttonText: '#FFFFFF'
        },
        typography: theme.typography
      },
      dark: {
        colors: {
          background: {
            gradient: {
              start: '#18181B',
              end: '#27272A'
            }
          },
          cardBackground: '#111827',
          cardOpacity: 0.95,
          titleText: '#FFFFFF',
          subtitleText: '#9CA3AF',
          buttonBackground: theme.colors.primary,
          buttonOpacity: 1,
          buttonText: '#FFFFFF'
        },
        typography: theme.typography
      }
    };
  } catch (error) {
    console.error('Error in getStylePresets:', error);
    // Return a minimal fallback preset if something goes wrong
    return {
      default: {
        colors: {
          background: { gradient: { start: '#F3F4F6', end: '#E5E7EB' } },
          cardBackground: '#FFFFFF',
          cardOpacity: 0.9,
          titleText: '#1F2937',
          subtitleText: '#4B5563',
          buttonBackground: gameTypeColor || DEFAULT_THEME.colors.primary,
          buttonOpacity: 1,
          buttonText: '#FFFFFF'
        },
        typography: DEFAULT_THEME.typography
      }
    };
  }
}; 