import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router";
import { doc, getDoc, setDoc, deleteDoc, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';
import useAuth from './useAuth';
import Loading from "../common/DefaultLoading";
import Stats, { StatsSkeleton } from "./stats";
import CampaignEmbed from "./CampaignEmbed";
import ActionsCard from '../dashboard/actions';
import CampaignBanner from '../dashboard/CampaignBanner';
import DeleteCampaign from "../dashboard/DeleteCampaign";
import AlertError from "./PermissionDenied";
import { useUser } from '../userContext';
import ManageLeaderboard from './ManageLeaderboard';

import { PencilSquareIcon, PlayIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';

function CampaignSkeleton() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
      {/* Main Content Skeleton */}
      <div className="col-span-12 md:col-span-8">
        {/* Campaign Banner Skeleton */}
        <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4">
          <div className="flex items-center justify-between">
            <div className="space-y-3 flex-1">
              <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/3 animate-pulse" />
              <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded w-1/2 animate-pulse" />
            </div>
            <div className="h-8 w-24 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse" />
          </div>
        </div>
        
        {/* Stats Skeleton */}
        <StatsSkeleton />
      </div>

      {/* Right Side Skeleton */}
      <RightSideSkeleton />
    </div>
  );
}

function RightSideSkeleton() {
  return (
    <div className="col-span-12 md:col-span-4 space-y-6">
      {/* Actions Card Skeleton */}
      <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4">
        {[1, 2].map((i) => (
          <div key={i} className="flex items-center space-x-4 mb-4 last:mb-0">
            <div className="h-10 w-10 bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse" />
            <div className="flex-1">
              <div className="h-6 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse" />
            </div>
            <div className="h-6 w-6 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse" />
          </div>
        ))}
      </div>

      {/* Embed Card Skeleton */}
      <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4">
        <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse w-1/2 mb-4" />
        <div className="h-32 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse" />
      </div>

      {/* Delete Button Skeleton */}
      <div className="bg-white dark:bg-gray-800 shadow-sm rounded-xl p-4">
        <div className="h-10 bg-gray-200 dark:bg-gray-700 rounded-md animate-pulse" />
      </div>
    </div>
  );
}

export default function EditCampaign() {
  const { user, loading: authLoading } = useAuth();
  const [campaign, setCampaign] = useState(null);
  const [formValues, setFormValues] = useState(null); 
  const [error, setError] = useState(null); 
  const params = useParams();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const { businessProfile } = useUser();
  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showStatsSkeleton, setShowStatsSkeleton] = useState(true);

  useEffect(() => {
    if (!user || !businessProfile) return;

    async function fetchCampaign() {
      setIsLoading(true);
      setShowStatsSkeleton(true);
      
      const minDelay = new Promise(resolve => setTimeout(resolve, 200));

      try {
        if (params.id) {
          const docRef = doc(db, "campaign", params.id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const campaignData = docSnap.data();
            if (campaignData.companyId !== businessProfile.id) {
              setError("You do not have permission to edit this campaign.");
              return;
            }
            const campaignWithId = { ...campaignData, id: docSnap.id };
            setCampaign(campaignWithId);
            setFormValues(campaignWithId);
          } else {
            setError("Campaign not found.");
          }
        }
      } catch (error) {
        setError("Error fetching campaign.");
        console.error(error);
      } finally {
        await minDelay;
        setIsLoading(false);
        setShowStatsSkeleton(false);
      }
    }

    fetchCampaign();
  }, [params.id, user, businessProfile]);

  useEffect(() => {
    if (!params.id) return;

    const docRef = doc(db, "campaign", params.id);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const campaignData = docSnap.data();
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          ...campaignData,
        }));
      }
    });

    return () => unsubscribe();
  }, [params.id]);

  const handleUpdateCampaignName = useCallback(async (newName) => {
    if (!params.id || !newName) return;

    const docRef = doc(db, "campaign", params.id);
  
    try {
      // First update Firestore
      await setDoc(docRef, {
        name: newName,
        updatedAt: new Date().toISOString(),
      }, { merge: true });

      // Then update local state
      setCampaign(prevCampaign => ({
        ...prevCampaign,
        name: newName,
        updatedAt: new Date().toISOString(),
      }));

    } catch (error) {
      console.error("Error updating campaign name:", error);
      // Optionally show an error message to the user
      setError("Failed to update campaign name. Please try again.");
    }
  }, [params.id]);

  const handleUpdateCampaignStatus = useCallback(async (newStatus) => {
    const docRef = doc(db, "campaign", params.id);
  
    try {
      const campaignSnapshot = await getDoc(docRef);
  
      if (campaignSnapshot.exists()) {
        const campaignData = campaignSnapshot.data();
        const updateData = {
          status: newStatus,
        };
  
        if (newStatus === 'live' && !campaignData.goLiveDate) {
          const goLiveDate = new Date();
          goLiveDate.setHours(0, 0, 0, 0);
          updateData.goLiveDate = goLiveDate.toISOString();
        }
  
        if (newStatus === 'complete') {
          const completeDate = new Date();
          updateData.completeDate = completeDate.toISOString();
        }
  
        await setDoc(docRef, updateData, { merge: true });
        setCampaign((prevCampaign) => ({
          ...prevCampaign,
          ...updateData,
        }));
      }
    } catch (error) {
      console.error("Error updating campaign status:", error);
      setError("Error updating campaign status. Please try again later.");
    }
  }, [params.id]);

  const allActions = useMemo(() => [
    { 
      name: "Open Editor", 
      link: `/editor/${campaign?.id}`, 
      icon: PencilSquareIcon, 
      iconForeground: 'text-blue-700', 
      iconBackground: 'bg-blue-50', 
      topRightIcon: ChevronRightIcon 
    },
    { 
      name: "Play", 
      link: `/play/${campaign?.id}`, 
      icon: PlayIcon, 
      iconForeground: 'text-teal-700', 
      iconBackground: 'bg-teal-50', 
      topRightIcon: ChevronRightIcon 
    },
  ], [campaign]);

  if (authLoading || !businessProfile) {
    return <CampaignSkeleton />;
  }

  if (error) {
    return <AlertError text={error} />;
  }

  if (!campaign) {
    return <CampaignSkeleton />;
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={showStatsSkeleton ? 'loading' : 'loaded'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          {/* Main Content */}
          <div className="col-span-12 md:col-span-8">
            <CampaignBanner 
              title={campaign.name} 
              name={campaign.options.title} 
              status={campaign.status} 
              gameType={campaign.gameType}
              onStatusChange={handleUpdateCampaignStatus} 
              onNameChange={handleUpdateCampaignName} 
            />
            {showStatsSkeleton ? (
              <StatsSkeleton />
            ) : (
              <Stats 
                campaignIds={[campaign.id]} 
                campaign={campaign} 
                onUpdateStatus={handleUpdateCampaignStatus}
              />
            )}
          </div>

          {/* Sidebar */}
          {showStatsSkeleton ? (
            <RightSideSkeleton />
          ) : (
            <div className="col-span-12 md:col-span-4">
              <ActionsCard actions={allActions} />
              <CampaignEmbed campaignId={campaign.id} />
              
              {campaign?.options?.leaderboard?.enabled ? (
                <>
                  <div className="mt-6">
                    <ManageLeaderboard campaignId={campaign.id} />
                  </div>
                  <div className="mt-6">
                    <DeleteCampaign campaignId={campaign.id} />
                  </div>
                </>
              ) : (
                <div className="mt-6">
                  <DeleteCampaign campaignId={campaign.id} />
                </div>
              )}
            </div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
