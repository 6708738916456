import React, { useRef } from 'react';
import Transition from '../utils/Transition';

function ModalBlank({
  children,
  id,
  modalOpen,
  setModalOpen,
  maxWidth = 'max-w-lg', // Default value is 'max-w-lg'
}) {
  const modalContent = useRef(null);

  return (
    <>
      {/* Modal backdrop */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-opacity ${
          modalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        style={{ transition: 'opacity 200ms ease-out' }}
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className={`bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full transition-all duration-300 ease-in-out my-5 ${maxWidth}`}
        >
          <div className="overflow-y-auto" style={{ maxHeight: '90svh' }}>
            {children}
          </div>
        </div>
      </Transition>
    </>
  );
}

export default ModalBlank;