// StartScreenSection.js
import React, { useState, useEffect } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { 
  ChevronDownIcon,
  BuildingStorefrontIcon, // For Branding
  PaintBrushIcon,         // For Game Content
  SwatchIcon,             // For Background
  PhotoIcon,              // For images
  Cog6ToothIcon          // For configuration/style sections
} from '@heroicons/react/24/outline';
import FormInput from './FormInput';
import FormSwitch from './FormSwitch';
import FileUpload from './FileUpload';
import FormSection from '../dashboard/FormSection';
import ColorPicker from '../components/ColorPicker';
import CustomFontPicker from '../dashboard/fontPicker';
import HeadlineProfile from '../account/HeadlineSetupProfile';
import { useUser } from '../userContext';

const ConfigureStyleDisclosure = ({ title, children }) => (
  <Disclosure>
    {({ open }) => (
      <div className="rounded-lg bg-gray-50">
        <Disclosure.Button className="flex w-full justify-between px-4 py-3 text-left text-sm font-medium text-gray-900 hover:bg-gray-100 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
          <span>{title}</span>
          <ChevronDownIcon
            className={`transform transition-transform duration-200 ease-in-out ${
              open ? 'rotate-180' : 'rotate-0'
            } h-5 w-5 text-gray-500`}
          />
        </Disclosure.Button>
        <Transition
          enter="transition-all duration-300 ease-out"
          enterFrom="transform -translate-y-2 opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition-all duration-150 ease-in"
          leaveFrom="transform translate-y-0 opacity-100"
          leaveTo="transform -translate-y-2 opacity-0"
        >
          <Disclosure.Panel>
            <div className="space-y-4 p-4">
              {children}
            </div>
          </Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
);

const StartScreenSection = ({ options = {}, onOptionsUpdate, params }) => {
  const [activeColorPicker, setActiveColorPicker] = useState(null);
  const { businessProfile, updateCompanyProfile } = useUser();

  // Add lightenColor function
  const lightenColor = (hex, percent) => {
    hex = hex.replace('#', '');
    let r = parseInt(hex.substr(0, 2), 16);
    let g = parseInt(hex.substr(2, 2), 16);
    let b = parseInt(hex.substr(4, 2), 16);
    r = Math.min(255, Math.round(r + (255 - r) * percent));
    g = Math.min(255, Math.round(g + (255 - g) * percent));
    b = Math.min(255, Math.round(b + (255 - b) * percent));
    return '#' + r.toString(16).padStart(2, '0') + g.toString(16).padStart(2, '0') + b.toString(16).padStart(2, '0');
  };

  // Extract headline options from businessProfile
  const headlineOptions = {
    headline: businessProfile?.headline || '',
    subheadline: businessProfile?.subheadline || '',
    headline_url: businessProfile?.headline_url || '',
    headlinePhoto: businessProfile?.headlinePhoto || '',
    imageShape: businessProfile?.imageShape || 'circle',
    headlineColor: businessProfile?.headlineColor || '#374151',
    subheadlineColor: businessProfile?.subheadlineColor || '#6B7280',
    iconColor: businessProfile?.iconColor || '#6B7280',
    imageBorderColor: businessProfile?.imageBorderColor || 'transparent',
    imageBorderWidth: businessProfile?.imageBorderWidth || 0,
    headlineFont: businessProfile?.headlineFont || 'inherit',
    subheadlineFont: businessProfile?.subheadlineFont || 'inherit',
    showIcon: businessProfile?.headline_url ? (businessProfile?.showIcon !== false) : false
  };

  const handleHeadlineOptionsUpdate = async (updatedOptions) => {
    if (businessProfile?.id) {
      await updateCompanyProfile(businessProfile.id, updatedOptions);
    }
  };

  const handleBackgroundUpdate = (updates) => {
    onOptionsUpdate({
      ...options,
      theme: {
        ...options.theme,
        colors: {
          ...options.theme?.colors,
          background: {
            ...options.theme?.colors?.background,
            ...updates
          }
        }
      }
    });
  };

  const handleThemeUpdate = (updates) => {
    onOptionsUpdate({
      ...options,
      theme: {
        ...options.theme,
        colors: {
          ...options.theme?.colors,
          ...updates
        }
      }
    });
  };

  // Add new handler for headline theme updates
  const handleHeadlineThemeUpdate = (updates) => {
    const newOptions = {
      ...options,
      theme: {
        ...options.theme,
        colors: {
          ...options.theme?.colors,
          headline: {
            ...options.theme?.colors?.headline,
            ...updates
          }
        }
      }
    };
    
    onOptionsUpdate(newOptions);
  };

  return (
    <div className="space-y-8 px-1 sm:px-4 mt-5">
      {/* Branding Section */}
      <FormSection 
        title="Branding" 
        subtitle="Configure your company branding"
        icon={<BuildingStorefrontIcon className="w-5 h-5" />}
        infoTitle="Company Branding"
        infoContent="Add your company's branding to the game start screen. This includes your logo, headline, and brand colors."
      >
        <div className="space-y-6">
          {/* Headline Toggle */}
          <FormSwitch
            name="hideHeadline"
            label="Show Headline"
            subtitle="Display your company headline configured in your settings"
            checked={!options?.hideHeadline}
            onChange={(checked) => onOptionsUpdate({
              ...options,
              hideHeadline: !checked
            })}
          />

          {!options?.hideHeadline && (
            <ConfigureStyleDisclosure 
              title="Headline Style"
              icon={<Cog6ToothIcon className="w-4 h-4 text-gray-500" />}
            >
              <ColorPicker
                label="Headline Text"
                subtitle="Override the default headline text color"
                color={options?.theme?.colors?.headline?.headlineColor}
                onChange={(color) => handleHeadlineThemeUpdate({ 
                  headlineColor: color,
                })}
                isActive={activeColorPicker === 'headlineColor'}
                onToggle={() => setActiveColorPicker(activeColorPicker === 'headlineColor' ? null : 'headlineColor')}
              />
              <ColorPicker
                label="Subheadline Text"
                subtitle="Override the default subheadline text color"
                color={options?.theme?.colors?.headline?.subheadlineColor}
                onChange={(color) => handleHeadlineThemeUpdate({ 
                  subheadlineColor: color,
                })}
                isActive={activeColorPicker === 'subheadlineColor'}
                onToggle={() => setActiveColorPicker(activeColorPicker === 'subheadlineColor' ? null : 'subheadlineColor')}
              />
            </ConfigureStyleDisclosure>
          )}
        </div>
      </FormSection>

      {/* Game Content Section */}
      <FormSection 
        title="Game Content" 
        subtitle="Customize the main game elements"
        icon={<PaintBrushIcon className="w-5 h-5" />}
        infoTitle="Game Content"
        infoContent="Configure the main elements of your game, including the cover image, title, description, and start button."
      >
        <div className="space-y-6">
          {/* Cover Image */}
          <div className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <FileUpload
              displayText="Cover Image"
              subtitle="Add a cover photo to your game (Recommended: 1200×630px, 1.91:1 ratio)"
              field="coverPhoto"
              options={options}
              onSuccess={onOptionsUpdate}
              onDelete={onOptionsUpdate}
              displayPreview={true}
              icon={<PhotoIcon className="w-5 h-5" />}
            />
            
            {options?.coverPhoto && (
              <div className="mt-4">
                <FormSwitch
                  name="coverPhotoRounded"
                  label="Rounded Corners"
                  subtitle="Apply rounded corners to the cover photo"
                  options={options}
                  checked={options?.coverPhotoSettings?.rounded ?? true}
                  onChange={(checked) => onOptionsUpdate({
                    ...options,
                    coverPhotoSettings: {
                      ...options?.coverPhotoSettings,
                      rounded: checked
                    }
                  })}
                />
              </div>
            )}
          </div>

          {/* Title & Description Group */}
          <div className="space-y-6">
            <FormInput
              label="Game Title"
              name="title"
              onChange={(e) => onOptionsUpdate({ ...options, title: e.target.value })}
              placeholder="Enter a compelling title for your game"
              type="textarea"
              rows={1}
              options={options}
              onOptionsUpdate={onOptionsUpdate}
              hideRegenerate={false}
              mandatory
              maxLength={100}
              helperText="This is the main title players will see"
            />

            <FormInput
              label="Game Description"
              name="intro"
              onChange={(e) => onOptionsUpdate({ ...options, intro: e.target.value })}
              placeholder="Brief description of your game"
              options={options}
              type="textarea"
              maxLength={255}
              rows={2}
              onOptionsUpdate={onOptionsUpdate}
              hideRegenerate={false}
              helperText="A short description to engage players"
            />

            <ConfigureStyleDisclosure 
              title="Content Style"
              icon={<Cog6ToothIcon className="w-4 h-4 text-gray-500" />}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Title styling */}
                <div className="space-y-4">
                  <h4 className="font-medium text-sm text-gray-700 dark:text-gray-300">Title Style</h4>
                  <ColorPicker
                    label="Color"
                    color={options?.theme?.colors?.titleText}
                    onChange={(color) => handleThemeUpdate({ titleText: color })}
                    isActive={activeColorPicker === 'titleText'}
                    onToggle={() => setActiveColorPicker(activeColorPicker === 'titleText' ? null : 'titleText')}
                  />
                  <CustomFontPicker 
                    label="Font"
                    value={options?.theme?.typography?.titleFont || 'Inter'}
                    onChange={(font) => onOptionsUpdate({
                      ...options,
                      theme: {
                        ...options.theme,
                        typography: {
                          ...options.theme?.typography,
                          titleFont: font
                        }
                      }
                    })}
                  />
                </div>

                {/* Description styling */}
                <div className="space-y-4">
                  <h4 className="font-medium text-sm text-gray-700 dark:text-gray-300">Description Style</h4>
                  <ColorPicker
                    label="Color"
                    color={options?.theme?.colors?.subtitleText}
                    onChange={(color) => handleThemeUpdate({ subtitleText: color })}
                    isActive={activeColorPicker === 'subtitleText'}
                    onToggle={() => setActiveColorPicker(activeColorPicker === 'subtitleText' ? null : 'subtitleText')}
                  />
                  <CustomFontPicker 
                    label="Font"
                    value={options?.theme?.typography?.subtitleFont || 'Inter'}
                    onChange={(font) => onOptionsUpdate({
                      ...options,
                      theme: {
                        ...options.theme,
                        typography: {
                          ...options.theme?.typography,
                          subtitleFont: font
                        }
                      }
                    })}
                  />
                </div>
              </div>
            </ConfigureStyleDisclosure>
          </div>

          {/* Start Button Configuration */}
          <div className="space-y-4">
            <FormInput
              label="Start Button"
              name="startButtonText"
              onChange={(e) => onOptionsUpdate({
                ...options,
                startButtonText: e.target.value
              })}
              placeholder="Start"
              type="text"
              options={options}
              maxLength={20}
              helperText="Text displayed on the button that starts the game"
            />
            <ConfigureStyleDisclosure 
              title="Button Style"
              icon={<Cog6ToothIcon className="w-4 h-4 text-gray-500" />}
            >
              <div className="space-y-4">
                <ColorPicker
                  label="Background"
                  color={options?.theme?.colors?.buttonBackground}
                  opacity={options?.theme?.colors?.buttonOpacity}
                  onChange={(color) => handleThemeUpdate({ buttonBackground: color })}
                  onOpacityChange={(opacity) => handleThemeUpdate({ buttonOpacity: opacity })}
                  showOpacity={true}
                  isActive={activeColorPicker === 'buttonBackground'}
                  onToggle={() => setActiveColorPicker(activeColorPicker === 'buttonBackground' ? null : 'buttonBackground')}
                />
                <ColorPicker
                  label="Text"
                  color={options?.theme?.colors?.buttonText}
                  onChange={(color) => handleThemeUpdate({ buttonText: color })}
                  isActive={activeColorPicker === 'buttonText'}
                  onToggle={() => setActiveColorPicker(activeColorPicker === 'buttonText' ? null : 'buttonText')}
                />
                <CustomFontPicker 
                  label="Font"
                  value={options?.theme?.typography?.buttonFont || 'Inter'}
                  onChange={(font) => onOptionsUpdate({
                    ...options,
                    theme: {
                      ...options.theme,
                      typography: {
                        ...options.theme?.typography,
                        buttonFont: font
                      }
                    }
                  })}
                />
              </div>
            </ConfigureStyleDisclosure>
          </div>
        </div>
      </FormSection>

      {/* Background Section */}
      <FormSection 
        title="Background" 
        subtitle="Style the game's background"
        icon={<SwatchIcon className="w-5 h-5" />}
        infoTitle="Background Style"
        infoContent="Customize the background of your game with images or gradient colors. This sets the overall mood and theme of your game."
      >
        <div className="space-y-6">
          {/* Cover Image */}
          <div className="p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
            <FileUpload
              displayText="Background Image"
              subtitle="Optional: Add a custom background image"
              field="backgroundImage"
              options={options}
              onSuccess={onOptionsUpdate}
              onDelete={onOptionsUpdate}
              displayPreview={true}
              icon={<PhotoIcon className="w-5 h-5" />}
            />
          </div>
          
          <ConfigureStyleDisclosure 
            title="Background Style"
            icon={<Cog6ToothIcon className="w-4 h-4 text-gray-500" />}
          >
            <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <ColorPicker
                  label="Gradient Start"
                  color={options?.theme?.colors?.background?.gradient?.start}
                  onChange={(color) => handleBackgroundUpdate({
                    gradient: {
                      ...options?.theme?.colors?.background?.gradient,
                      start: color,
                      end: options?.theme?.colors?.background?.gradient?.end || lightenColor(color, -0.2)
                    }
                  })}
                  isActive={activeColorPicker === 'gradientStart'}
                  onToggle={() => setActiveColorPicker(activeColorPicker === 'gradientStart' ? null : 'gradientStart')}
                />
                <ColorPicker
                  label="Gradient End"
                  color={options?.theme?.colors?.background?.gradient?.end}
                  onChange={(color) => handleBackgroundUpdate({
                    gradient: {
                      ...options?.theme?.colors?.background?.gradient,
                      end: color
                    }
                  })}
                  isActive={activeColorPicker === 'gradientEnd'}
                  onToggle={() => setActiveColorPicker(activeColorPicker === 'gradientEnd' ? null : 'gradientEnd')}
                />
              </div>
              <ColorPicker
                label="Card Background"
                color={options?.theme?.colors?.cardBackground}
                opacity={options?.theme?.colors?.cardOpacity}
                onChange={(color) => handleThemeUpdate({ cardBackground: color })}
                onOpacityChange={(opacity) => handleThemeUpdate({ cardOpacity: opacity })}
                showOpacity={true}
                isActive={activeColorPicker === 'cardBackground'}
                onToggle={() => setActiveColorPicker(activeColorPicker === 'cardBackground' ? null : 'cardBackground')}
              />
            </div>
          </ConfigureStyleDisclosure>
        </div>
      </FormSection>
    </div>
  );
};

export default StartScreenSection;