import React from 'react';
import ColorPicker from '../ColorPicker';
import CustomFontPicker from '../../dashboard/fontPicker';

export const mapCompanyThemeToLeaderboard = (companyTheme) => {
  if (!companyTheme) return {};
  
  return {
    theme: {
      colors: {
        leaderboard: {
          // Header
          leaderboardTitleText: companyTheme.text || '#1F2937',
          
          // Background
          background: '#FFFFFF',
          backgroundOpacity: 0.9,
          
          // Entries
          rankText: '#6B7280',
          nameText: '#111827',
          scoreText: '#4F46E5',
          
          // User highlight
          userBackground: '#E5E7EB',
          
          // Simplified tab colors
          tabPrimary: '#4F46E5',    // Used for active tab
          tabSecondary: '#6B7280',  // Used for inactive tab
          
          // Join Button
          joinButtonGradientFrom: '#4F46E5',
          joinButtonGradientTo: '#7C3AED',
          joinButtonText: '#FFFFFF',
        }
      },
      typography: {
        leaderboardTitleFont: companyTheme.headingFont || 'Inter',
        rankFont: companyTheme.bodyFont || 'Inter',
        nameFont: companyTheme.bodyFont || 'Inter',
        scoreFont: companyTheme.bodyFont || 'Inter',
        joinButtonFont: companyTheme.bodyFont || 'Inter',
      }
    }
  };
};

export default function LeaderboardAppearanceSection({ 
  safeOptions, 
  onOptionsUpdate, 
  activeColorPicker, 
  setActiveColorPicker 
}) {
  const handleUpdate = (updates) => {
    onOptionsUpdate({
      ...safeOptions,
      theme: {
        ...safeOptions.theme,
        colors: {
          ...safeOptions.theme?.colors,
          leaderboard: {
            ...safeOptions.theme?.colors?.leaderboard,
            ...updates
          }
        }
      }
    });
  };

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="space-y-4">
        <ColorPicker
          label="Title Text"
          subtitle="Color of the leaderboard title"
          color={safeOptions?.theme?.colors?.leaderboard?.leaderboardTitleText}
          onChange={(color) => handleUpdate({ leaderboardTitleText: color })}
          isActive={activeColorPicker === 'leaderboardTitleText'}
          onToggle={() => setActiveColorPicker(activeColorPicker === 'leaderboardTitleText' ? null : 'leaderboardTitleText')}
        />
        <CustomFontPicker 
          label="Title Font"
          subtitle="Font used for the leaderboard title"
          value={safeOptions?.theme?.typography?.leaderboardTitleFont || 'Inter'}
          onChange={(font) => onOptionsUpdate({
            ...safeOptions,
            theme: {
              ...safeOptions.theme,
              typography: {
                ...safeOptions.theme?.typography,
                leaderboardTitleFont: font
              }
            }
          })}
        />
      </div>

      {/* Background */}
      <div className="space-y-4">
        <ColorPicker
          label="Background"
          subtitle="Background color of the leaderboard"
          color={safeOptions?.theme?.colors?.leaderboard?.background}
          opacity={safeOptions?.theme?.colors?.leaderboard?.backgroundOpacity}
          onChange={(color) => handleUpdate({ background: color })}
          onOpacityChange={(value) => handleUpdate({ backgroundOpacity: value })}
          showOpacity={true}
          isActive={activeColorPicker === 'background'}
          onToggle={() => setActiveColorPicker(activeColorPicker === 'background' ? null : 'background')}
        />
      </div>

      {/* Entries */}
      <div className="space-y-4">
        <div className="space-y-4">
          <ColorPicker
            label="Rank Numbers"
            subtitle="Color of the rank numbers"
            color={safeOptions?.theme?.colors?.leaderboard?.rankText}
            onChange={(color) => handleUpdate({ rankText: color })}
            isActive={activeColorPicker === 'rankText'}
            onToggle={() => setActiveColorPicker(activeColorPicker === 'rankText' ? null : 'rankText')}
          />
          <CustomFontPicker 
            label="Rank Font"
            subtitle="Font used for rank numbers"
            value={safeOptions?.theme?.typography?.rankFont || 'Inter'}
            onChange={(font) => onOptionsUpdate({
              ...safeOptions,
              theme: {
                ...safeOptions.theme,
                typography: {
                  ...safeOptions.theme?.typography,
                  rankFont: font
                }
              }
            })}
          />
        </div>

        <div className="space-y-4">
          <ColorPicker
            label="Names"
            subtitle="Color of player names"
            color={safeOptions?.theme?.colors?.leaderboard?.nameText}
            onChange={(color) => handleUpdate({ nameText: color })}
            isActive={activeColorPicker === 'nameText'}
            onToggle={() => setActiveColorPicker(activeColorPicker === 'nameText' ? null : 'nameText')}
          />
          <CustomFontPicker 
            label="Name Font"
            subtitle="Font used for player names"
            value={safeOptions?.theme?.typography?.nameFont || 'Inter'}
            onChange={(font) => onOptionsUpdate({
              ...safeOptions,
              theme: {
                ...safeOptions.theme,
                typography: {
                  ...safeOptions.theme?.typography,
                  nameFont: font
                }
              }
            })}
          />
        </div>

        <div className="space-y-4">
          <ColorPicker
            label="Scores"
            subtitle="Color of the scores"
            color={safeOptions?.theme?.colors?.leaderboard?.scoreText}
            onChange={(color) => handleUpdate({ scoreText: color })}
            isActive={activeColorPicker === 'scoreText'}
            onToggle={() => setActiveColorPicker(activeColorPicker === 'scoreText' ? null : 'scoreText')}
          />
          <CustomFontPicker 
            label="Score Font"
            subtitle="Font used for scores"
            value={safeOptions?.theme?.typography?.scoreFont || 'Inter'}
            onChange={(font) => onOptionsUpdate({
              ...safeOptions,
              theme: {
                ...safeOptions.theme,
                typography: {
                  ...safeOptions.theme?.typography,
                  scoreFont: font
                }
              }
            })}
          />
        </div>
      </div>

      {/* Tab Colors */}
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-900">Tab Colors</h3>
        <ColorPicker
          label="Primary Tab Color"
          subtitle="Color for active tab"
          color={safeOptions?.theme?.colors?.leaderboard?.tabPrimary}
          onChange={(color) => handleUpdate({ tabPrimary: color })}
          isActive={activeColorPicker === 'tabPrimary'}
          onToggle={() => setActiveColorPicker(activeColorPicker === 'tabPrimary' ? null : 'tabPrimary')}
        />
        <ColorPicker
          label="Secondary Tab Color"
          subtitle="Color for inactive tabs"
          color={safeOptions?.theme?.colors?.leaderboard?.tabSecondary}
          onChange={(color) => handleUpdate({ tabSecondary: color })}
          isActive={activeColorPicker === 'tabSecondary'}
          onToggle={() => setActiveColorPicker(activeColorPicker === 'tabSecondary' ? null : 'tabSecondary')}
        />
      </div>

      {/* Join Button */}
      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-900">Join Button</h3>
        <ColorPicker
          label="Button Gradient Start"
          subtitle="Starting color of the button gradient"
          color={safeOptions?.theme?.colors?.leaderboard?.joinButtonGradientFrom || '#4F46E5'}
          onChange={(color) => handleUpdate({ joinButtonGradientFrom: color })}
          isActive={activeColorPicker === 'joinButtonGradientFrom'}
          onToggle={() => setActiveColorPicker(activeColorPicker === 'joinButtonGradientFrom' ? null : 'joinButtonGradientFrom')}
        />
        <ColorPicker
          label="Button Gradient End"
          subtitle="Ending color of the button gradient"
          color={safeOptions?.theme?.colors?.leaderboard?.joinButtonGradientTo || '#7C3AED'}
          onChange={(color) => handleUpdate({ joinButtonGradientTo: color })}
          isActive={activeColorPicker === 'joinButtonGradientTo'}
          onToggle={() => setActiveColorPicker(activeColorPicker === 'joinButtonGradientTo' ? null : 'joinButtonGradientTo')}
        />
        <ColorPicker
          label="Button Text"
          subtitle="Color of the button text"
          color={safeOptions?.theme?.colors?.leaderboard?.joinButtonText || '#FFFFFF'}
          onChange={(color) => handleUpdate({ joinButtonText: color })}
          isActive={activeColorPicker === 'joinButtonText'}
          onToggle={() => setActiveColorPicker(activeColorPicker === 'joinButtonText' ? null : 'joinButtonText')}
        />
        <CustomFontPicker 
          label="Join Button Font"
          subtitle="Font used for the join button"
          value={safeOptions?.theme?.typography?.joinButtonFont || 'Inter'}
          onChange={(font) => onOptionsUpdate({
            ...safeOptions,
            theme: {
              ...safeOptions.theme,
              typography: {
                ...safeOptions.theme?.typography,
                joinButtonFont: font
              }
            }
          })}
        />
      </div>
    </div>
  );
} 