import React, { useState, useEffect } from 'react';
import HeadlineCard from '../dashboard/HeadlineCard';
import { useUser } from '../userContext';

const HeadlineProfile = ({ options = {}, params, onOptionsUpdate }) => {
  const { user } = useUser();
  const [showWarning, setShowWarning] = useState(true);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [pendingOptions, setPendingOptions] = useState(null);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  // Check localStorage on mount
  useEffect(() => {
    const hideWarning = localStorage.getItem('hideHeadlineWarning');
    if (hideWarning === 'true') {
      setShowWarning(false);
    }
  }, []);

  // Default options with fallback values
  const safeOptions = {
    headline_url: '',
    headlinePhoto: '',
    headline: '',
    subheadline: '',
    imageShape: 'circle',
    headlineColor: '#000000',
    subheadlineColor: '#000000',
    iconColor: '#000000',
    imageBorderColor: 'transparent',
    imageBorderWidth: 0,
    headlineFont: 'Inter',
    subheadlineFont: 'Inter',
    showIcon: false,
    ...options
  };

  const handleOptionsUpdate = (newOptions) => {
    if (showWarning) {
      setPendingOptions(newOptions);
      setIsWarningOpen(true);
    } else {
      onOptionsUpdate(newOptions);
    }
  };

  const handleConfirm = () => {
    if (dontShowAgain) {
      localStorage.setItem('hideHeadlineWarning', 'true');
      setShowWarning(false);
    }
    onOptionsUpdate(pendingOptions);
    setIsWarningOpen(false);
    setPendingOptions(null);
    setDontShowAgain(false);
  };

  return (
    <div className="w-full max-w-2xl">
      <HeadlineCard
        preview={true}
        customization={safeOptions}
        onOptionsUpdate={handleOptionsUpdate}
        params={params}
        user={user}
      />

      {/* Warning Dialog */}
      {isWarningOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Update Company Headline
            </h3>
            <p className="text-sm text-gray-500 mb-4">
              This will update your company headline across all campaigns. Do you want to continue?
            </p>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="dontShowAgain"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                checked={dontShowAgain}
                onChange={(e) => setDontShowAgain(e.target.checked)}
              />
              <label htmlFor="dontShowAgain" className="ml-2 text-sm text-gray-600">
                Don't show this warning again
              </label>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  setIsWarningOpen(false);
                  setPendingOptions(null);
                  setDontShowAgain(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleConfirm}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeadlineProfile;