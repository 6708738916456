import React from 'react';
import Logo from "../images/logo.png"

const SmilesFlowLink = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="mt-6 text-center text-gray-500 flex items-center justify-center" style={{fontSize: '0.5rem'}}>
      <a href="https://smilesflow.com" className="flex items-center" target="_blank" rel="noopener noreferrer">
        <span>Created on</span>
        <img src={Logo} alt="Logo" className="inline-block w-16 ml-1" />
      </a>
    </div>
  );
};

export default SmilesFlowLink;