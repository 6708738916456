import React, { useState, useEffect, useRef } from 'react';
import { storage } from '../firebase'; // Adjust the import path as needed
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { PencilIcon, TrashIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { ArrowUpTrayIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const FileUpload = ({ onSuccess, onDelete, field, options, displayText, displayPreview, subtitle = null }) => {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [currentFileUrl, setCurrentFileUrl] = useState(options?.[field] || '');
  const [errorMessage, setErrorMessage] = useState('');
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (options?.[field]) {
      setCurrentFileUrl(options[field]);
    }
  }, [options, field]);

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    // File type restriction
    const allowedTypes = ['image/jpeg', 'image/png'];
    if (!allowedTypes.includes(selectedFile.type)) {
      setErrorMessage('Invalid file type. Only JPG and PNG are allowed.');
      return;
    }

    // File size restriction
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeInBytes) {
      setErrorMessage('File size exceeds the 10MB limit.');
      return;
    }

    setErrorMessage('');
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (!file) return;

    setUploading(true);
    const storageReference = storageRef(storage, `${field}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageReference, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(prog);
      },
      (error) => {
        console.log(error);
        setUploading(false);
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('File available at', url); // Logging the URL
        setCurrentFileUrl(url);
        handleFileUploadSuccess(url);
        setUploading(false);
      }
    );
  };

  const handleFileUploadSuccess = (url) => {
    const updatedOptions = { ...options, [field]: url };
    onSuccess(updatedOptions);
  };

  const handleDelete = () => {
    setCurrentFileUrl(null);
    setProgress(0);
    fileInputRef.current.value = null; // Reset the input field
    const updatedOptions = { ...options, [field]: null };
    onDelete(updatedOptions);
  };

  return (
    <div className="flex flex-col items-start">
      <div className="flex items-center justify-between w-full mb-2">
        <span className="flex flex-grow flex-col">
          <span className="text-sm font-medium leading-6 text-gray-900">
            {displayText}
          </span>
          {subtitle && (
            <span className="text-sm text-gray-500">
              {subtitle}
            </span>
          )}
        </span>
      </div>

      {currentFileUrl && displayPreview ? (
        // Improved image preview with full height
        <div className="w-full relative rounded-lg overflow-hidden aspect-video">
          <img
            src={currentFileUrl}
            alt="Uploaded file"
            className="w-full h-full object-cover rounded-lg"
          />
          <div className="absolute top-2 right-2 flex gap-2">
            <button
              type="button"
              className="p-2 bg-white/90 backdrop-blur-sm rounded-lg shadow-sm text-gray-600 hover:text-gray-900 transition-colors"
              onClick={() => fileInputRef.current.click()}
            >
              <PencilIcon className="h-4 w-4" />
            </button>
            <button
              type="button"
              className="p-2 bg-white/90 backdrop-blur-sm rounded-lg shadow-sm text-red-500 hover:text-red-700 transition-colors"
              onClick={handleDelete}
            >
              <TrashIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      ) : (
        // Improved empty state with better button styling
        <div 
          className="w-full mt-2 flex justify-center rounded-lg border-2 border-dashed border-gray-300 px-6 py-10 cursor-pointer hover:border-gray-400 transition-colors"
          onClick={() => fileInputRef.current.click()}
        >
          <div className="text-center">
            <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />
            <div className="mt-4 flex flex-col items-center">
              <button
                type="button"
                className="inline-flex items-center gap-1.5 rounded-md bg-gray-50 text-xs px-3.5 py-2.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 transition-colors"
                onClick={(e) => {
                  e.stopPropagation();
                  fileInputRef.current.click();
                }}
              >
                <ArrowUpTrayIcon className="h-4 w-4 text-gray-600" />
                Upload Image
              </button>
              <p className="mt-1 text-xs text-gray-500">JPG, PNG up to 10MB</p>
            </div>
          </div>
        </div>
      )}

      {/* Keep existing input, progress bar and error message */}
      <input
        id={`${field}-upload`}
        name={`${field}-upload`}
        type="file"
        className="sr-only"
        ref={fileInputRef}
        onChange={handleChange}
      />
      
      {uploading && (
        <div className="w-full bg-gray-200 rounded-full h-1.5 mt-2">
          <div
            className="bg-blue-600 h-1.5 rounded-full transition-all duration-500 ease-in-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      )}
      
      {errorMessage && (
        <div className="mt-2 text-sm text-red-600">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default FileUpload;