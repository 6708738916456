import React, { useState } from 'react';
import FormInput from './FormInput';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CallToActionForm = ({ handleChange, options }) => {
  const [ctaEnabled, setCtaEnabled] = useState(options.ctaEnabled || false);

  const handleSwitchChange = (enabled) => {
    setCtaEnabled(enabled);
    handleChange({
      target: {
        name: 'ctaEnabled',
        value: enabled,
      },
    });
  };

  return (
    <div className="space-y-4">
      {/* Header Section */}
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <label className="text-sm font-medium text-gray-900">
            Call to Action
          </label>
          <p className="text-sm text-gray-500">
            Add a button that appears at the end of the game
          </p>
        </div>
        <Switch
          checked={ctaEnabled}
          onChange={handleSwitchChange}
          className={classNames(
            ctaEnabled ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Enable call to action</span>
          <span
            aria-hidden="true"
            className={classNames(
              ctaEnabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      </div>

      {/* Form Section */}
      <div className={classNames(
        'rounded-lg border border-gray-200 transition-all duration-500 ease-in-out relative overflow-hidden',
        !ctaEnabled && 'opacity-50'
      )}>
        <div className="p-4">
          <div className="relative">
            <div className="space-y-4">
              {/* Form Inputs */}
              <FormInput
                style=""
                label="Text"
                name="cta_text"
                onChange={handleChange}
                placeholder="View our products"
                options={options}
                mandatory={ctaEnabled}
                subtitle="Text that will appear on the button"
              />
              <FormInput
                style=""
                label="Button URL"
                name="cta_link"
                onChange={handleChange}
                placeholder="https://yoururl.com"
                options={options}
                mandatory={ctaEnabled}
                subtitle="Where users will be directed when clicking the button"
                hideRegenerate={true}
              />
            </div>

            {/* Overlay when disabled */}
            {!ctaEnabled && (
              <div className="absolute inset-0 bg-white/60 rounded-lg transition-all duration-500 ease-in-out z-10" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActionForm;