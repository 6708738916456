import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { gameTypes } from '../data/GameTypes';
import useAuth from './useAuth';

export default function Games() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCreate = (gameType) => {
    if (user) {
      navigate(`/new?gameType=${gameType}`);
    } else {
      navigate('/register');
    }
  };

  const getIconColorClasses = (gameId) => {
    const colorMap = {
      "pindrop": "bg-red-100 text-red-500",
      "hint": "bg-amber-100 text-amber-500",
      "quiz": "bg-blue-100 text-blue-500",
      "mapselect": "bg-emerald-100 text-emerald-500",
      "snake": "bg-purple-100 text-purple-500"
    };
    
    return colorMap[gameId] || colorMap["quiz"];
  };

  return (
    <div className="sm:px-4 pb-5 max-w-7xl mx-auto">
      {/* Header and Introduction */}
      <div className="mb-8">
        <h1 className="text-2xl md:text-3xl text-gray-800 font-bold mb-3">Game Library</h1>
        <div className="prose prose-gray max-w-none">
          <p className="text-gray-600">
            Choose from our collection of interactive geography games. Each game type offers unique ways to engage with maps and locations. 
            Try a demo or create your own campaign to get started.
          </p>
        </div>
      </div>

      {/* Game Cards Grid */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {gameTypes.map((game) => (
          <div 
            key={game.name}
            className="group bg-white rounded-xl overflow-hidden shadow-sm border border-gray-200 hover:shadow-md transition-all duration-200 flex flex-col h-full"
          >
            {/* Header Section with Icon */}
            <div className="p-6 pb-0">
              <div className="flex items-center gap-3 mb-3">
                {game.icon && (
                  <div className={`p-2 rounded-lg ${getIconColorClasses(game.id)}`}>
                    <game.icon className="w-5 h-5" />
                  </div>
                )}
                <h3 className="text-lg font-semibold text-gray-900">
                  {game.name}
                </h3>
              </div>
            </div>

            {/* Content */}
            <div className="px-6 pb-6 flex flex-col flex-1">
              <p className="text-gray-600 text-sm mb-6 flex-1">
                {game.description}
              </p>

              {/* Buttons */}
              <div className="flex gap-2 mt-auto">
                <a
                  href={`/play/${game.exampleId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-1 py-1.5 px-3 border border-gray-300 text-gray-700 text-sm rounded-md hover:bg-gray-50 text-center"
                >
                  Try ↗
                </a>
                <button
                  onClick={() => handleCreate(game.id)}
                  className="flex-1 py-1.5 px-3 bg-violet-500 text-white hover:bg-violet-600 text-sm rounded-md transition-colors duration-200"
                >
                  Start Campaign
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}