import React from 'react';
import ColorPicker from '../../components/ColorPicker';
import CustomFontPicker from '../../dashboard/fontPicker';
import FormSection from '../../dashboard/FormSection';
import Switch from '../FormSwitch';
import { 
  SwatchIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  LightBulbIcon
} from '@heroicons/react/24/outline';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const ConfigureStyleDisclosure = ({ title, children }) => (
  <Disclosure>
    {({ open }) => (
      <div className="rounded-lg bg-gray-50">
        <Disclosure.Button className="flex w-full justify-between px-4 py-3 text-left text-sm font-medium text-gray-900 hover:bg-gray-100 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
          <span>{title}</span>
          <ChevronDownIcon
            className={`transform transition-transform duration-200 ease-in-out ${
              open ? 'rotate-180' : 'rotate-0'
            } h-5 w-5 text-gray-500`}
          />
        </Disclosure.Button>
        <Transition
          enter="transition-all duration-300 ease-out"
          enterFrom="transform -translate-y-2 opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition-all duration-150 ease-in"
          leaveFrom="transform translate-y-0 opacity-100"
          leaveTo="transform -translate-y-2 opacity-0"
        >
          <Disclosure.Panel>
            <div className="space-y-4 p-4">
              {children}
            </div>
          </Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
);

export const mapCompanyThemeToQuiz = (companyTheme) => {
  if (!companyTheme) return {};
  
  return {
    theme: {
      colors: {
        surface: {
          background: companyTheme.colors.background || '#F3F4F6',
          primary: companyTheme.colors.primary || '#7C3AED',
          secondary: companyTheme.colors.secondary || '#4B5563',
          questionText: companyTheme.colors.text.primary || '#1F2937',
          answerBackground: 'rgba(255, 255, 255, 0.1)',
          answerText: companyTheme.colors.text.primary || '#1F2937',
          answerOpacity: 0.1,
          hintBackground: 'rgba(255, 255, 255, 0.1)',
          hintText: companyTheme.colors.text.primary || '#1F2937',
          hintOpacity: 0.1,
        },
        text: {
          primary: companyTheme.colors.text.primary || '#FFFFFF',
          secondary: companyTheme.colors.text.secondary || '#FFFFFF',
          onSurface: '#1F2937',
        },
        button: {
          primary: companyTheme.colors.primary || '#7C3AED',
          secondary: companyTheme.colors.secondary || '#4B5563',
        },
        font: {
          primary: companyTheme.typography.primaryFont || 'Inter',
          secondary: companyTheme.typography.secondaryFont || 'Inter',
        }
      },
      typography: {
        questionFont: companyTheme.typography.primaryFont || 'Inter',
        answerFont: companyTheme.typography.secondaryFont || 'Inter',
        hintFont: companyTheme.typography.secondaryFont || 'Inter',
      }
    },
    leaderboard: {
      enabled: false
    }
  };
};

export default function QuizAppearanceSection({ 
  surfaceProps, 
  handleThemeUpdate, 
  activeColorPicker, 
  setActiveColorPicker,
  safeOptions,
  onOptionsUpdate 
}) {
  return (
    <div className="space-y-8">
      {/* Question Section */}
      <FormSection 
        title="Questions" 
        subtitle="Style the question display"
        icon={<QuestionMarkCircleIcon className="w-5 h-5" />}
        infoTitle="Question Appearance"
        infoContent="Customize how questions appear in your quiz, including the background, text color, and font style."
      >
        <div className="space-y-6">
          <ConfigureStyleDisclosure title="Question Style">
            <ColorPicker
              label="Background"
              subtitle="Background color of the question container"
              color={surfaceProps.background}
              opacity={surfaceProps.opacity}
              onChange={(color) => handleThemeUpdate('background', color)}
              onOpacityChange={(value) => handleThemeUpdate('opacity', value)}
              showOpacity={true}
              isActive={activeColorPicker === 'questionBackground'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'questionBackground' ? null : 'questionBackground')}
            />
            <ColorPicker
              label="Text Color"
              subtitle="Color of the question text"
              color={surfaceProps.questionText}
              onChange={(color) => handleThemeUpdate('questionText', color)}
              isActive={activeColorPicker === 'questionText'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'questionText' ? null : 'questionText')}
            />
            <CustomFontPicker 
              label="Question Font"
              subtitle="Font used for questions"
              value={safeOptions?.theme?.typography?.questionFont || 'Inter'}
              onChange={(font) => onOptionsUpdate({
                ...safeOptions,
                theme: {
                  ...safeOptions.theme,
                  typography: {
                    ...safeOptions.theme?.typography,
                    questionFont: font
                  }
                }
              })}
            />
          </ConfigureStyleDisclosure>
        </div>
      </FormSection>

      {/* Answers Section */}
      <FormSection 
        title="Answers" 
        subtitle="Style the answer options"
        icon={<ChatBubbleBottomCenterTextIcon className="w-5 h-5" />}
        infoTitle="Answer Appearance"
        infoContent="Customize how answer options appear, including their background, text color, and font style."
      >
        <div className="space-y-6">
          <ConfigureStyleDisclosure title="Answer Style">
            <ColorPicker
              label="Background"
              subtitle="Background color of the answer options"
              color={surfaceProps.answerBackground}
              opacity={surfaceProps.answerOpacity}
              onChange={(color) => handleThemeUpdate('answerBackground', color)}
              onOpacityChange={(value) => handleThemeUpdate('answerOpacity', value)}
              showOpacity={true}
              isActive={activeColorPicker === 'answerBackground'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'answerBackground' ? null : 'answerBackground')}
            />
            <ColorPicker
              label="Text Color"
              subtitle="Color of the answer text"
              color={surfaceProps.answerText}
              onChange={(color) => handleThemeUpdate('answerText', color)}
              isActive={activeColorPicker === 'answerText'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'answerText' ? null : 'answerText')}
            />
            <CustomFontPicker 
              label="Answer Font"
              subtitle="Font used for answers"
              value={safeOptions?.theme?.typography?.answerFont || 'Inter'}
              onChange={(font) => onOptionsUpdate({
                ...safeOptions,
                theme: {
                  ...safeOptions.theme,
                  typography: {
                    ...safeOptions.theme?.typography,
                    answerFont: font
                  }
                }
              })}
            />
          </ConfigureStyleDisclosure>
        </div>
      </FormSection>

      {/* Hints Section */}
      <FormSection 
        title="Hints" 
        subtitle="Style the hint display"
        icon={<LightBulbIcon className="w-5 h-5" />}
        infoTitle="Hint Appearance"
        infoContent="Customize how hints appear in your quiz, including their background, text color, and font style."
      >
        <div className="space-y-6">
          <ConfigureStyleDisclosure title="Hint Style">
            <ColorPicker
              label="Background"
              subtitle="Background color of the hint container"
              color={surfaceProps.hintBackground}
              opacity={surfaceProps.hintOpacity}
              onChange={(color) => handleThemeUpdate('hintBackground', color)}
              onOpacityChange={(value) => handleThemeUpdate('hintOpacity', value)}
              showOpacity={true}
              isActive={activeColorPicker === 'hintBackground'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'hintBackground' ? null : 'hintBackground')}
            />
            <ColorPicker
              label="Text Color"
              subtitle="Color of the hint text"
              color={surfaceProps.hintText}
              onChange={(color) => handleThemeUpdate('hintText', color)}
              isActive={activeColorPicker === 'hintText'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'hintText' ? null : 'hintText')}
            />
            <CustomFontPicker 
              label="Hint Font"
              subtitle="Font used for hints"
              value={safeOptions?.theme?.typography?.hintFont || 'Inter'}
              onChange={(font) => onOptionsUpdate({
                ...safeOptions,
                theme: {
                  ...safeOptions.theme,
                  typography: {
                    ...safeOptions.theme?.typography,
                    hintFont: font
                  }
                }
              })}
            />
          </ConfigureStyleDisclosure>
        </div>
      </FormSection>
    </div>
  );
}