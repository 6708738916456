import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { LockClosedIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const FormSwitch = ({
  name,
  label,
  subtitle,
  handleChange,
  options = {},
  disabled,
  disabledText,
  checked: controlledChecked,
  onChange: controlledOnChange,
}) => {
  const [checked, setChecked] = useState(
    controlledChecked !== undefined 
      ? controlledChecked 
      : options?.[name] || false
  );
  const [showDisabledMessage, setShowDisabledMessage] = useState(false);

  useEffect(() => {
    if (controlledChecked !== undefined) {
      setChecked(controlledChecked);
    }
  }, [controlledChecked]);

  const handleSwitchChange = (enabled) => {
    if (disabled) {
      setShowDisabledMessage(true);
      setTimeout(() => setShowDisabledMessage(false), 6000);
      return;
    }

    setChecked(enabled);
    
    if (controlledOnChange) {
      controlledOnChange(enabled);
    } else if (handleChange) {
      const event = {
        target: {
          name: name,
          value: enabled,
        },
      };
      handleChange(event);
    }
  };

  return (
    <div
      className={classNames(
        'flex flex-col',
        disabled && 'opacity-75 pointer-events-none'
      )}
    >
      <div className="flex items-center justify-between mb-2">
        <span className="flex flex-grow flex-col">
          <div className="flex items-center">
            {disabled && name === 'hideBranding' && (
              <span
                className={classNames(
                  'bg-yellow-400 text-indigo-800 text-xs font-semibold px-2 py-0.5 rounded inline-flex items-center capitalize',
                  'mr-2'
                )}
              >
                Premium
                <LockClosedIcon className="ml-1 h-3 w-3 text-gray-500" aria-hidden="true" />
              </span>
            )}
            <label className="text-sm font-medium leading-6 text-gray-900">
              {label}
            </label>
          </div>
          <span className="text-sm text-gray-500">
            {subtitle}
          </span>
        </span>
        <Switch
          checked={checked}
          onChange={handleSwitchChange}
          className={classNames(
            checked ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
            disabled && 'pointer-events-none'
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              checked ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch>
      </div>
      {showDisabledMessage && (
        <div className="text-xs text-red-500">
          {disabledText}
        </div>
      )}
    </div>
  );
};

export default FormSwitch;