import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLocationDot,
  faLightbulb,
  faGraduationCap,
  faEarthAmericas,
  faWorm,
} from '@fortawesome/free-solid-svg-icons';

export const gameTypes = [
  { 
    id: "pindrop",
    name: 'GeoPin',
    description: 'Place pins on a world map to mark locations. Accuracy determines your score.',
    aiOutline:"Famous landmarks in major cities around the world, such as the Eiffel Tower, Statue of Liberty, and Great Wall of China.",
    requiresAIGeneration: true,
    setupOptions: [
      {
        id: 'numberOfRounds',
        label: 'Number of rounds',
        type: 'number',
        min: 1,
        max: 10,
        defaultValue: 5,
      },
    ], 
    summaryDisplayOptions: [
      'name',
    ],
    icon: ({ className }) => (
      <div className={className}>
        <FontAwesomeIcon icon={faLocationDot} style={{ width: '100%', height: '100%' }} />
      </div>
    ),
    role: "free",
    exampleId: "62cmkStzelwwGovG9Fal",
    exampleName: "Global Landmark Adventure: Can You Pin 'Em All?",
    imageUrl: '/GameTypeImages/pindrop.png',
    additionalImages: [
      '/GameTypeImages/pindropmid.png',
      '/GameTypeImages/pindropend.png'
    ],
    options: {
      title: "Welcome to your new Pin-Drop campaign!",
      intro: "Get started by opening the campaign editor using the actions button",
      ctaEnabled: false,
      colorButton: "#EF4444",
      start_view: {
        lat: 54.5,
        lng: -2,
        zoom: 3
      },
      start_radius: 2,
      zoom_level: 17,
      mapType: "roadmap",
      data: [{
        id: "1",
        lat: 48.85802422036538,
        lng: 2.294508122090164,
        name: "Eiffel tower",
        zoom: 17
      }],
      leaderboard: {
        enabled: true,
        allowMultipleAttempts: false
      },
    },
    exampleTopics: "Try: World Landmarks, Capital Cities, Ancient Ruins",
  },
  {
    "id": "hint",
    "name": "HintGuess",
    "description": "Solve each puzzle using up to six hints. Each hint makes the answer clearer but lowers your score.",
    "aiOutline": "A difficult game based on Z-list celebrities...",
    "icon": ({ className }) => (
      <div className={className}>
        <FontAwesomeIcon icon={faLightbulb} style={{ width: '100%', height: '100%' }} />
      </div>
    ),
    "role": "free",
    "exampleId": "gFSPj1NRn3eMfEmRn1ZR",
    "exampleName": "Step into History: The Ultimate Figure Challenge!",
    "imageUrl": "/GameTypeImages/hintgame.png",
    "additionalImages": [
      "/GameTypeImages/hintend.png"
    ],
    "options": {
      "title": "Welcome to your new Hint-Guess campaign!",
      "intro": "Guess the answer based on the hints provided. You have up to 6 hints to figure it out.",
      "ctaEnabled": false,
      "colorButton": "#F59E0B",
      "backgroundImage": "",
      "data": [
        {
          "id": "1",
          "answer": "Eiffel Tower",
          "hints": [
            "It is located in Paris.",
            "This landmark was completed in 1889.",
            "It is named after the engineer who designed it.",
            "It is one of the most recognizable structures in the world.",
            "It was initially criticized by some of France's leading artists and intellectuals.",
            "It stands at 324 meters tall."
          ]
        }
      ],
      "dummyData": [
        "Empire State Building",
        "Big Ben",
        "Burj Khalifa"
      ],
      leaderboard: {
        enabled: true,
        allowMultipleAttempts: false
      },
    },
    setupOptions: [
      {
        id: 'numberOfQuestions',
        label: 'Number of rounds',
        type: 'number',
        min: 1,
        max: 10,
        defaultValue: 3,
      },
      {
        id: 'difficulty',
        label: 'Difficulty',
        type: 'radio',
        options: ['Easy', 'Medium', 'Hard', 'Impossible'],
        defaultValue: 'Medium',
      },
    ],
    summaryDisplayOptions: [
      'name',
    ],
    requiresAIGeneration: true,
    exampleTopics: "Try: Historical Figures, Famous Scientists, World Leaders",
  },
  { 
    id: "quiz",
    name: 'Quiz',
    description: 'Test your knowledge with multiple choice questions. Each correct answer adds to your score.',
    aiOutline:'Famous historical events, world capitals, or popular science facts.',
    requiresAIGeneration: true,
    setupOptions: [
      {
        id: 'numberOfQuestions',
        label: 'Number of questions',
        type: 'number',
        min: 1,
        max: 20,
        defaultValue: 5,
      },
      {
        id: 'difficulty',
        label: 'Difficulty',
        type: 'radio',
        options: ['Easy', 'Medium', 'Hard', 'Impossible'],
        defaultValue: 'Medium',
      },
      {
        id: 'addHints',
        label: 'Add Hints?',
        type: 'boolean',
        description: 'Include hints for each question.',
        defaultValue: false,
      },
      {
        id: 'tone',
        label: 'Tone of questions',
        type: 'radio',
        options: ['Serious', 'Light-Hearted', 'Humorous'],
        description: 'Set the tone of the questions',
        defaultValue: 'Serious',
      },
    ],
    summaryDisplayOptions: [
      'question'
    ],
    icon: ({ className }) => (
      <div className={className}>
        <FontAwesomeIcon icon={faGraduationCap} style={{ width: '100%', height: '100%' }} />
      </div>
    ),
    role: "free",
    exampleId: "FPsVoKVOWXUmL1bKNsZE",
    exampleName: "Journey Through Famous Historical Events",
    imageUrl: '/GameTypeImages/quiz.png',
    additionalImages: [
      '/GameTypeImages/quizend.png'
    ],
    options: {
      title: "Welcome to your new Quiz-Style campaign!",
      intro: "Get started by opening the campaign editor using the actions button",
      ctaEnabled: false,
      colorButton: "#3B82F6",
      backgroundImage: "",
      data: [
        {
          id: "1",
          question: "What is the capital of France?",
          options: [
            { text: "Paris", correct: true },
            { text: "London", correct: false },
            { text: "Rome", correct: false },
            { text: "Berlin", correct: false }
          ],
          hint: ""
        },
        {
          id: "2",
          question: "Which landmark is located in New York?",
          options: [
            { text: "Statue of Liberty", correct: true },
            { text: "Eiffel Tower", correct: false },
            { text: "Taj Mahal", correct: false },
            { text: "Sydney Opera House", correct: false }
          ],
          hint: ""
        }
      ],
      leaderboard: {
        enabled: true,
        allowMultipleAttempts: false
      },
    },
    exampleTopics: "Try: World History, Space Exploration, Ancient Civilizations",
  },
  { 
    id: "mapselect",
    name: 'GeoSelect',
    description: 'View locations from above and type the correct name. Explore famous places worldwide.',
    aiOutline:"Famous landmarks in major cities around the world, such as the Eiffel Tower, Statue of Liberty, and Great Wall of China.",
    requiresAIGeneration: true,
    setupOptions: [
      {
        id: 'numberOfRounds',
        label: 'Number of Rounds',
        type: 'number',
        min: 1,
        max: 10,
        defaultValue: 5,
      },
    ], 
    icon: ({ className }) => (
      <div className={className}>
        <FontAwesomeIcon icon={faEarthAmericas} style={{ width: '100%', height: '100%' }} />
      </div>
    ),
    role: "free",
    exampleId: "DJJ96OosfG0GpUahlV8W",
    exampleName: "Journey Through Landmarks: A Global Scavenger Quest",
    imageUrl: '/GameTypeImages/mapselect.png',
    additionalImages: [
      '/GameTypeImages/mapmultiend.png'
    ],
    options: {
      title: "Welcome to your new Map-Select campaign!",
      intro: "Get started by opening the campaign editor using the actions button",
      ctaEnabled: false,
      colorButton: "#10B981",
      mapType: "satellite",
      movement: true,
      showIcon: false,
      data: [
        {
          id: "1",
          lat: 40.689247,
          lng: -74.044502,
          name: "Statue of Liberty",
          zoom: 18
        },
        {
          id: "2",
          lat: 48.85802422036538,
          lng: 2.294508122090164,
          name: "Eiffel Tower",
          zoom: 17
        },
        {
          id: "3",
          lat: 27.1751448,
          lng: 78.0421422,
          name: "Taj Mahal",
          zoom: 17
        },
        {
          id: "4",
          lat: -33.8567844,
          lng: 151.2152967,
          name: "Sydney Opera House",
          zoom: 17
        }
      ],
      leaderboard: {
        enabled: true,
        allowMultipleAttempts: false
      },
    },
    summaryDisplayOptions: [
      'name'
    ],
    exampleTopics: "Try: Famous Buildings, Natural Wonders, Historic Sites",
  },
  { 
    id: "snake",
    name: 'Snake',
    description: 'Control a snake to collect items and grow longer. Avoid collisions in this arcade classic.',
    aiOutline: 'A customizable snake game where players navigate to collect items while avoiding collisions.',
    requiresAIGeneration: false,
    icon: ({ className }) => (
      <div className={className}>
        <FontAwesomeIcon icon={faWorm} style={{ width: '100%', height: '100%' }} />
      </div>
    ),
    role: "free",
    exampleId: "snakeExample123",
    exampleName: "Classic Snake: The Ultimate Challenge!",
    imageUrl: '/GameTypeImages/snake.png',
    additionalImages: [
      '/GameTypeImages/snakeend.png'
    ],
    options: {
      title: "Classic Snake Adventure",
      intro: "Navigate your snake through the arena, collecting items to grow longer. But be careful - don't hit the walls or yourself! Use arrow keys or swipe to control your snake's direction.",
      ctaEnabled: false,
      colorButton: "#8B5CF6",
      backgroundImage: "",
      leaderboard: {
        enabled: true,
        allowMultipleAttempts: true
      },
      hideInstructions: true, 
      data: [{
        id: "1",
        gameSettings: {
          snakeColor: '#4F46E5',
          foodColor: '#EF4444',
          gridColor: '#E5E7EB',
        }
      }]
    },
    summaryDisplayOptions: [
      'score'
    ],
    exampleTopics: "Try: Math Problems, Vocabulary Words, Science Facts",
  },
];

