import React, { useEffect } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import WebFont from 'webfontloader';

const HeadlineDisplay = ({ customization = {}, defaultPhotoUrl = '/default-profile.png' }) => {
  useEffect(() => {
    const headlineFont = customization?.headlineFont;
    const subheadlineFont = customization?.subheadlineFont;

    const fontsToLoad = [...new Set([headlineFont, subheadlineFont])]
      .filter(font => font && font !== 'Roboto' && font !== 'Inter');

    if (fontsToLoad.length > 0) {
      WebFont.load({
        google: {
          families: fontsToLoad
        }
      });
    }
  }, [customization?.headlineFont, customization?.subheadlineFont]);

  const getImageShape = () => {
    switch (customization?.imageShape) {
      case 'square': return 'rounded-none';
      case 'rounded': return 'rounded-md';
      case 'circle':
      default: return 'rounded-full';
    }
  };

  const formattedUrl = customization?.headline_url ? 
    (!customization.headline_url.startsWith('http') ? `http://${customization.headline_url}` : customization.headline_url)
    : null;

    console.log('HeadlineDisplay - Full customization object:', customization);
    console.log('HeadlineDisplay - Theme colors:', customization?.theme?.colors);
    console.log('HeadlineDisplay - Headline colors:', {
      fromTheme: customization?.theme?.colors?.headline?.headlineColor,
      fromCustomization: customization?.headlineColor,
      final: customization?.theme?.colors?.headline?.headlineColor || customization?.headlineColor || '#374151'
    });

  const styles = {
    headline: {
      color: customization?.theme?.colors?.headline?.headlineColor || customization?.headlineColor || '#374151',
      fontFamily: customization?.headlineFont || 'Roboto',
      ...(formattedUrl && {
        transition: 'opacity 200ms ease-in-out'
      })
    },
    subheadline: {
      color: customization?.theme?.colors?.headline?.subheadlineColor || customization?.subheadlineColor || '#6B7280',
      fontFamily: customization?.subheadlineFont || 'Roboto',
      ...(formattedUrl && {
        transition: 'opacity 200ms ease-in-out'
      })
    },
    icon: {
      color: customization?.theme?.colors?.headline?.iconColor || customization?.subheadlineColor || '#6B7280',
      ...(formattedUrl && {
        transition: 'opacity 200ms ease-in-out'
      })
    }
  };

  const HeadlineContent = () => (
    <>
      <img
        className={`inline-block h-9 w-9 ${getImageShape()}`}
        src={customization?.headlinePhoto || defaultPhotoUrl}
        alt=""
      />
      <div className="ml-3">
        <p className="text-sm font-medium group-hover:opacity-80" style={styles.headline}>
          {customization?.headline}
        </p>
        <p className="text-xs font-medium flex items-center group-hover:opacity-80" style={styles.subheadline}>
          {customization?.subheadline}
          {formattedUrl && customization?.showIcon && (
            <ArrowTopRightOnSquareIcon 
              className="ml-1 h-3 w-3 group-hover:opacity-80" 
              style={styles.icon}
              aria-hidden="true" 
            />
          )}
        </p>
      </div>
    </>
  );

  return (
    <div className="flex flex-col">
      {formattedUrl ? (
        <a 
          href={formattedUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center group hover:opacity-80 transition-all duration-200"
          title={`Opens ${formattedUrl} in a new tab`}
          aria-label={`Opens ${formattedUrl} in a new tab`}
        >
          <HeadlineContent />
        </a>
      ) : (
        <div className="flex items-center">
          <HeadlineContent />
        </div>
      )}
    </div>
  );
};

export default HeadlineDisplay; 