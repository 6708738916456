export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

export const getLuminance = (r, g, b) => {
  const [rs, gs, bs] = [r, g, b].map(c => {
    c = c / 255;
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
};

export const getContrastTextColor = (backgroundColor) => {
  const rgb = hexToRgb(backgroundColor);
  if (!rgb) return '#FFFFFF';
  const luminance = getLuminance(rgb.r, rgb.g, rgb.b);
  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

export const adjustColor = (hex, amount) => {
  const rgb = hexToRgb(hex);
  if (!rgb) return hex;
  
  const newRgb = Object.keys(rgb).reduce((acc, key) => {
    acc[key] = Math.max(0, Math.min(255, rgb[key] + amount));
    return acc;
  }, {});
  
  return `#${Object.values(newRgb)
    .map(x => x.toString(16).padStart(2, '0'))
    .join('')}`;
};

export const generateTheme = (primaryColor, secondaryColor) => {
  return {
    colors: {
      primary: {
        main: primaryColor,
        text: getContrastTextColor(primaryColor),
        hover: adjustColor(primaryColor, 20)
      },
      secondary: {
        main: secondaryColor,
        text: getContrastTextColor(secondaryColor),
        hover: adjustColor(secondaryColor, 20)
      },
      background: {
        gradient: {
          start: adjustColor(primaryColor, 140),
          end: adjustColor(primaryColor, -60)
        }
      },
      surface: {
        background: 'rgba(255, 255, 255, 0.1)',
        text: '#FFFFFF',
        border: 'rgba(255, 255, 255, 0.1)',
        isTransparent: true
      }
    },
    opacity: {
      overlay: 0.5,
      hover: 0.8
    },
    transitions: {
      duration: '200ms',
      timing: 'ease-in-out'
    }
  };
}; 