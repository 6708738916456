import React, { useState, useEffect, useRef } from 'react';
import { RadioGroup, Switch } from '@headlessui/react';
import { ArrowPathIcon, CheckIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import Slider from '@mui/material/Slider';
import { getGameTypeColor } from './CreateCampaign';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const getGridCols = (numOptions) => {
  if (numOptions <= 2) return 'grid-cols-1';
  if (numOptions <= 3) return 'grid-cols-1 md:grid-cols-3';
  if (numOptions <= 4) return 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4';
  return 'grid-cols-1 md:grid-cols-3 lg:grid-cols-4';
};

// Helper function to safely stringify objects with circular references
const safeStringify = (obj) => {
  const seen = new WeakSet();
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return '[Circular]';
      }
      seen.add(value);
    }
    return value;
  }, 2);
};

export default function InitialSetup({ formData, onNext, onBack }) {
  const gameTypeColor = formData?.gameType?.id ? getGameTypeColor(formData.gameType.id) : "#4F46E5";
  const [localFormData, setLocalFormData] = useState(() => {
    // Initialize with default values
    const defaultSettings = formData?.gameType?.setupOptions?.reduce((acc, option) => ({
      ...acc,
      [option.id]: option.defaultValue !== undefined ? option.defaultValue : acc[option.id]
    }), {});

    return {
      shortDescription: formData?.shortDescription || '',
      gameType: formData?.gameType,
      additionalSettings: {
        ...defaultSettings,
        ...formData?.additionalSettings,
      }
    };
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [MAX_RETRIES, RETRY_DELAY] = [3, 1000];

  // Add a ref for the textarea
  const textareaRef = useRef(null);

  useEffect(() => {
    console.log('InitialSetup Mount - Received formData:', safeStringify({
      shortDescription: formData?.shortDescription,
      gameType: {
        id: formData?.gameType?.id,
        name: formData?.gameType?.name,
        requiresAIGeneration: formData?.gameType?.requiresAIGeneration
      },
      additionalSettings: formData?.additionalSettings
    }));
  }, []);

  useEffect(() => {
    console.log('FormData Update:', safeStringify({
      formData: {
        shortDescription: formData?.shortDescription,
        gameDescription: formData?.gameDescription,
        gameType: {
          id: formData?.gameType?.id,
          name: formData?.gameType?.name,
          requiresAIGeneration: formData?.gameType?.requiresAIGeneration
        },
        additionalSettings: formData?.additionalSettings
      },
      localFormData: {
        shortDescription: localFormData.shortDescription,
        gameDescription: localFormData.gameDescription,
        gameType: {
          id: localFormData.gameType?.id,
          name: localFormData.gameType?.name,
          requiresAIGeneration: localFormData.gameType?.requiresAIGeneration
        },
        additionalSettings: localFormData.additionalSettings
      },
      timestamp: new Date().toISOString()
    }));
    
    if (formData) {
      const defaultSettings = formData.gameType?.setupOptions?.reduce((acc, option) => ({
        ...acc,
        [option.id]: option.defaultValue !== undefined ? option.defaultValue : acc[option.id]
      }), {});

      setLocalFormData({
        shortDescription: formData.shortDescription || '',
        gameType: formData.gameType,
        additionalSettings: {
          ...defaultSettings,
          ...formData.additionalSettings, // This allows existing settings to override defaults
        }
      });
    }
  }, [formData]);

  // Add useEffect for auto-resizing
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [localFormData.shortDescription]); // Re-run when content changes

  const handleChange = (nameOrEvent, value) => {
    if (typeof nameOrEvent === 'object' && nameOrEvent.target) {
      const { name, value } = nameOrEvent.target;
      setLocalFormData(prev => ({
        ...prev,
        [name]: value
      }));
    } else {
      setLocalFormData(prev => ({
        ...prev,
        [nameOrEvent]: value
      }));
    }
  };

  const handleSettingChange = (optionId, value) => {
    setLocalFormData(prev => ({
      ...prev,
      additionalSettings: {
        ...prev.additionalSettings,
        [optionId]: value,
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataToSubmit = {
      ...localFormData,
      gameType: formData.gameType
    };
    
    if (!formData.gameType.requiresAIGeneration) {
      onNext({
        ...dataToSubmit,
        aiGeneratedContent: {
          options: formData.gameType.options
        }
      });
    } else {
      onNext(dataToSubmit);
    }
  };

  const attemptGeneration = async (retryCount = 0) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/app/generate-game`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          gameTypeId: formData.gameType.id,
          shortDescription: localFormData.shortDescription,
          additionalSettings: localFormData.additionalSettings
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to generate game content');
      }

      onNext({
        ...localFormData,
        aiGeneratedContent: {
          options: data.message.options
        }
      });
      
    } catch (error) {
      console.error('Generation error:', error);
      setError(error.message);
      
      if (retryCount < MAX_RETRIES) {
        setTimeout(() => attemptGeneration(retryCount + 1), RETRY_DELAY);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderSettingInput = (option) => {
    switch (option.type) {
      case 'number':
        return (
          <div className="mt-2 space-y-2">
            <div className="flex items-center justify-between">
              {option.description && (
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {option.description}
                </span>
              )}
              <span className="text-sm font-medium" style={{ color: gameTypeColor }}>
                {localFormData.additionalSettings?.[option.id] || option.min}
              </span>
            </div>
            <Slider
              size="small"
              value={localFormData.additionalSettings?.[option.id] || option.min}
              onChange={(_, newValue) => handleSettingChange(option.id, newValue)}
              min={option.min}
              max={option.max}
              sx={{
                '& .MuiSlider-root': {
                  color: gameTypeColor
                },
                '& .MuiSlider-thumb': {
                  width: 14,
                  height: 14,
                  backgroundColor: '#fff',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: gameTypeColor,
                  '&:hover, &.Mui-focusVisible': {
                    boxShadow: `0 0 0 8px ${gameTypeColor}26`
                  }
                },
                '& .MuiSlider-track': {
                  height: 4,
                  backgroundColor: gameTypeColor
                },
                '& .MuiSlider-rail': {
                  height: 4,
                  opacity: 0.2,
                  backgroundColor: '#bfbfbf'
                }
              }}
            />
          </div>
        );
      case 'radio':
        return (
          <div className="mt-2">
            {option.description && (
              <p className="mb-3 text-sm text-gray-500 dark:text-gray-400">
                {option.description}
              </p>
            )}
            <RadioGroup
              value={localFormData.additionalSettings?.[option.id]}
              onChange={(value) => handleSettingChange(option.id, value)}
              className={classNames(
                "grid w-full gap-3",
                getGridCols(option.options.length)
              )}
            >
              {option.options.map((radioOption) => (
                <RadioGroup.Option
                  key={radioOption}
                  value={radioOption}
                  className={({ checked }) => classNames(
                    'cursor-pointer rounded-lg border p-4 shadow-sm focus:outline-none w-full transition-all duration-200',
                    checked ? `border-2 ring-2 ring-offset-2` : 'border-gray-300 hover:border-opacity-50',
                    'relative flex items-center justify-center'
                  )}
                >
                  {({ checked }) => (
                    <div 
                      className="flex items-center justify-between w-full"
                      style={{
                        borderColor: checked ? gameTypeColor : '',
                        '--tw-ring-color': checked ? gameTypeColor : ''
                      }}
                    >
                      <span 
                        className={classNames(
                          'text-sm font-medium',
                          checked ? '' : 'text-gray-900 dark:text-white'
                        )}
                        style={{
                          color: checked ? gameTypeColor : ''
                        }}
                      >
                        {radioOption}
                      </span>
                      {checked && (
                        <CheckIcon 
                          className="h-5 w-5" 
                          style={{
                            color: gameTypeColor
                          }} 
                          aria-hidden="true" 
                        />
                      )}
                    </div>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </div>
        );
      case 'select':
        return (
          <div className="mt-2">
            {option.description && (
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                {option.description}
              </p>
            )}
            <select
              value={localFormData.additionalSettings?.[option.id] || ''}
              onChange={(e) => handleSettingChange(option.id, e.target.value)}
              className="block w-full rounded-md border-gray-300 dark:border-gray-700 
                shadow-sm focus:ring-2 focus:ring-offset-2 
                dark:bg-gray-800 dark:text-white sm:text-sm
                transition-colors duration-200"
              style={{
                borderColor: `${gameTypeColor}40`,
                outlineColor: gameTypeColor
              }}
            >
              {option.options.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>
        );
      case 'boolean':
        return (
          <Switch.Group as="div" className="flex items-center justify-between mt-2">
            {option.description && (
              <Switch.Label as="span" className="text-sm text-gray-500 dark:text-gray-400">
                {option.description}
              </Switch.Label>
            )}
            <Switch
              checked={localFormData.additionalSettings?.[option.id] ?? false}
              onChange={(checked) => handleSettingChange(option.id, checked)}
              className={`${
                localFormData.additionalSettings?.[option.id] ? '' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full 
              border-2 border-transparent transition-colors duration-200 ease-in-out 
              focus:outline-none focus:ring-2 focus:ring-offset-2`}
              style={{
                backgroundColor: localFormData.additionalSettings?.[option.id] ? gameTypeColor : undefined,
                outlineColor: gameTypeColor
              }}
            >
              <span
                aria-hidden="true"
                className={`${
                  localFormData.additionalSettings?.[option.id] ? 'translate-x-5' : 'translate-x-0'
                } pointer-events-none inline-block h-5 w-5 transform rounded-full 
                bg-white shadow ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </Switch.Group>
        );
      default:
        return null;
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 }
  };

  // For non-AI games, skip if no options
  if (!formData.gameType?.requiresAIGeneration) {
    // If no setup options, skip this step by calling onNext immediately
    if (!formData.gameType?.setupOptions?.length) {
      onNext({
        ...localFormData,
        aiGeneratedContent: {
          options: formData.gameType.options
        }
      });
      return null; // Return null to not render anything
    }

    // Only render if there are setup options
    return (
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-2xl mx-auto"
      >
        <div className="space-y-8">
          {formData.gameType.setupOptions.map((option, index) => (
            <motion.fieldset
              key={option.id}
              variants={itemVariants}
              className="mb-6"
            >
              <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                {option.label}
              </legend>
              {renderSettingInput(option)}
            </motion.fieldset>
          ))}
        </div>
      </motion.div>
    );
  }

  // Keep existing AI form return
  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <form onSubmit={handleSubmit} className="mt-4">
        <motion.fieldset variants={itemVariants} className="mb-6">
          <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
            What's your game about?
          </legend>
          <div className="mt-2">
            <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
              We will generate a draft for you. Don't worry, you can customize everything later.
            </p>
            <textarea
              ref={textareaRef}
              name="shortDescription"
              value={localFormData.shortDescription || ''}
              onChange={handleChange}
              rows={1}
              className="block w-full rounded-md border-gray-300 
                dark:border-gray-700 shadow-sm focus:ring-2 focus:ring-offset-2 
                dark:bg-gray-800 dark:text-white text-sm resize-none leading-tight
                transition-colors duration-200 md:py-2 min-h-[40px]"
              style={{
                borderColor: `${gameTypeColor}40`,
                outlineColor: gameTypeColor,
                overflow: 'hidden' // Hide scrollbar during resize
              }}
              placeholder={`${formData.gameType?.exampleTopics || "Enter your game topic"}`}
              required
            />
          </div>
        </motion.fieldset>

        {formData.gameType?.setupOptions && formData.gameType.setupOptions.map((option) => (
          <motion.fieldset key={option.id} variants={itemVariants} className="mb-6">
            <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
              {option.label}
            </legend>
            {renderSettingInput(option)}
          </motion.fieldset>
        ))}

        <motion.div variants={itemVariants} className="mt-6">
          <div className="flex justify-between">
            <button
              type="button"
              onClick={onBack}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
            >
              Back
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:opacity-90 transition-opacity duration-200"
              style={{
                backgroundColor: gameTypeColor
              }}
            >
              Continue
            </button>
          </div>
        </motion.div>

        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </form>
    </motion.div>
  );
}
