import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import Layout from './Layout';
import Login from './dashboard/Login';
import Register from './dashboard/Signup';
import Settings from './account/Settings';
import Campaigns from './dashboard/Campaigns';
import Campaign from './dashboard/Campaign';
import Examples from './dashboard/Examples';
import Play from './games/Play';
import Editor from './games/Editor';
import { UserProvider, useUser } from './userContext';
import PrivateRoute from './PrivateRoute';
import Support from './dashboard/Support';
import SharedByUsers from './dashboard/SharedByUsers';
import ScrollToTop from './dashboard/ScrollToTop';
import PlanModal from './dashboard/PlanModal';
import GameCenter from './GameCenter/GameCenterHome';
import Error from './account/ErrorPage';
import NewCampaign from './campaign/CreateCampaign';
import ClientHome from './clients/clientHome';
import EditClient from './clients/EditClient';

const AppContent = () => {
  const { businessProfile } = useUser();
  
  // Use businessProfile.id as a key to force re-render of the entire protected app
  const appKey = businessProfile?.id || 'default';

  return (
    <>
      <ScrollToTop />
      <Routes>
        {/* Public routes */}
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="error" element={<Error />} />
        <Route path="play/:id" element={<Play />} />
        <Route path="playground" element={<GameCenter />} />
        
        {/* Editor route - outside of Layout */}
        <Route
          path="editor/:id"
          element={
            <PrivateRoute key={appKey}>
              <Editor />
            </PrivateRoute>
          }
        />

        {/* Protected routes with Layout */}
        <Route
          path="/"
          element={
            <PrivateRoute key={appKey}>
              <Layout />
            </PrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="plan" element={<PlanModal />} />
          <Route path="support" element={<Support />} />
          <Route path="settings/*" element={<Settings />} />
          <Route path="success" element={<Dashboard />} />
          <Route path="campaigns" element={<Campaigns />} />
          <Route path="campaign/:id" element={<Campaign />} />
          <Route path="games" element={<Examples />} />
          <Route path="shared" element={<SharedByUsers />} />
          <Route path="new" element={<NewCampaign />} />
          <Route path="clients" element={<ClientHome />} />
          <Route path="/edit-client/:id" element={<EditClient />} />
        </Route>
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <UserProvider>
        <AppContent />
      </UserProvider>
    </BrowserRouter>
  );
};

export default App;