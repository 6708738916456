import React, { useState, useEffect, useRef, memo, useMemo } from 'react';
import LineChart from '../charts/LineChart02';
import { fetchUserStats } from '../data/statsService';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import BeatLoader from 'react-spinners/BeatLoader';
import { tailwindConfig } from '../utils/Utils';
import { Chart } from 'chart.js';

// Memoized version of the LineChart component
const MemoizedLineChart = memo(LineChart);

function DashboardChart({ campaignIds }) {
  const [rawChartData, setRawChartData] = useState(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [totals, setTotals] = useState({ plays: 0, ctas: 0, shares: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [visibleDatasets, setVisibleDatasets] = useState({
    'Plays': true,
    'CTA Clicks': true,
    'Shares': true
  });

  // Add ref to store chart instance
  const chartRef = useRef(null);

  const chartOptions = useMemo(() => ({
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM D',
          displayFormats: {
            day: 'MMM D',
          },
        },
        ticks: {
          source: 'data',
          autoSkip: false,
          maxRotation: 0,
          color: tailwindConfig().theme.colors.gray[500],
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        min: 0,
        suggestedMin: 0,
        suggestedMax: 10,
        ticks: {
          maxTicksLimit: 5,
          color: tailwindConfig().theme.colors.gray[500],
          callback: (value) => Math.round(value),
        },
        grid: {
          color: tailwindConfig().theme.colors.gray[200],
        },
        afterDataLimits: (scale) => {
          if (scale.max < 10) {
            scale.max = 10;
          }
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        align: 'start',
        labels: {
          usePointStyle: true,
          padding: 20,
          boxWidth: 8,
          boxHeight: 8,
          color: tailwindConfig().theme.colors.gray[700],
          cursor: 'pointer',
        },
        onClick: function(e, legendItem, legend) {
          const index = legendItem.datasetIndex;
          const label = legendItem.text;

          const chart = legend.chart;
          if (chart.isDatasetVisible(index)) {
            chart.hide(index);
          } else {
            chart.show(index);
          }

          setVisibleDatasets(prev => ({
            ...prev,
            [label]: chart.isDatasetVisible(index)
          }));
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: (context) => {
            return context[0].label;
          },
          label: (context) => {
            const label = context.dataset.label || '';
            return `${label}: ${context.parsed.y}`;
          },
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    elements: {
      line: {
        tension: 0.3,
        borderWidth: 2,
      },
      point: {
        radius: 0,
      },
    },
    maintainAspectRatio: false,
    resizeDelay: 200,
  }), []);

  // Load raw data and calculate totals
  useEffect(() => {
    async function loadChartData() {
      setIsLoading(true);
      const cachedChartData = localStorage.getItem('cachedChartData');
      const cacheExpiryTime = localStorage.getItem('cacheExpiryTime');

      if (cachedChartData && cacheExpiryTime && new Date() < new Date(cacheExpiryTime)) {
        const parsedData = JSON.parse(cachedChartData);
        setRawChartData(parsedData);
        
        // Calculate totals from the cached data
        const calculatedTotals = {
          plays: parsedData.datasets[0].data.reduce((sum, val) => sum + val, 0),
          ctas: parsedData.datasets[1].data.reduce((sum, val) => sum + val, 0),
          shares: parsedData.datasets[2].data.reduce((sum, val) => sum + val, 0)
        };
        setTotals(calculatedTotals);
      } else {
        let labels = [];
        let playCounts = [];
        let ctaCounts = [];
        let shareCounts = [];

        if (campaignIds && campaignIds.length > 0) {
          const stats = await fetchUserStats(campaignIds);
          labels = stats.labels.map(label => new Date(label).toISOString());
          playCounts = stats.playCounts;
          ctaCounts = stats.ctaCounts;
          shareCounts = stats.shareCounts;
        } else {
          // Generate empty data for the last 3 days
          const today = new Date();
          for (let i = 2; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            labels.push(date.toISOString());
            playCounts.push(0);
            ctaCounts.push(0);
            shareCounts.push(0);
          }
        }

        const newChartData = {
          labels,
          datasets: [
            {
              label: 'Plays',
              data: playCounts,
              borderColor: tailwindConfig().theme.colors.violet[500],
              backgroundColor: `${tailwindConfig().theme.colors.violet[500]}33`,
              fill: true,
            },
            {
              label: 'CTA Clicks',
              data: ctaCounts,
              borderColor: tailwindConfig().theme.colors.sky[400],
              backgroundColor: `${tailwindConfig().theme.colors.sky[400]}33`,
              fill: true,
            },
            {
              label: 'Shares',
              data: shareCounts,
              borderColor: tailwindConfig().theme.colors.emerald[400],
              backgroundColor: `${tailwindConfig().theme.colors.emerald[400]}33`,
              fill: true,
            },
          ],
        };
        setRawChartData(newChartData);
        
        // Calculate totals from the new data
        const calculatedTotals = {
          plays: playCounts.reduce((sum, val) => sum + val, 0),
          ctas: ctaCounts.reduce((sum, val) => sum + val, 0),
          shares: shareCounts.reduce((sum, val) => sum + val, 0)
        };
        setTotals(calculatedTotals);
        
        localStorage.setItem('cachedChartData', JSON.stringify(newChartData));
        localStorage.setItem('cachedTotals', JSON.stringify(calculatedTotals));

        const expiryTime = new Date();
        expiryTime.setMinutes(expiryTime.getMinutes() + 10);
        localStorage.setItem('cacheExpiryTime', expiryTime.toISOString());
      }
      setIsLoading(false);
    }

    loadChartData();
  }, [campaignIds]);

  // Update chart data when visibility changes
  useEffect(() => {
    if (rawChartData) {
      setChartData({
        ...rawChartData,
        datasets: rawChartData.datasets.map(dataset => ({
          ...dataset,
          hidden: !visibleDatasets[dataset.label]
        }))
      });
    }
  }, [visibleDatasets, rawChartData]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'gameSummary'), () => {
      localStorage.removeItem('cachedChartData');
      localStorage.removeItem('cachedTotals');
      localStorage.removeItem('cacheExpiryTime');
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-56">
        <BeatLoader size={15} color={tailwindConfig().theme.colors.violet[500]} />
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <header className="px-5 py-4 border-b border-gray-100 dark:border-gray-700/60 flex justify-between items-center">
        <h2 className="font-semibold text-gray-800 dark:text-gray-100">Recent Activity</h2>
        <span className="text-xs uppercase font-semibold text-gray-400">Last 3 Days</span>
      </header>

      {/* Combined Legend Buttons with Stats */}
      <div className="px-5 pt-4 pb-2 flex gap-3">
        <button
          onClick={() => setVisibleDatasets(prev => ({ ...prev, 'Plays': !prev['Plays'] }))}
          className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm ${
            visibleDatasets['Plays'] ? 'bg-violet-50 text-violet-600' : 'bg-gray-50 text-gray-400'
          }`}
        >
          <span className="w-2 h-2 rounded-full bg-violet-500 mr-2"></span>
          <span className="font-semibold">{totals.plays.toLocaleString()}</span>
          <span className="ml-1.5">Plays</span>
        </button>

        <button
          onClick={() => setVisibleDatasets(prev => ({ ...prev, 'CTA Clicks': !prev['CTA Clicks'] }))}
          className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm ${
            visibleDatasets['CTA Clicks'] ? 'bg-sky-50 text-sky-600' : 'bg-gray-50 text-gray-400'
          }`}
        >
          <span className="w-2 h-2 rounded-full bg-sky-400 mr-2"></span>
          <span className="font-semibold">{totals.ctas.toLocaleString()}</span>
          <span className="ml-1.5">CTA</span>
        </button>

        <button
          onClick={() => setVisibleDatasets(prev => ({ ...prev, 'Shares': !prev['Shares'] }))}
          className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm ${
            visibleDatasets['Shares'] ? 'bg-emerald-50 text-emerald-600' : 'bg-gray-50 text-gray-400'
          }`}
        >
          <span className="w-2 h-2 rounded-full bg-emerald-400 mr-2"></span>
          <span className="font-semibold">{totals.shares.toLocaleString()}</span>
          <span className="ml-1.5">Shares</span>
        </button>
      </div>

      <MemoizedLineChart data={chartData} options={chartOptions} width={595} height={248} />
    </div>
  );
}

export default memo(DashboardChart);