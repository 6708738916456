import React, { useState, useEffect } from 'react';

export default function UpdateName({ onClose, onUpdateCampaignName, currentName }) {
  const [campaignName, setCampaignName] = useState(currentName || "");

  useEffect(() => {
    setCampaignName(currentName || "");
  }, [currentName]);

  function onSubmit(event) {
    event.preventDefault();

    if (!campaignName) {
      alert("Please fill in all fields.");
      return;
    }

    onUpdateCampaignName(campaignName);
    onClose();
  }

  return (
    <>
      <div className="px-5 py-4">
        <div className="text-sm">
          <p className="text-gray-500">This name is for your internal use only and will not be visible to end-users.</p>
          <form id="campaignName" onSubmit={onSubmit}>
            <div className="mt-3">
              <label htmlFor="campaignName" className="sr-only">Name</label>
              <input
                type="text"
                name="campaignName"
                id="campaignName"
                maxLength={50}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Campaign Name"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="px-5 py-4 border-t border-gray-200 dark:border-gray-700/60">
        <div className="flex flex-wrap justify-end space-x-2">
          <button
            className="btn-sm border-gray-200 dark:border-gray-700 hover:border-gray-300 dark:hover:border-gray-600 text-gray-600 dark:text-gray-300"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            form="campaignName"
            className={`btn-sm ${
              !campaignName 
                ? 'bg-indigo-400 cursor-not-allowed' 
                : 'bg-indigo-500 hover:bg-indigo-600'
            } text-white`}
            disabled={!campaignName}
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
}
