import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalBlank from '../components/ModalBlank';
import PlanSelection from '../account/Plans';
import { useUser } from '../userContext';
import { ArrowRightIcon, RocketLaunchIcon } from '@heroicons/react/20/solid';
import { SquareLoader } from 'react-spinners';

const SubscriptionModal = ({
  showModal,
  setShowModal,
  handleSignOut,
  onClose,
}) => {
  const [currentStep, setCurrentStep] = useState('welcome');
  const [planRedirectLoading, setPlanRedirectLoading] = useState(false);
  const { subscriptionStatus } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (showModal) {
      if (window.location.search.includes('session_id')) {
        console.log('SubscriptionModal: session_id found in URL');
        if (['active', 'trialing'].includes(subscriptionStatus)) {
          console.log('SubscriptionModal: Subscription is active or trialing, showing success message');
          setCurrentStep('subscriptionSuccess');
          setTimeout(() => {
            console.log('SubscriptionModal: Transitioning to thank you message');
            setCurrentStep('thankYou');
          }, 1000);
        } else {
          console.log('SubscriptionModal: Unexpected subscription status, showing plan selection');
          setCurrentStep('planSelection');
        }
      } else if (subscriptionStatus === 'expired') {
        console.log('SubscriptionModal: Subscription is inactive, showing plan selection');
        setCurrentStep('planSelection');
      } else {
        console.log('SubscriptionModal: No session_id in URL, staying on welcome screen');
        setCurrentStep('welcome');
      }
    }
  }, [subscriptionStatus, showModal]);

  const handleGoToDashboard = () => {
    console.log('SubscriptionModal: Handling Go to Dashboard click');
    const currentUrl = window.location.href;
    const newUrl = currentUrl.split('/success')[0];

    console.log('SubscriptionModal: Updating URL from', currentUrl, 'to', newUrl);
    window.history.replaceState({}, '', newUrl);

    console.log('SubscriptionModal: Closing modal');
    setShowModal(false);

    console.log('SubscriptionModal: Resetting current step to welcome');

  };

  const renderModalContent = () => {
    const hasNeverSubscribed = !['active', 'trialing'].includes(subscriptionStatus);

    if (planRedirectLoading) {
      return (
        <div className="flex flex-col items-center justify-center py-20">
          <div className='py-5'>
            <SquareLoader size={15} color="#4f46e5" />
          </div>
          <p className="text-sm text-gray-500 mt-2">Preparing your plan...</p>
        </div>
      );
    }

    switch (currentStep) {
      case 'welcome':
        return (
          <div className="relative overflow-hidden rounded-t-lg">
            <img className="w-full" src="/welcome.png" width="40" height="200" alt="Welcome to SmilesFlow" />
            <div className="text-center py-5 px-5">
              <p>We're excited to have you here. {hasNeverSubscribed && <span className="font-medium">Start with a 7-day free trial and</span>} select the best plan for your needs.</p>
              <button
                className="btn py-2 bg-gray-800 rounded hover:bg-gray-900 text-white text-sm mt-4 flex items-center justify-center"
                onClick={() => setCurrentStep('planSelection')}
              >
                {hasNeverSubscribed ? 'Start Free Trial' : 'Get Started'}
                <ArrowRightIcon className="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        );
      case 'subscriptionSuccess':
        return (
          <div className="flex flex-col items-center justify-center py-20">
            <div className='py-5'>
              <SquareLoader size={15} color="#4f46e5" />
            </div>
          </div>
        );
      case 'planSelection':
        return (
          <>
            <h2 className="text-lg px-5 mt-5 font-semibold">
              {subscriptionStatus === 'expired' ? 'Your plan has expired' : 'Choose your plan'}
            </h2>
            <p className="px-5">
              {subscriptionStatus === 'expired' 
                ? 'Please select a new plan to continue.'
                : hasNeverSubscribed 
                  ? 'Start with a 7-day free trial on any plan.'
                  : 'Please choose a plan to continue.'}
            </p>
            <PlanSelection isModal={true} showTrial={hasNeverSubscribed} />
          </>
        );
      case 'thankYou':
        return (
          <div className="text-center py-10 px-5">
            <div className="w-16 h-16 mx-auto mb-4 flex items-center justify-center bg-blue-500 rounded-full">
              <RocketLaunchIcon className="h-8 w-8 text-white" />
            </div>
            <p className="text-gray-700">We're thrilled to have you on board. Enjoy using SmilesFlow, and don't hesitate to reach out if you have any questions.</p>
            <button
              className="btn py-2 bg-gray-800 rounded hover:bg-gray-900 text-white text-sm mt-4"
              onClick={handleGoToDashboard}
            >
              Go to Dashboard
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const modalMaxWidth = currentStep === 'planSelection' ? 'max-w-3xl' : 'max-w-md';

  return (
    <ModalBlank
      id="subscription-issue-modal"
      modalOpen={showModal}
      setModalOpen={setShowModal}
      maxWidth={modalMaxWidth}
    >
      {renderModalContent()}
      {currentStep === 'planSelection' && !planRedirectLoading && (
        <div className="px-5 py-4 mt-5 border-t text-center">
          <button
            className="text-xs text-blue-500 hover:text-blue-600 font-medium"
            onClick={handleSignOut}
          >
            Sign in with a different account
          </button>
        </div>
      )}
    </ModalBlank>
  );
};

export default SubscriptionModal;
