import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import InfoModal from './InfoModal';

const FormSection = ({ title, subtitle, infoTitle, infoContent, children, icon }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-100 dark:border-gray-700 mb-6 overflow-hidden">
      {title && (
        <div className="border-b border-gray-100 dark:border-gray-700 bg-gray-50/50 dark:bg-gray-800/50 px-5 sm:px-6 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              {icon && (
                <span className="text-indigo-500 dark:text-indigo-400">
                  {icon}
                </span>
              )}
              <div>
                <h2 className="text-gray-900 dark:text-gray-100 font-semibold text-base">
                  {title}
                </h2>
                {subtitle && (
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-0.5">
                    {subtitle}
                  </p>
                )}
              </div>
            </div>
            {(infoTitle || infoContent) && (
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="text-gray-400 hover:text-indigo-500 dark:hover:text-indigo-400 
                  transition-colors p-1.5 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                aria-label="More information"
              >
                <FontAwesomeIcon 
                  icon={faInfoCircle} 
                  className="h-4 w-4" 
                  aria-hidden="true" 
                />
              </button>
            )}
          </div>
        </div>
      )}
      
      <div className="px-5 sm:px-6 py-5">
        <div className="space-y-6">
          {children}
        </div>
      </div>

      {isModalOpen && (
        <InfoModal 
          show={isModalOpen} 
          title={infoTitle} 
          content={infoContent} 
          onClose={() => setIsModalOpen(false)} 
        />
      )}
    </div>
  );
};

export default FormSection;