import React, { memo, useMemo } from 'react';
import LineChart from '../../charts/LineChart02';
import { tailwindConfig } from '../../utils/Utils';

// Memoized version of the LineChart component
const MemoizedLineChart = memo(LineChart);

function DashboardCard08({ chartData }) {
  // Check if there are no data entries in the chartData
  const noDataAvailable = !chartData || chartData.labels.length === 0;

  // Memoize the chart options to avoid recalculating on every render
  const chartOptions = useMemo(() => ({
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day', // Ensure daily data
          tooltipFormat: 'MMM D', // Format tooltips as 'Oct 1'
          displayFormats: {
            day: 'MMM D', // Display x-axis labels as 'Oct 1'
          },
        },
        ticks: {
          source: 'data', // Align ticks with your data
          autoSkip: false, // Don't skip dates
          maxRotation: 0, // Keep ticks horizontal
          color: tailwindConfig().theme.colors.gray[500], // Set tick color
        },
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
      y: {
        beginAtZero: true, // Ensure y-axis starts at 0
        min: 0, // Explicitly set y-axis minimum to 0
        ticks: {
          maxTicksLimit: 5, // Limit the number of ticks
          color: tailwindConfig().theme.colors.gray[500], // Set tick color
        },
        grid: {
          color: tailwindConfig().theme.colors.gray[200], // Set gridline color
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        callbacks: {
          label: (context) => `Plays: ${context.parsed.y}`, // Tooltip for numeric values
        },
      },
    },
    interaction: {
      intersect: false, // Tooltips appear near points
      mode: 'nearest', // Tooltips for the nearest point
    },
    elements: {
      line: {
        tension: 0, // Ensure sharp, straight lines
        borderJoinStyle: 'miter', // Ensure sharp corner jumps
      },
      point: {
        radius: 0, // Hide points unless needed
      },
    },
    maintainAspectRatio: false, // Disable maintaining the aspect ratio
    resizeDelay: 200, // Delay resizing
  }), []);

  // Memoize filled chart data to avoid unnecessary recalculations
  const filledChartData = useMemo(() => ({
    ...chartData,
    datasets: chartData.datasets.map(dataset => ({
      ...dataset,
      tension: 0, // Set tension to 0 to ensure sharp, straight lines
    })),

  }), [chartData]);

  console.log(JSON.stringify(filledChartData));

  if (noDataAvailable) {
    return (
      <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
        <header className="px-5 py-4 border-b border-gray-100 dark:border-gray-700/60 flex items-center">
          <h2 className="font-semibold text-gray-800 dark:text-gray-100">Performance</h2>
        </header>
        <div className="p-5 text-center text-gray-500 dark:text-gray-400">
          Set campaign live to monitor performance.
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white dark:bg-gray-800 shadow-sm rounded-xl">
      <header className="px-5 py-4 border-b border-gray-100 dark:border-gray-700/60 flex items-center">
        <h2 className="font-semibold text-gray-800 dark:text-gray-100">Play Count Timeline</h2>
      </header>
      {/* Render Memoized Line Chart */}
      <MemoizedLineChart data={filledChartData} options={chartOptions} width={595} height={248} />
    </div>
  );
}

export default memo(DashboardCard08);