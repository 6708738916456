import React from 'react';
import { gameTypes } from '../data/GameTypes';
import { motion } from 'framer-motion';

export default function SelectGameType({ onNext, formData }) {
  const renderIcon = (IconComponent, color) => {
    if (!IconComponent) return null;
    
    return <IconComponent className={`w-6 h-6 ${color}`} />;
  };

  const getColorClasses = (type) => {
    const colorMap = {
      "pindrop": {
        icon: "text-red-500 dark:text-red-400",
        border: "border-red-500 ring-red-500",
        hover: "hover:border-red-400 dark:hover:border-red-500",
        bg: "bg-red-100 dark:bg-red-900/60",
        hoverBg: "group-hover:bg-red-50 dark:group-hover:bg-red-900/30",
        text: "text-red-500 dark:text-red-400",
        badge: "bg-red-100 dark:bg-red-900/40 text-red-700 dark:text-red-300",
        check: "bg-red-500"
      },
      "hint": {
        icon: "text-amber-500 dark:text-amber-400",
        border: "border-amber-500 ring-amber-500",
        hover: "hover:border-amber-400 dark:hover:border-amber-500",
        bg: "bg-amber-100 dark:bg-amber-900/60",
        hoverBg: "group-hover:bg-amber-50 dark:group-hover:bg-amber-900/30",
        text: "text-amber-500 dark:text-amber-400",
        badge: "bg-amber-100 dark:bg-amber-900/40 text-amber-700 dark:text-amber-300",
        check: "bg-amber-500"
      },
      "quiz": {
        icon: "text-blue-500 dark:text-blue-400",
        border: "border-blue-500 ring-blue-500",
        hover: "hover:border-blue-400 dark:hover:border-blue-500",
        bg: "bg-blue-100 dark:bg-blue-900/60",
        hoverBg: "group-hover:bg-blue-50 dark:group-hover:bg-blue-900/30",
        text: "text-blue-500 dark:text-blue-400",
        badge: "bg-blue-100 dark:bg-blue-900/40 text-blue-700 dark:text-blue-300",
        check: "bg-blue-500"
      },
      "mapselect": {
        icon: "text-emerald-500 dark:text-emerald-400",
        border: "border-emerald-500 ring-emerald-500",
        hover: "hover:border-emerald-400 dark:hover:border-emerald-500",
        bg: "bg-emerald-100 dark:bg-emerald-900/60",
        hoverBg: "group-hover:bg-emerald-50 dark:group-hover:bg-emerald-900/30",
        text: "text-emerald-500 dark:text-emerald-400",
        badge: "bg-emerald-100 dark:bg-emerald-900/40 text-emerald-700 dark:text-emerald-300",
        check: "bg-emerald-500"
      },
      "snake": {
        icon: "text-purple-500 dark:text-purple-400",
        border: "border-purple-500 ring-purple-500",
        hover: "hover:border-purple-400 dark:hover:border-purple-500",
        bg: "bg-purple-100 dark:bg-purple-900/60",
        hoverBg: "group-hover:bg-purple-50 dark:group-hover:bg-purple-900/30",
        text: "text-purple-500 dark:text-purple-400",
        badge: "bg-purple-100 dark:bg-purple-900/40 text-purple-700 dark:text-purple-300",
        check: "bg-purple-500"
      }
    };

    return colorMap[type.id] || colorMap["quiz"]; // Default to quiz colors if type not found
  };

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
          Choose your game type
        </h2>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Select the type of game that best fits your campaign objectives
        </p>
      </div>

      <motion.div 
        variants={container}
        initial="hidden"
        animate="show"
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
      >
        {gameTypes.map((type) => {
          const colors = getColorClasses(type);
          return (
            <motion.div
              key={type.id}
              variants={item}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <button
                onClick={() => onNext({ gameType: type })}
                className={`w-full h-full relative group p-6 bg-white dark:bg-gray-800 rounded-xl 
                  shadow-sm border-2 transition-all duration-200 ease-in-out
                  ${formData?.gameType?.id === type.id 
                    ? `${colors.border} ring-2 ring-opacity-50` 
                    : `border-gray-200 dark:border-gray-700 ${colors.hover}`
                  }
                `}
              >
                <div className="flex flex-col items-center text-center space-y-4">
                  <div className={`p-4 rounded-xl transition-all duration-200
                    ${formData?.gameType?.id === type.id 
                      ? colors.bg
                      : `bg-gray-50 dark:bg-gray-700/50 ${colors.hoverBg}`
                    }`}
                  >
                    {renderIcon(type.icon, colors.icon)}
                  </div>

                  <h3 className={`text-lg font-medium transition-colors
                    ${formData?.gameType?.id === type.id 
                      ? colors.text
                      : 'text-gray-900 dark:text-white'
                    }`}
                  >
                    {type.name}
                  </h3>

                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {type.description}
                  </p>

                  <div className="flex flex-wrap gap-2 justify-center">
                    {type.features?.map((feature, index) => (
                      <span 
                        key={index} 
                        className={`text-xs px-2.5 py-1 rounded-full transition-colors
                          ${formData?.gameType?.id === type.id 
                            ? colors.badge
                            : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300'
                          }`}
                      >
                        {feature}
                      </span>
                    ))}
                  </div>
                </div>

                {formData?.gameType?.id === type.id && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    className="absolute top-3 right-3"
                  >
                    <div className={`w-6 h-6 ${colors.check} rounded-full flex items-center justify-center`}>
                      <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                  </motion.div>
                )}
              </button>
            </motion.div>
          );
        })}
      </motion.div>
    </div>
  );
}
