import React, { useState, useEffect } from 'react';
import { useUser } from '../userContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import HeadlineProfile from './HeadlineSetupProfile';
import { BeatLoader } from 'react-spinners';
import { HexColorPicker } from 'react-colorful';
import ColorPicker from '../components/ColorPicker';
import CustomFontPicker from '../dashboard/fontPicker';
import StartCard from '../games/StartCard';

const defaultTheme = {
  colors: {
    primary: '#7C3AED',
    secondary: '#4B5563',
  },
  typography: {
    primaryFont: 'Inter',
    secondaryFont: 'Inter'
  }
};

export default function Company() {
  const { user, businessProfile, isOwner, userProfile } = useUser();
  const [options, setOptions] = useState({
    headline: '',
    subheadline: '',
    headline_url: '',
    headlinePhoto: '',
    displayHeadline: true,
    name: '',
    description: '',
    theme: defaultTheme,
    imageShape: 'circle',
    headlineColor: '#374151',
    subheadlineColor: '#6B7280',
    iconColor: '#6B7280',
    imageBorderColor: 'transparent',
    imageBorderWidth: 0,
    headlineFont: '',
    subheadlineFont: '',
    showIcon: false
  });
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showStartPicker, setShowStartPicker] = useState(false);
  const [showCardPicker, setShowCardPicker] = useState(false);
  const [activeColorPicker, setActiveColorPicker] = useState(null);

  useEffect(() => {
    if (businessProfile) {
      console.log('Setting options from businessProfile:', businessProfile); // Debug log
      setOptions({
        ...options,
        headline: businessProfile.headline || '',
        subheadline: businessProfile.subheadline || '',
        headline_url: businessProfile.headline_url || '', // Make sure this is handled consistently
        headlinePhoto: businessProfile.headlinePhoto || '',
        displayHeadline: true,
        name: businessProfile.name || '',
        description: businessProfile.description || '',
        theme: {
          colors: {
            primary: businessProfile.theme?.colors?.primary || defaultTheme.colors.primary,
            secondary: businessProfile.theme?.colors?.secondary || defaultTheme.colors.secondary,
          },
          typography: {
            primaryFont: businessProfile.theme?.typography?.primaryFont || defaultTheme.typography.primaryFont,
            secondaryFont: businessProfile.theme?.typography?.secondaryFont || defaultTheme.typography.secondaryFont
          }
        },
        imageShape: businessProfile.imageShape || 'circle',
        headlineColor: businessProfile.headlineColor || '#374151',
        subheadlineColor: businessProfile.subheadlineColor || '#6B7280',
        iconColor: businessProfile.iconColor || '#6B7280',
        imageBorderColor: businessProfile.imageBorderColor || 'transparent',
        imageBorderWidth: businessProfile.imageBorderWidth || 0,
        headlineFont: businessProfile.headlineFont || 'inherit',
        subheadlineFont: businessProfile.subheadlineFont || 'inherit',
        showIcon: businessProfile.headline_url ? (businessProfile.showIcon !== false) : false
      });
    }
  }, [businessProfile]);

  if (loading || !businessProfile) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOptions({
      ...options,
      [name]: value
    });
    setUnsavedChanges(true);
    setSaveSuccess(false); // Reset success message on change
  };

  const onOptionsUpdate = (updatedOptions) => {
    console.log('Updating options:', updatedOptions); // Debug log
    setOptions(prev => {
      const newOptions = {
        ...prev,
        ...updatedOptions,
        headline_url: updatedOptions.headline_url || '', // Ensure consistent empty state
      };
      return newOptions;
    });
    setUnsavedChanges(true);
    setSaveSuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (businessProfile && user) {
      console.log('Submitting options:', options); // Debug log
      setLoading(true);
      const businessDocRef = doc(db, 'company', businessProfile.id);
      
      const updateData = {
        headline: options.headline,
        subheadline: options.subheadline,
        headline_url: options.headline_url, // This should match what we're saving
        headlinePhoto: options.headlinePhoto,
        name: options.name,
        description: options.description,
        theme: options.theme,
        imageShape: options.imageShape,
        headlineColor: options.headlineColor,
        subheadlineColor: options.subheadlineColor,
        iconColor: options.iconColor,
        imageBorderColor: options.imageBorderColor,
        imageBorderWidth: options.imageBorderWidth,
        headlineFont: options.headlineFont,
        subheadlineFont: options.subheadlineFont,
        showIcon: options.showIcon
      };
      
      console.log('Updating document with:', updateData); // Debug log
      
      try {
        await updateDoc(businessDocRef, updateData);
        setUnsavedChanges(false);
        setSaveSuccess(true);
      } catch (error) {
        console.error("Error updating document: ", error);
      } finally {
        setLoading(false);
        setTimeout(() => setSaveSuccess(false), 3000);
      }
    }
  };

  const handleThemeUpdate = (updates) => {
    console.log('Raw update:', updates);

    if (updates.colors) {
      const validColors = {};
      Object.entries(updates.colors).forEach(([key, value]) => {
        // If value is null/undefined, use default
        if (!value) {
          validColors[key] = defaultTheme.colors[key];
        }
        // If value is already a string with #, use it
        else if (typeof value === 'string' && value.startsWith('#')) {
          validColors[key] = value;
        }
        // If value is a string without #, add it
        else if (typeof value === 'string') {
          validColors[key] = `#${value}`;
        }
        // Otherwise use the default
        else {
          validColors[key] = defaultTheme.colors[key];
        }
      });

      updates = {
        ...updates,
        colors: validColors
      };
    }

    setOptions(prev => ({
      ...prev,
      theme: {
        ...prev.theme,
        colors: updates.colors ? {
          ...prev.theme?.colors,
          ...updates.colors
        } : prev.theme?.colors,
        typography: updates.typography ? {
          ...prev.theme?.typography,
          ...updates.typography
        } : prev.theme?.typography
      }
    }));
    
    setUnsavedChanges(true);
  };

  // Check if user is agency managing client
  const canManageCompany = () => {
    // Allow if user is owner
    if (isOwner) return true;

    // Allow if user is from agency and viewing client
    if (businessProfile?.parentCompany === userProfile?.companyId) {
      return true;
    }

    return false;
  };

  if (!canManageCompany()) {
    return (
      <div className="p-6 space-y-6 w-full">
        <div className="bg-yellow-100 w-full border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
          <p className="font-bold">Access Restricted</p>
          <p>Only the account administrator or agency manager can manage company information.</p>
        </div>
      </div>
    );
  }

  console.log("Company options:", options);

  // Add this helper function to adjust color brightness
  const adjustColor = (hex, percent) => {
    // Remove the # if present
    hex = hex.replace('#', '');
    
    // Convert to RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Adjust each channel
    r = Math.round(r * (1 + percent));
    g = Math.round(g * (1 + percent));
    b = Math.round(b * (1 + percent));

    // Ensure values stay within valid range
    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    // Convert back to hex
    const rHex = r.toString(16).padStart(2, '0');
    const gHex = g.toString(16).padStart(2, '0');
    const bHex = b.toString(16).padStart(2, '0');

    return `#${rHex}${gHex}${bHex}`;
  };

  const getPreviewTheme = () => {
    const primaryColor = options.theme.colors.primary || defaultTheme.colors.primary;
    const secondaryColor = options.theme.colors.secondary || defaultTheme.colors.secondary;
    const gradientEndColor = adjustColor(primaryColor, -0.2);

    return {
      typography: {
        titleFont: options.theme.typography.primaryFont,
        subtitleFont: options.theme.typography.secondaryFont,
        buttonFont: options.theme.typography.primaryFont,
      },
      colors: {
        // Card colors
        cardBackground: '#FFFFFF',
        titleText: secondaryColor,
        subtitleText: secondaryColor,
        buttonBackground: primaryColor,
        buttonText: '#FFFFFF',
        
        // Keep headline colors separate
        headline: {
          headlineColor: options.headlineColor,
          subheadlineColor: options.subheadlineColor,
          iconColor: options.iconColor
        },
        
        // Background gradient
        background: {
          gradient: {
            start: primaryColor,
            end: gradientEndColor
          }
        }
      }
    };
  };

  return (
    <div className="w-full px-8 py-6">
      <form onSubmit={handleSubmit}>
        <div className="space-y-8">
          <div>
            <div className="flex items-center mb-1">
              <h2 className="text-base font-semibold leading-7 text-indigo-700">Company Information</h2>
              {unsavedChanges && !saveSuccess && (
                <span className="ml-2 inline-flex items-center rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">
                  Unsaved changes
                </span>
              )}
              {saveSuccess && (
                <span className="ml-2 inline-flex items-center rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                  Changes saved
                </span>
              )}
            </div>
            <p className="max-w-2xl text-sm leading-6 text-gray-600 mb-4">
              Manage your company information.
            </p>

            <div className="mt-2">
              <h3 className="text-sm font-semibold leading-6 text-gray-800">Business Name</h3>
              <input
                type="text"
                name="name"
                value={options.name}
                onChange={handleChange}
                placeholder="Enter your business name"
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div className="mt-4">
              <h3 className="text-sm font-semibold leading-6 text-gray-800">About Your Business</h3>
              <p className="text-sm text-gray-600 mb-2">
                Tell us about your business and what makes it unique
              </p>
              <textarea
                name="description"
                value={options.description}
                onChange={handleChange}
                placeholder="e.g., We're a family-owned bakery specializing in artisanal breads and pastries..."
                className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                rows={4}
              />
            </div>

            <div className="mt-6">
              <div className="flex items-center">
                <h2 className="text-base font-semibold leading-7 text-indigo-700">Header</h2>
              </div>
              <p className="max-w-2xl text-sm leading-6 text-gray-600 mb-2">
                Your header is optionally displayed on your campaigns.
              </p>
              <HeadlineProfile
                options={options}
                params={businessProfile.id}
                handleChange={handleChange}
                onOptionsUpdate={onOptionsUpdate}
              />
            </div>

            <div className="mt-6 space-y-8">
              <div className="space-y-4">
                <h3 className="text-sm font-medium text-gray-700">Brand Colors</h3>
                <div className="space-y-6">
                  <ColorPicker
                    label="Primary Color"
                    color={options.theme?.colors?.primary || defaultTheme.colors.primary}
                    onChange={(color) => handleThemeUpdate({
                      colors: {
                        primary: color
                      }
                    })}
                    isActive={activeColorPicker === 'primary'}
                    onToggle={() => setActiveColorPicker(activeColorPicker === 'primary' ? null : 'primary')}
                  />

                  <ColorPicker
                    label="Secondary Color"
                    color={options.theme?.colors?.secondary || defaultTheme.colors.secondary}
                    onChange={(color) => handleThemeUpdate({
                      colors: {
                        secondary: color
                      }
                    })}
                    isActive={activeColorPicker === 'secondary'}
                    onToggle={() => setActiveColorPicker(activeColorPicker === 'secondary' ? null : 'secondary')}
                  />
                </div>
              </div>
              
              <div className="space-y-4">
                <h3 className="text-sm font-medium text-gray-700">Typography</h3>
                <div className="space-y-4">
                  <CustomFontPicker 
                    label="Primary Font"
                    value={options.theme?.typography?.primaryFont || defaultTheme.typography.primaryFont}
                    onChange={(font) => handleThemeUpdate({
                      typography: {
                        ...options.theme?.typography,
                        primaryFont: font
                      }
                    })}
                  />
                  <CustomFontPicker 
                    label="Secondary Font"
                    value={options.theme?.typography?.secondaryFont || defaultTheme.typography.secondaryFont}
                    onChange={(font) => handleThemeUpdate({
                      typography: {
                        ...options.theme?.typography,
                        secondaryFont: font
                      }
                    })}
                  />
                </div>
              </div>
              
              <div className="space-y-4">
                <h3 className="text-sm font-medium text-gray-700">Preview</h3>
                <div className="w-full h-[500px] bg-gray-50 rounded-lg">
                  <StartCard
                    preview={true}
                    overrideOptions={{
                      title: "An Example Quiz",
                      intro: "This is an example introduction",
                      theme: getPreviewTheme(),
                      gameType: { id: 'quiz' },
                      hideBranding: true,
                      headline: options.headline,
                      subheadline: options.subheadline,
                      headline_url: options.headline_url,
                      headlinePhoto: options.headlinePhoto,
                      showIcon: options.showIcon
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-start mt-6">
              <button
                type="submit"
                disabled={!unsavedChanges || loading}
                className={`inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full sm:w-auto ${
                  unsavedChanges && !loading ? 'bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600' : 'bg-gray-400 cursor-not-allowed'
                }`}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}