import React from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../userContext';

function GetStartedWizard({ onCreateCampaign }) {
  const { userProfile, businessProfile, campaigns } = useUser();

  const hasAIProfile = businessProfile?.name && 
                      businessProfile?.description;

            

  
  const hasCampaigns = campaigns && campaigns.length > 0;
  const hasLiveCampaign = campaigns?.some(campaign => campaign.status === 'live');

  // Determine if create campaign is the active step
  const isCreateCampaignActive = hasAIProfile && !hasCampaigns;

  // Helper function to determine step status
  const getStepStyles = (isComplete, isActive) => {
    if (isActive) return {
      container: 'bg-indigo-500',
      icon: 'text-white',
      text: 'text-gray-900',
      action: 'text-gray-600 hover:text-gray-900'
    };
    if (isComplete) return {
      container: 'bg-indigo-500',
      icon: 'text-white',
      text: 'text-gray-900',
      action: 'text-gray-600 hover:text-gray-900'
    };
    return {
      container: 'bg-gray-200',
      icon: 'text-gray-400',
      text: 'text-gray-400',
      action: 'text-gray-400'
    };
  };

  // Determine which step is active
  const step1Styles = getStepStyles(hasAIProfile, !hasAIProfile);
  const step2Styles = getStepStyles(hasCampaigns, hasAIProfile && !hasCampaigns);
  const step3Styles = getStepStyles(hasLiveCampaign, hasCampaigns && !hasLiveCampaign);

  const handleCreateCampaign = (e) => {
    if (!isCreateCampaignActive) {
      e.preventDefault();
      return;
    }
    onCreateCampaign();
  };

  if (hasLiveCampaign) return null;

  return (
    <div className="mb-8 bg-white rounded-xl shadow-sm">
      {/* Welcome Banner */}
      <div className="px-6 py-8 border-b border-gray-100 bg-gradient-to-br from-indigo-50 to-white">
        <h2 className="text-2xl font-semibold text-gray-900 mb-2">
          Welcome, {userProfile.firstName}! 👋
        </h2>
        <p className="text-gray-600">
          Let's get you set up with your first interactive campaign. Follow these simple steps to get started.
        </p>
      </div>

      {/* Steps */}
      <div className="px-6 py-5">
        <div className="flex flex-col md:flex-row items-stretch gap-6 md:gap-0 justify-between">
          {/* Step 1: Setup Profile */}
          <div className="flex items-center gap-3 w-full md:w-1/3">
            <div className={`w-10 h-10 rounded-lg flex items-center justify-center shrink-0 ${step1Styles.container}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-5 h-5 ${step1Styles.icon}`}>
                <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="flex flex-col flex-grow">
              <h3 className={`text-sm font-semibold ${step1Styles.text}`}>
                Setup AI Profile For Suggestions
              </h3>
              <Link 
                to="/settings/company"
                className={`text-sm ${step1Styles.action}`}
              >
                {hasAIProfile ? 'Profile complete ✓' : 'Complete profile →'}
              </Link>
            </div>
          </div>

          {/* Step 2: Create Campaign */}
          <div className="flex items-center gap-3 w-full md:w-1/3">
            <div className={`w-10 h-10 rounded-lg flex items-center justify-center shrink-0 ${step2Styles.container}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-5 h-5 ${step2Styles.icon}`}>
                <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="flex flex-col flex-grow">
              <h3 className={`text-sm font-semibold ${step2Styles.text}`}>
                Create Your First Campaign
              </h3>
              <Link
                to="/new"
                onClick={(e) => {
                  if (!isCreateCampaignActive) {
                    e.preventDefault();
                    return;
                  }
                }}
                className={`text-sm text-left ${step2Styles.action} ${!isCreateCampaignActive && !hasCampaigns ? 'cursor-not-allowed' : ''}`}
              >
                {hasCampaigns ? 'Campaign created ✓' : 'Create campaign →'}
              </Link>
            </div>
          </div>

          {/* Step 3: Embed */}
          <div className="flex items-center gap-3 w-full md:w-1/3">
            <div className={`w-10 h-10 rounded-lg flex items-center justify-center shrink-0 ${step3Styles.container}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-5 h-5 ${step3Styles.icon}`}>
                <path fillRule="evenodd" d="M14.447 3.026a.75.75 0 0 1 .527.921l-4.5 16.5a.75.75 0 0 1-1.448-.394l4.5-16.5a.75.75 0 0 1 .921-.527ZM16.72 6.22a.75.75 0 0 1 1.06 0l5.25 5.25a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 1 1-1.06-1.06L21.44 12l-4.72-4.72a.75.75 0 0 1 0-1.06Zm-9.44 0a.75.75 0 0 1 0 1.06L2.56 12l4.72 4.72a.75.75 0 0 1-1.06 1.06L.97 12.53a.75.75 0 0 1 0-1.06l5.25-5.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="flex flex-col flex-grow">
              <h3 className={`text-sm font-semibold ${step3Styles.text}`}>
                Embed & Share
              </h3>
              {hasLiveCampaign ? (
                <span className={`text-sm ${step3Styles.action}`}>
                  Campaign embedded ✓
                </span>
              ) : (
                <Link 
                  to="/campaigns"
                  className={`text-sm ${step3Styles.action} ${!hasCampaigns ? 'cursor-not-allowed' : ''}`}
                  onClick={(e) => {
                    if (!hasCampaigns) {
                      e.preventDefault();
                      return;
                    }
                  }}
                >
                  Set your campaign live →
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStartedWizard; 