import React, { useRef, useEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../utils/Transition';
import { useUser } from '../userContext';

const SearchModal = memo(({ id, searchId, modalOpen, setModalOpen }) => {
  const { campaigns } = useUser();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const modalContent = useRef(null);
  const searchInput = useRef(null);

  // Updated search functionality
  useEffect(() => {
    if (!searchTerm.trim()) {
      setSearchResults([]);
      return;
    }


    const term = searchTerm.toLowerCase().trim();
    const filtered = campaigns?.filter(campaign => {
      const title = (campaign.name || '').toLowerCase();
      const description = (campaign.description || '').toLowerCase();
      const gameType = (campaign.gameType || '').toLowerCase();
      
      return title.includes(term) || 
             description.includes(term) || 
             gameType.includes(term);
    });

    setSearchResults(filtered || []);
  }, [searchTerm, campaigns]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!modalOpen || modalContent.current.contains(target)) return
      setModalOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!modalOpen || keyCode !== 27) return;
      setModalOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    modalOpen && searchInput.current.focus();
  }, [modalOpen]);

  const getImage = (campaign) => {
    // Try to get images in order of preference
    const image = campaign.options?.sharingPhoto || 
                  campaign.options?.coverPhoto || 
                  '/twitter.png'; // Fallback to logo

    return (
      <img 
        src={image}
        alt={campaign.name}
        className="w-8 h-8 rounded object-cover mr-3"
      />
    );
  };

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-start top-20 mb-4 justify-center px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white dark:bg-gray-800 border border-transparent dark:border-gray-700/60 overflow-auto max-w-2xl w-full max-h-full rounded-lg shadow-lg"
        >
          {/* Search form */}
          <form className="border-b border-gray-200 dark:border-gray-700/60" onSubmit={(e) => e.preventDefault()}>
            <div className="relative">
              <label htmlFor={searchId} className="sr-only">Search</label>
              <input
                id={searchId}
                className="w-full dark:text-gray-300 bg-white dark:bg-gray-800 border-0 focus:ring-transparent placeholder-gray-400 dark:placeholder-gray-500 appearance-none py-3 pl-10 pr-4"
                type="search"
                placeholder="Search campaign name..."
                ref={searchInput}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="absolute inset-0 right-auto group" type="submit" aria-label="Search">
                <svg className="shrink-0 fill-current text-gray-400 dark:text-gray-500 group-hover:text-gray-500 dark:group-hover:text-gray-400 ml-4 mr-2" width="16" height="16" viewBox="0 0 16 16">
                  <path d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z" />
                  <path d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z" />
                </svg>
              </button>
            </div>
          </form>

          <div className="py-4 px-2">
            {/* Search Results */}
            {searchTerm && (
              <div className="mb-3 last:mb-0">
                <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase px-2 mb-2">
                  Search Results
                </div>
                <ul className="text-sm">
                  {searchResults.length > 0 ? (
                    searchResults.map(campaign => (
                      <li key={campaign.id}>
                        <Link
                          className="flex items-center p-2 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700/20 rounded-lg"
                          to={`/campaign/${campaign.id}`}
                          onClick={() => setModalOpen(false)}
                        >
                          {getImage(campaign)}
                          <div className="flex flex-col">
                            <span className="font-medium">{campaign.name}</span>
                            {campaign.options?.title && (
                              <span className="text-sm text-gray-500">{campaign.options.title}</span>
                            )}
                          </div>
                        </Link>
                      </li>
                    ))
                  ) : (
                    <li className="px-2 text-gray-500">No campaigns found</li>
                  )}
                </ul>
              </div>
            )}

            {/* Recent Campaigns */}
            {!searchTerm && campaigns && campaigns.length > 0 && (
              <div className="mb-3 last:mb-0">
                <div className="text-xs font-semibold text-gray-400 dark:text-gray-500 uppercase px-2 mb-2">
                  Recent Campaigns
                </div>
                <ul className="text-sm">
                  {campaigns
                    .sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds)
                    .slice(0, 3)
                    .map(campaign => (
                      <li key={campaign.id}>
                        <Link
                          className="flex items-center p-2 text-gray-800 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700/20 rounded-lg"
                          to={`/campaign/${campaign.id}`}
                          onClick={() => setModalOpen(false)}
                        >
                          {getImage(campaign)}
                          <div className="flex flex-col">
                            <span className="font-medium">{campaign.name}</span>
                            {campaign.options?.title && (
                              <span className="text-sm text-gray-500">{campaign.options.title}</span>
                            )}
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Transition>
    </>
  );
});

export default SearchModal;
