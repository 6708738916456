import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword, sendEmailVerification, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import Logo from "../images/logo.png";
import AlertError from "./PermissionDenied";
import Loading from "../common/ComponentLoading";
import { SquareLoader } from "react-spinners";
import { useUser } from "../userContext"; // Import the useUser hook

const Signup = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [notice, setNotice] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [loadingText, setLoadingText] = useState("Creating account...");
    const [loadingTick, setLoadingTick] = useState(false);
    const { loading: userLoading, userProfile, businessProfile } = useUser(); // Access the loading state from userContext

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && !isProcessing && !userLoading && userProfile && businessProfile) {
                // If the user is logged in and user data is fully loaded, navigate
                navigate("/");
            }
        });

        return () => unsubscribe();
    }, [navigate, isProcessing, userLoading, userProfile, businessProfile]);

    useEffect(() => {
        setNotice("");
    }, [email, password, confirmPassword, firstName, lastName, companyName]);

    const isFormValid = email && password && confirmPassword && firstName && lastName;

    const getErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'auth/email-already-in-use':
                return 'The email address is already in use by another account.';
            case 'auth/invalid-email':
                return 'The email address is not valid.';
            case 'auth/operation-not-allowed':
                return 'Email/password accounts are not enabled.';
            case 'auth/weak-password':
                return 'The password is too weak.';
            case 'auth/user-disabled':
                return 'The user account has been disabled by an administrator.';
            case 'auth/user-not-found':
                return 'No user found with this email.';
            case 'auth/wrong-password':
                return 'The password is invalid.';
            default:
                return 'Sorry, something went wrong. Please try again.';
        }
    };

    const signupWithUsernameAndPassword = async (e) => {
        e.preventDefault();

        if (password === confirmPassword) {
            setIsProcessing(true);
            setIsLoading(true); // Show the loading screen immediately

            try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;
                const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

                const businessRef = await addDoc(collection(db, "company"), {
                    ownerId: user.uid,
                    name: companyName || "",
                    createdAt: new Date(),
                });

                await setDoc(doc(db, "users", user.uid), {
                    uid: user.uid,
                    email: user.email,
                    firstName,
                    lastName,
                    companyId: businessRef.id,
                    createdAt: new Date(),
                });

                await sendEmailVerification(user);

                setLoadingText("Account created!"); // Update the loading text
                setLoadingTick(true); // Show the success tick

                // Wait for 2 seconds, then allow time for userContext to load
                setTimeout(() => {
                    setIsProcessing(false);
                }, 2000);

            } catch (error) {
                const errorMessage = getErrorMessage(error.code);
                setNotice(errorMessage);
                setIsProcessing(false);
                setIsLoading(false); // Hide the loading screen in case of an error
                console.error("Error signing up: ", error);
                return;
            }
        } else {
            setNotice("Passwords don't match. Please try again.");
            setIsProcessing(false);
        }
    };

    if (isLoading) {
        return <Loading text={loadingText} loadingTick={loadingTick} spinnerOverride={SquareLoader} />;
    }

    return (
        <main className="dark:bg-gray-900">
            <div className="relative flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/2">
                    <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
                        <div className="flex-1">
                            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                                <a href="https://www.smilesflow.com" target="_blank" rel="noopener noreferrer">
                                    <img alt="SmilesFlow" src={Logo} className="h-8" />
                                </a>
                                <div className="text-sm">
                                    Already got an account?{' '}
                                    <a href="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Sign in
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="px-4 py-8">
                            <div className="max-w-md mx-auto">
                                <h2 className="text-2xl font-bold mb-4">Create Your Account</h2>

                                {notice && <AlertError text={notice} dashboardButton={false} />}

                                <form onSubmit={signupWithUsernameAndPassword} method="POST" className="space-y-6">
                                    <div className="flex space-x-2">
                                        <div className="w-1/2">
                                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
                                            <input
                                                id="firstName"
                                                type="text"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                                className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600"
                                            />
                                        </div>
                                        <div className="w-1/2">
                                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
                                            <input
                                                id="lastName"
                                                type="text"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                                className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company (Optional)</label>
                                        <input
                                            id="companyName"
                                            type="text"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600"
                                            placeholder="Enter your company name"
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email address</label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            required
                                            className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600"
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            required
                                            className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600"
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">Confirm password</label>
                                        <input
                                            id="confirm-password"
                                            name="confirm-password"
                                            type="password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                            className="w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600"
                                        />
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold text-white shadow-sm ${isFormValid ? 'bg-indigo-600 hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600' : 'bg-gray-400 cursor-not-allowed'}`}
                                            disabled={!isFormValid || isProcessing}
                                        >
                                            {isProcessing ? "Please wait..." : "Get Started"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-1/2 bg-indigo-500 text-white p-8">
                    <div className="max-w-md mx-auto">
                        <h3 className="text-xl font-bold mb-4">What our users say</h3>
                        <div className="space-y-4">
                            <blockquote className="p-4 bg-gray-100 rounded-md shadow-md">
                                <p>"This service has changed my life!"</p>
                                <footer className="mt-2 text-sm text-gray-600">- Happy User</footer>
                            </blockquote>
                            <blockquote className="p-4 bg-gray-100 rounded-md shadow-md">
                                <p>"I can't imagine going back to the way things were before."</p>
                                <footer className="mt-2 text-sm text-gray-600">- Satisfied Customer</footer>
                            </blockquote>
                            <blockquote className="p-4 bg-gray-100 rounded-md shadow-md">
                                <p>"The best decision I've ever made!"</p>
                                <footer className="mt-2 text-sm text-gray-600">- Loyal Client</footer>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Signup;