import React, { useState, useEffect, useRef } from 'react';
import { leaderboardService } from '../services/leaderboardService';
import { PencilIcon, TrashIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import ModalBasic from '../components/ModalBasic';
import { usePopper } from 'react-popper';

const ManageLeaderboard = ({ campaignId }) => {
  const [entries, setEntries] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [editName, setEditName] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const inputRef = useRef(null);
  const saveButtonRef = useRef(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [
      { name: 'offset', options: { offset: [0, 0] } },
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
        },
      }
    ],
  });

  useEffect(() => {
    loadEntries();
    loadTotalEntries();
  }, [campaignId]);

  useEffect(() => {
    if (showDeleteModal) {
      const timer = setTimeout(() => {
        setModalOpen(true);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [showDeleteModal]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target) && 
          (!saveButtonRef.current || !saveButtonRef.current.contains(event.target))) {
        setEditingId(null);
        setEditName('');
      }
    }

    if (editingId) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [editingId]);

  const loadEntries = async () => {
    setIsLoading(true);
    try {
      const { entries } = await leaderboardService.getTopScores(campaignId, 10);
      setEntries(entries);
    } catch (error) {
      console.error('Error loading leaderboard entries:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadTotalEntries = async () => {
    try {
      const count = await leaderboardService.getTotalEntries(campaignId);
      setTotalEntries(count);
    } catch (error) {
      console.error('Error loading total leaderboard entries:', error);
    }
  };

  const handleDelete = async () => {
    if (!entryToDelete) return;
    
    try {
      await leaderboardService.deleteEntry(campaignId, entryToDelete);
      setModalOpen(false);
      setShowDeleteModal(false);
      setEntryToDelete(null);
      await loadEntries(); // Reload the list
    } catch (error) {
      console.error('Error deleting entry:', error);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setTimeout(() => {
      setShowDeleteModal(false);
      setEntryToDelete(null);
    }, 300);
  };

  const handleEdit = (entry) => {
    setEditingId(entry.id);
    setEditName(entry.name);
    setEditEmail(entry.email || '');
  };

  const handleSave = async () => {
    if (!editingId) return;
    
    try {
      await leaderboardService.updateScore(campaignId, editingId, { 
        name: editName,
        email: editEmail
      });
      await loadEntries();
      
      setEditingId(null);
      setEditName('');
      setEditEmail('');
    } catch (error) {
      console.error('Error updating entry:', error);
    }
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      // Fetch all entries for download
      const { entries: allEntries } = await leaderboardService.getAllScores(campaignId);
      
      // Convert entries to CSV format
      const headers = ['Rank', 'Name', 'Email', 'Score'];
      const csvData = allEntries.map((entry, index) => [
        index + 1,
        entry.name,
        entry.email || 'No email provided',
        entry.score
      ]);
      
      // Add headers to the beginning
      csvData.unshift(headers);
      
      // Convert to CSV string
      const csvString = csvData
        .map(row => row.map(cell => 
          cell.toString().includes(',') ? `"${cell}"` : cell
        ).join(','))
        .join('\n');
      
      // Create blob and download
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      
      link.setAttribute('href', url);
      link.setAttribute('download', `leaderboard-${new Date().toISOString().split('T')[0]}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Clean up
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading leaderboard:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="bg-white shadow-sm rounded-xl pl-5 pt-5 pb-7">
      <div className="flex justify-between items-center mb-4 pr-5">
        <div>
          <div className="text-xs uppercase font-semibold text-gray-400 mb-2">
            Leaderboard
          </div>
          <div className="text-sm text-gray-500">
            Total Entries: {totalEntries}
          </div>
        </div>
        <button
          onClick={handleDownload}
          disabled={isDownloading}
          className={`
            inline-flex items-center justify-center
            w-9 h-9 rounded-md
            transition-all duration-200
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
            group relative
            ${isDownloading 
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300 active:bg-gray-100'
            }
          `}
        >
          {/* Tooltip - repositioned to left */}
          <div className="absolute right-full mr-2 top-1/2 -translate-y-1/2 hidden group-hover:block">
            <div className="bg-gray-900 text-white text-xs rounded px-2 py-1 whitespace-nowrap">
              Download leaderboard
            </div>
          </div>

          {isDownloading ? (
            <svg 
              className="animate-spin h-4 w-4 text-gray-400" 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 24 24"
            >
              <circle 
                className="opacity-25" 
                cx="12" 
                cy="12" 
                r="10" 
                stroke="currentColor" 
                strokeWidth="4"
              />
              <path 
                className="opacity-75" 
                fill="currentColor" 
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : (
            <ArrowDownTrayIcon className="h-4 w-4" />
          )}
        </button>
      </div>
      
      <div className="divide-y divide-gray-200">
        {entries.map((entry, index) => (
          <div 
            key={entry.id} 
            onClick={() => handleEdit(entry)}
            className="py-4 flex items-center justify-between hover:bg-gray-50 pr-5 cursor-pointer"
          >
            <div className="flex items-center space-x-4">
              <span className="text-sm font-medium text-gray-500 w-6">{index + 1}</span>
              <div 
                ref={editingId === entry.id ? setReferenceElement : null}
                className="relative"
              >
                <span className="text-sm font-medium text-gray-900">{entry.name}</span>
              </div>
              <span className="text-sm text-gray-500">{entry.score}</span>
            </div>
            
            <div className="flex items-center space-x-2">
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row click
                  setEntryToDelete(entry.id);
                  setShowDeleteModal(true);
                }}
                className="text-gray-400 hover:text-red-500"
              >
                <TrashIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
        ))}
        
        {entries.length === 0 && (
          <div className="py-4 text-center text-sm text-gray-500 pr-5">
            No entries yet
          </div>
        )}
      </div>

      {/* Edit Popper */}
      {editingId && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-50 w-72"
        >
          <div className="space-y-4">
            <div>
              <label htmlFor="edit-name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <input
                id="edit-name"
                type="text"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                autoFocus
              />
            </div>
            <div>
              <span className="block text-sm font-medium text-gray-700">
                Email
              </span>
              <span className="mt-1 block text-sm text-gray-500">
                {editEmail || 'No email provided'}
              </span>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  setEditingId(null);
                  setEditName('');
                  setEditEmail('');
                }}
                className="px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-3 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <ModalBasic
          id="delete-confirmation-modal"
          modalOpen={modalOpen}
          setModalOpen={handleCloseModal}
          title="Confirm Deletion"
        >
          <div className="p-6">
            <div className="mb-4">
              <p className="text-sm text-gray-900">
                Are you sure you want to delete this entry?
              </p>
            </div>

            <div className="flex justify-end gap-4">
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                type="button"
                className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalBasic>
      )}
    </div>
  );
};

export default ManageLeaderboard; 