import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import React, { useState, useEffect } from 'react';
import ColorPicker from '../components/ColorPicker';
import CustomFontPicker from './fontPicker';
import FileUpload from '../games/FileUpload';
import { usePopper } from 'react-popper';

const getContrastingBackground = (color) => {
  return '#ffffff';
};

const getContrastingHover = (color) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)';
};

const HeadlineCard = ({ 
  companyId, 
  customization = {}, 
  preview = false, 
  onOptionsUpdate,
  params,
  user
}) => {
  console.log('Initial customization:', customization);

  const [activeSection, setActiveSection] = useState(null);
  const [activeColorPicker, setActiveColorPicker] = useState(null);
  const [localState, setLocalState] = useState(
    preview ? {
      headline: customization?.headline || '',
      subheadline: customization?.subheadline || '',
      headlineFont: customization?.headlineFont || 'Inter',
      subheadlineFont: customization?.subheadlineFont || 'Inter',
      headlineColor: customization?.headlineColor || '#000000',
      subheadlineColor: customization?.subheadlineColor || '#000000',
      headline_url: customization?.headline_url || '',
      showIcon: customization?.showIcon ?? true,
      imageShape: customization?.imageShape || 'circle'
    } : null
  );

  console.log('LocalState:', localState);

  // Update localState when customization changes
  useEffect(() => {
    console.log('Customization changed:', customization);
    setLocalState({
      headline: customization?.headline || '',
      subheadline: customization?.subheadline || '',
      headlineFont: customization?.headlineFont || 'Inter',
      subheadlineFont: customization?.subheadlineFont || 'Inter',
      headlineColor: customization?.headlineColor || '#000000',
      subheadlineColor: customization?.subheadlineColor || '#000000',
      headline_url: customization?.headline_url || '',
      showIcon: customization?.showIcon ?? true,
      imageShape: customization?.imageShape || 'circle'
    });
  }, [customization]);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      { name: 'offset', options: { offset: [0, 8] } },
      { name: 'preventOverflow', options: { padding: 8 } },
    ],
  });

  const handleSectionClick = (section, event) => {
    setReferenceElement(event.currentTarget);
    if (activeSection === section) {
      setActiveSection(null);
    } else {
      setActiveSection(section);
      setLocalState(customization);
    }
  };

  const handleApplyChanges = () => {
    console.log('Before update - localState.url:', localState.url);
    
    const updatedOptions = {
      ...customization,
      headline: localState.headline,
      subheadline: localState.subheadline,
      headlineFont: localState.headlineFont,
      subheadlineFont: localState.subheadlineFont,
      headlineColor: localState.headlineColor,
      subheadlineColor: localState.subheadlineColor,
      headline_url: localState.headline_url,
      imageShape: localState.imageShape,
      showIcon: localState.showIcon
    };
    
    console.log('Sending update:', updatedOptions);
    onOptionsUpdate(updatedOptions);
    setActiveSection(null);
  };

  // Initialize with empty strings to prevent null/undefined issues
  const defaultPhotoUrl = 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541';
  const formattedUrl = localState.url && !localState.url.startsWith('http') 
    ? `http://${localState.url}` 
    : localState.url;

  // Image shape styles with default
  const getImageShape = () => {
    const shape = preview ? localState?.imageShape : customization?.imageShape;
    switch (shape) {
      case 'square': return 'rounded-none';
      case 'rounded': return 'rounded-md';
      case 'circle':
      default: return 'rounded-full';
    }
  };

  // Container logic - simplified
  const Container = (preview || !formattedUrl) ? 'div' : 'a';
  const containerProps = (!preview && formattedUrl)
    ? {
        href: formattedUrl,
        target: "_blank",
        rel: "noopener noreferrer",
        className: "group block flex-shrink-0 pb-5",
      }
    : {
        className: "block flex-shrink-0 pb-5",
      };

  // Create preview styles that use localState instead of customization
  const previewStyles = {
    headline: {
      color: localState?.headlineColor,
      fontFamily: localState?.headlineFont
    },
    subheadline: {
      color: localState?.subheadlineColor,
      fontFamily: localState?.subheadlineFont
    },
    icon: {
      color: localState?.subheadlineColor
    },
    image: {
      borderColor: localState?.imageBorderColor || 'transparent',
      borderWidth: `${localState?.imageBorderWidth || 0}px`
    }
  };

  // Combined getStyles function that handles both preview and normal states
  const getStyles = (section) => {
    // If we're in preview mode and editing this section, use preview styles
    if (activeSection === section) {
      return previewStyles[section];
    }

    // Regular styles with campaign overrides
    const regularStyles = {
      headline: {
        color: customization?.theme?.colors?.headline?.headlineColor || customization?.headlineColor || '#000000',
        fontFamily: customization?.headlineFont || 'Inter'
      },
      subheadline: {
        color: customization?.theme?.colors?.headline?.subheadlineColor || customization?.subheadlineColor || '#000000',
        fontFamily: customization?.subheadlineFont || 'Inter'
      },
      icon: {
        color: customization?.theme?.colors?.headline?.iconColor || customization?.iconColor || '#6B7280'
      },
      image: {
        borderColor: customization?.imageBorderColor || 'transparent',
        borderWidth: `${customization?.imageBorderWidth || 0}px`
      }
    };

    return section ? regularStyles[section] : regularStyles;
  };

  const renderPopperContent = () => {
    if (!activeSection) return null;

    let content;
    switch (activeSection) {
      case 'image':
        content = (
          <div className="p-4 bg-white rounded-lg shadow-lg border space-y-4 min-w-[320px]">
            <h3 className="text-lg font-medium text-gray-900">Edit Image</h3>
            <FileUpload
              onSuccess={onOptionsUpdate}
              onDelete={onOptionsUpdate}
              field="headlinePhoto"
              params={params}
              id={user.uid}
              collection={"users"}
              options={customization}
              displayText={"Change Image"}
              displayPreview={false}
            />
            
            {/* Add Image Shape Selector */}
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Image Shape
              </label>
              <div className="flex gap-3">
                <button
                  type="button"
                  onClick={() => setLocalState(prev => ({ ...prev, imageShape: 'circle' }))}
                  className={`p-2 rounded-md ${
                    localState.imageShape === 'circle' 
                      ? 'bg-indigo-50 ring-2 ring-indigo-600' 
                      : 'bg-white ring-1 ring-gray-200'
                  }`}
                >
                  <div className="w-8 h-8 rounded-full bg-gray-200" />
                </button>
                <button
                  type="button"
                  onClick={() => setLocalState(prev => ({ ...prev, imageShape: 'square' }))}
                  className={`p-2 rounded-md ${
                    localState.imageShape === 'square' 
                      ? 'bg-indigo-50 ring-2 ring-indigo-600' 
                      : 'bg-white ring-1 ring-gray-200'
                  }`}
                >
                  <div className="w-8 h-8 bg-gray-200" />
                </button>
                <button
                  type="button"
                  onClick={() => setLocalState(prev => ({ ...prev, imageShape: 'rounded' }))}
                  className={`p-2 rounded-md ${
                    localState.imageShape === 'rounded' 
                      ? 'bg-indigo-50 ring-2 ring-indigo-600' 
                      : 'bg-white ring-1 ring-gray-200'
                  }`}
                >
                  <div className="w-8 h-8 rounded-md bg-gray-200" />
                </button>
              </div>
            </div>

            <div className="flex justify-end space-x-2 pt-2">
              <button
                onClick={() => setActiveSection(null)}
                className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleApplyChanges}
                className="px-3 py-1 text-sm bg-indigo-600 text-white rounded hover:bg-indigo-700"
              >
                Apply
              </button>
            </div>
          </div>
        );
        break;
      case 'headline':
        content = (
          <div className="p-4 bg-white rounded-lg shadow-lg border space-y-4 min-w-[320px]">
            <h3 className="text-lg font-medium text-gray-900">Edit Headline</h3>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Headline Text
              </label>
              <input
                type="text"
                value={localState.headline}
                onChange={(e) => setLocalState(prev => ({ ...prev, headline: e.target.value }))}
                className="block w-full text-sm rounded-md border-gray-300"
                placeholder="Company name"
                maxLength={30}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Font
              </label>
              <CustomFontPicker
                value={localState.headlineFont}
                onChange={(font) => setLocalState(prev => ({ ...prev, headlineFont: font }))}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Color
              </label>
              <ColorPicker
                color={localState.headlineColor}
                onChange={(color) => setLocalState(prev => ({ ...prev, headlineColor: color }))}
                isActive={activeColorPicker === 'headlineColor'}
                onToggle={() => setActiveColorPicker(
                  activeColorPicker === 'headlineColor' ? null : 'headlineColor'
                )}
                inline={true}
              />
            </div>
            <div className="flex justify-end space-x-2 pt-2">
              <button
                onClick={() => setActiveSection(null)}
                className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleApplyChanges}
                className="px-3 py-1 text-sm bg-indigo-600 text-white rounded hover:bg-indigo-700"
              >
                Apply
              </button>
            </div>
          </div>
        );
        break;
      case 'subheadline':
        content = (
          <div className="p-4 bg-white rounded-lg shadow-lg border space-y-4 min-w-[320px]">
            <h3 className="text-lg font-medium text-gray-900">Edit Subheadline</h3>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Subheadline Text
              </label>
              <input
                type="text"
                value={localState.subheadline}
                onChange={(e) => setLocalState(prev => ({ ...prev, subheadline: e.target.value }))}
                className="block w-full text-sm rounded-md border-gray-300"
                placeholder="Subheadline"
                maxLength={40}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Link URL (optional)
              </label>
              <input
                type="text"
                value={localState.headline_url}
                onChange={(e) => setLocalState(prev => ({ ...prev, headline_url: e.target.value }))}
                className="block w-full text-sm rounded-md border-gray-300"
                placeholder="https://example.com"
              />
            </div>
            {localState.headline_url && (
              <div className="flex items-center justify-between">
                <label className="block text-sm font-medium text-gray-700">
                  Show Link Icon
                </label>
                <button
                  type="button"
                  onClick={() => setLocalState(prev => ({ ...prev, showIcon: !prev.showIcon }))}
                  className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                    localState.showIcon ? 'bg-indigo-600' : 'bg-gray-200'
                  }`}
                >
                  <span className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                    localState.showIcon ? 'translate-x-5' : 'translate-x-0'
                  }`} />
                </button>
              </div>
            )}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Font
              </label>
              <CustomFontPicker
                value={localState.subheadlineFont}
                onChange={(font) => setLocalState(prev => ({ ...prev, subheadlineFont: font }))}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Color
              </label>
              <ColorPicker
                color={localState.subheadlineColor}
                onChange={(color) => setLocalState(prev => ({ ...prev, subheadlineColor: color }))}
                isActive={activeColorPicker === 'subheadlineColor'}
                onToggle={() => setActiveColorPicker(
                  activeColorPicker === 'subheadlineColor' ? null : 'subheadlineColor'
                )}
              />
            </div>
            <div className="flex justify-end space-x-2 pt-2">
              <button
                onClick={() => setActiveSection(null)}
                className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleApplyChanges}
                className="px-3 py-1 text-sm bg-indigo-600 text-white rounded hover:bg-indigo-700"
              >
                Apply
              </button>
            </div>
          </div>
        );
        break;
      default:
        return null;
    }

    return (
      <div
        ref={setPopperElement}
        style={popperStyles.popper}
        {...attributes.popper}
        className="z-50 animate-popup"
      >
        {content}
      </div>
    );
  };

  // If not in preview mode, render simple display version
  if (!preview) {
    const formattedUrl = customization?.headline_url ? 
      (!customization.headline_url.startsWith('http') ? `http://${customization.headline_url}` : customization.headline_url)
      : null;

    // Add console.log to debug what we're trying to render
    console.log('Rendering display version with:', {
      headline: customization?.headline,
      subheadline: customization?.subheadline,
      url: formattedUrl,
      photo: customization?.headlinePhoto
    });

    return (
      <div className="flex flex-col">
        <div className="flex items-center">
          <img
            className={`inline-block h-9 w-9 ${getImageShape()}`}
            src={customization?.headlinePhoto || defaultPhotoUrl}
            alt="Profile"
            style={getStyles('image')}
          />
          <div className="ml-3">
            {customization?.headline && (
              <p 
                className="text-sm font-medium" 
                style={getStyles('headline')}
              >
                {customization.headline}
              </p>
            )}
            {customization?.subheadline && (
              <p 
                className="text-xs font-medium flex items-center" 
                style={getStyles('subheadline')}
              >
                {customization.subheadline}
                {formattedUrl && customization?.showIcon && (
                  <ArrowTopRightOnSquareIcon 
                    className="ml-1 h-3 w-3" 
                    style={getStyles('icon')}
                    aria-hidden="true" 
                  />
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Container {...containerProps}>
      <div className="flex flex-col">
        <div className={`flex items-center ${preview ? 'p-3 rounded-lg bg-white' : ''}`}>
          {preview && (
            <div>
              <div 
                className="relative group cursor-pointer"
                onClick={(e) => handleSectionClick('image', e)}
              >
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-20 rounded-full transition-all">
                  <PencilSquareIcon className="h-4 w-4 text-white opacity-0 group-hover:opacity-100" />
                </div>
                <img
                  className={`inline-block h-9 w-9 ${getImageShape()}`}
                  src={customization?.headlinePhoto || defaultPhotoUrl}
                  alt="Profile"
                  style={getStyles('image')}
                />
              </div>
            </div>
          )}

          <div className="ml-3">
            {/* Headline with adaptive hover */}
            {preview ? (
              <div 
                className="group cursor-pointer"
                onClick={(e) => handleSectionClick('headline', e)}
              >
                <p 
                  className="text-sm font-medium px-1 rounded transition-colors duration-150 group-hover:bg-gray-50"
                  style={getStyles('headline')}
                >
                  {customization?.headline || 'Add headline'}
                </p>
              </div>
            ) : (
              <p className="text-sm font-medium" style={getStyles('headline')}>
                {customization?.headline}
              </p>
            )}

            {/* Subheadline with adaptive hover */}
            {preview ? (
              <div 
                className="group cursor-pointer flex items-center"
                onClick={(e) => handleSectionClick('subheadline', e)}
              >
                <p 
                  className="text-xs font-medium px-1 rounded transition-colors duration-150 group-hover:bg-gray-50"
                  style={getStyles('subheadline')}
                >
                  {customization?.subheadline || 'Add subheadline'}
                </p>
                {customization?.headline_url && customization?.showIcon && (
                  <ArrowTopRightOnSquareIcon 
                    className="ml-1 h-3 w-3" 
                    style={getStyles('icon')}
                    aria-hidden="true" 
                  />
                )}
              </div>
            ) : (
              <p 
                className={`text-xs font-medium flex items-center ${formattedUrl ? 'group-hover:text-gray-700' : ''}`}
                style={getStyles('subheadline')}
              >
                {customization?.subheadline}
                {formattedUrl && customization?.showIcon && (
                  <ArrowTopRightOnSquareIcon 
                    className="ml-1 h-3 w-3 group-hover:text-gray-700" 
                    style={getStyles('subheadline').icon}
                    aria-hidden="true" 
                  />
                )}
              </p>
            )}
          </div>
        </div>

        {renderPopperContent()}
      </div>
    </Container>
  );
};

export default HeadlineCard;