import React, { useState, lazy, Suspense } from 'react';
import { Switch, Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import CallToActionForm from './CallToActionForm';
import ShareUrlForm from './ShareUrlForm';
import FileUpload from './FileUpload';
import FormInput from './FormInput';
import FormSwitch from './FormSwitch';
import FormSection from '../dashboard/FormSection';
import ColorPicker from '../components/ColorPicker';
import CustomFontPicker from '../dashboard/fontPicker';
import LeaderboardAppearanceSection from '../components/leaderboard/LeaderboardAppearanceSection';
import { 
  SwatchIcon,
  ArrowPathIcon,
  ShareIcon,
  TrophyIcon,
  MegaphoneIcon,
  DocumentTextIcon,
  BuildingStorefrontIcon,
  Cog6ToothIcon
} from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ConfigureStyleDisclosure = ({ title, children }) => (
  <Disclosure>
    {({ open }) => (
      <div className="rounded-lg bg-gray-50">
        <Disclosure.Button className="flex w-full justify-between px-4 py-3 text-left text-sm font-medium text-gray-900 hover:bg-gray-100 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
          <span>{title}</span>
          <ChevronDownIcon
            className={`transform transition-transform duration-200 ease-in-out ${
              open ? 'rotate-180' : 'rotate-0'
            } h-5 w-5 text-gray-500`}
          />
        </Disclosure.Button>
        <Transition
          enter="transition-all duration-300 ease-out"
          enterFrom="transform -translate-y-2 opacity-0"
          enterTo="transform translate-y-0 opacity-100"
          leave="transition-all duration-150 ease-in"
          leaveFrom="transform translate-y-0 opacity-100"
          leaveTo="transform -translate-y-2 opacity-0"
        >
          <Disclosure.Panel>
            <div className="space-y-4 p-4">
              {children}
            </div>
          </Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
);

const ResultsScreenSection = ({ options = {}, onOptionsUpdate, params, gameType }) => {
  const [activeColorPicker, setActiveColorPicker] = useState(null);
  const [ResultsAppearanceComponent, setResultsAppearanceComponent] = useState(null);
  const [errorLoadingComponent, setErrorLoadingComponent] = useState(false);

  const handleEndCardUpdate = (updates) => {
    onOptionsUpdate({
      ...options,
      theme: {
        ...options.theme,
        colors: {
          ...options.theme?.colors,
          endCard: {
            ...options.theme?.colors?.endCard,
            ...updates
          }
        }
      }
    });
  };

  const handleHeadlineThemeUpdate = (updates) => {
    onOptionsUpdate({
      ...options,
      theme: {
        ...options.theme,
        colors: {
          ...options.theme?.colors,
          endCard: {
            ...options.theme?.colors?.endCard,
            ...updates
          }
        }
      }
    });
  };

  React.useEffect(() => {
    const loadComponent = async () => {
      try {
        const module = await import(`./${gameType}/ResultsAppearanceSection`);
        setResultsAppearanceComponent(() => module.default);
      } catch (error) {
        console.error(`Error loading results appearance for ${gameType}:`, error);
        setErrorLoadingComponent(true);
      }
    };

    if (gameType) {
      loadComponent();
    }
  }, [gameType]);

  return (
    <div className="space-y-8 px-1 sm:px-4 mt-5">
      <FormSection 
        title="Branding" 
        subtitle="Configure your company branding"
        icon={<BuildingStorefrontIcon className="w-5 h-5" />}
        infoTitle="Company Branding"
        infoContent="Add your company's branding to the results screen. This includes your logo, headline, and brand colors."
      >
        <div className="space-y-6">
          <FormSwitch
            name="hideHeadline"
            label="Show Headline"
            subtitle="Display your company headline configured in your settings"
            checked={options?.endCard?.showHeadline !== false}
            onChange={(checked) => onOptionsUpdate({
              ...options,
              endCard: {
                ...options.endCard,
                showHeadline: checked
              }
            })}
          />

          {options?.endCard?.showHeadline !== false && (
            <ConfigureStyleDisclosure 
              title="Headline Style"
              icon={<Cog6ToothIcon className="w-4 h-4 text-gray-500" />}
            >
              <ColorPicker
                label="Headline Text"
                subtitle="Override the default headline text color"
                color={options?.theme?.colors?.endCard?.headlineColor}
                onChange={(color) => handleHeadlineThemeUpdate({ 
                  headlineColor: color,
                })}
                isActive={activeColorPicker === 'endCardHeadlineColor'}
                onToggle={() => setActiveColorPicker(activeColorPicker === 'endCardHeadlineColor' ? null : 'endCardHeadlineColor')}
              />
              <ColorPicker
                label="Subheadline Text"
                subtitle="Override the default subheadline text color"
                color={options?.theme?.colors?.endCard?.subheadlineColor}
                onChange={(color) => handleHeadlineThemeUpdate({ 
                  subheadlineColor: color,
                })}
                isActive={activeColorPicker === 'endCardSubheadlineColor'}
                onToggle={() => setActiveColorPicker(activeColorPicker === 'endCardSubheadlineColor' ? null : 'endCardSubheadlineColor')}
              />
            </ConfigureStyleDisclosure>
          )}
        </div>
      </FormSection>

      <FormSection 
        title="Content" 
        subtitle="Configure the end screen content and appearance"
        icon={<SwatchIcon className="w-5 h-5" />}
        infoTitle="End Screen Content"
        infoContent="Customize how your end screen looks, including the background, headline, and overall layout."
      >
        <div className="space-y-6">
          <div className="space-y-4">
            <ConfigureStyleDisclosure title="Background Style">
              <ColorPicker
                label="Card Background"
                subtitle="Background color of the end card"
                color={options?.theme?.colors?.endCard?.background}
                opacity={options?.theme?.colors?.endCard?.opacity}
                onChange={(color) => handleEndCardUpdate({ background: color })}
                onOpacityChange={(value) => handleEndCardUpdate({ opacity: value })}
                showOpacity={true}
                isActive={activeColorPicker === 'endCardBackground'}
                onToggle={() => setActiveColorPicker(activeColorPicker === 'endCardBackground' ? null : 'endCardBackground')}
              />
            </ConfigureStyleDisclosure>
          </div>

          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-700">Thank You Message</h3>
            <FormInput
              label="Message Text"
              name="thankYouMessage"
              onChange={(e) => onOptionsUpdate({ 
                ...options, 
                endCard: {
                  ...options.endCard,
                  thankYouMessage: e.target.value 
                }
              })}
              placeholder="Thanks for playing!"
              type="text"
              options={options.endCard || {}}
              maxLength={100}
            />
            <ConfigureStyleDisclosure title="Message Style">
              <ColorPicker
                label="Message Color"
                subtitle="Color of the thank you message"
                color={options?.theme?.colors?.endCard?.thankYouText}
                onChange={(color) => handleEndCardUpdate({ thankYouText: color })}
                isActive={activeColorPicker === 'thankYouText'}
                onToggle={() => setActiveColorPicker(activeColorPicker === 'thankYouText' ? null : 'thankYouText')}
              />
              <CustomFontPicker 
                label="Message Font"
                subtitle="Font used for thank you message"
                value={options?.theme?.typography?.thankYouFont || 'Inter'}
                onChange={(font) => onOptionsUpdate({
                  ...options,
                  theme: {
                    ...options.theme,
                    typography: {
                      ...options.theme?.typography,
                      thankYouFont: font
                    }
                  }
                })}
              />
            </ConfigureStyleDisclosure>
          </div>
        </div>
      </FormSection>

      <FormSection 
        title="Play Again" 
        subtitle="Configure the replay button"
        icon={<ArrowPathIcon className="w-5 h-5" />}
        infoTitle="Play Again Button"
        infoContent="Customize the appearance and text of the button that allows players to restart the game."
      >
        <div className="space-y-6">
          <FormInput
            label="Button Text"
            name="playAgainText"
            onChange={(e) => onOptionsUpdate({ 
              ...options, 
              endCard: {
                ...options.endCard,
                playAgainText: e.target.value 
              }
            })}
            placeholder="Play again"
            type="text"
            options={options.endCard || {}}
            maxLength={20}
          />
          <ConfigureStyleDisclosure title="Button Style">
            <ColorPicker
              label="Background"
              subtitle="Background color of the play again button"
              color={options?.theme?.colors?.endCard?.playAgainBackground}
              onChange={(color) => handleEndCardUpdate({ playAgainBackground: color })}
              isActive={activeColorPicker === 'playAgainBackground'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'playAgainBackground' ? null : 'playAgainBackground')}
            />
            <ColorPicker
              label="Text Color"
              subtitle="Text color of the play again button"
              color={options?.theme?.colors?.endCard?.playAgainText}
              onChange={(color) => handleEndCardUpdate({ playAgainText: color })}
              isActive={activeColorPicker === 'playAgainText'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'playAgainText' ? null : 'playAgainText')}
            />
            <CustomFontPicker 
              label="Button Font"
              subtitle="Font used for play again button"
              value={options?.theme?.typography?.playAgainFont || 'Inter'}
              onChange={(font) => onOptionsUpdate({
                ...options,
                theme: {
                  ...options.theme,
                  typography: {
                    ...options.theme?.typography,
                    playAgainFont: font
                  }
                }
              })}
            />
          </ConfigureStyleDisclosure>
        </div>
      </FormSection>

      <FormSection 
        title="Leaderboard" 
        subtitle="Configure the global leaderboard"
        icon={<TrophyIcon className="w-5 h-5" />}
        infoTitle="Game Leaderboard"
        infoContent="Enable and customize the leaderboard to show top scores and encourage competition."
      >
        <div className="space-y-6">
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={options?.leaderboard?.enabled ?? false}
              onChange={(enabled) => onOptionsUpdate({
                ...options,
                leaderboard: {
                  ...options?.leaderboard,
                  enabled
                }
              })}
              className={classNames(
                options?.leaderboard?.enabled ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  options?.leaderboard?.enabled ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3">
              <span className="text-sm font-medium text-gray-700">Show Leaderboard</span>
              <p className="text-sm text-gray-500">Display a global leaderboard of top scores</p>
            </Switch.Label>
          </Switch.Group>

          {options?.leaderboard?.enabled && (
            <ConfigureStyleDisclosure title="Leaderboard Style">
              <LeaderboardAppearanceSection
                safeOptions={options}
                onOptionsUpdate={onOptionsUpdate}
                activeColorPicker={activeColorPicker}
                setActiveColorPicker={setActiveColorPicker}
              />
            </ConfigureStyleDisclosure>
          )}
        </div>
      </FormSection>

      <FormSection 
        title="Call to Action" 
        subtitle="Configure the primary action button"
        icon={<MegaphoneIcon className="w-5 h-5" />}
        infoTitle="Call to Action"
        infoContent="Customize the main action button that players see after completing the game."
      >
        <div className="space-y-6">
          <CallToActionForm 
            handleChange={(e) => onOptionsUpdate({ ...options, [e.target.name]: e.target.value })} 
            options={options} 
          />
          <ConfigureStyleDisclosure title="Button Style">
            <ColorPicker
              label="Background"
              subtitle="Background color of the call-to-action button"
              color={options?.theme?.colors?.endCard?.ctaButtonBackground}
              onChange={(color) => handleEndCardUpdate({ ctaButtonBackground: color })}
              isActive={activeColorPicker === 'ctaButtonBackground'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'ctaButtonBackground' ? null : 'ctaButtonBackground')}
            />
            <ColorPicker
              label="Text"
              subtitle="Text color of the call-to-action button"
              color={options?.theme?.colors?.endCard?.ctaButtonText}
              onChange={(color) => handleEndCardUpdate({ ctaButtonText: color })}
              isActive={activeColorPicker === 'ctaButtonText'}
              onToggle={() => setActiveColorPicker(activeColorPicker === 'ctaButtonText' ? null : 'ctaButtonText')}
            />
            <CustomFontPicker 
              label="CTA Button Font"
              subtitle="Font used for the call-to-action button"
              value={options?.theme?.typography?.ctaButtonFont || 'Inter'}
              onChange={(font) => onOptionsUpdate({
                ...options,
                theme: {
                  ...options.theme,
                  typography: {
                    ...options.theme?.typography,
                    ctaButtonFont: font
                  }
                }
              })}
            />
          </ConfigureStyleDisclosure>
        </div>
      </FormSection>

      <FormSection 
        title="Sharing" 
        subtitle="Configure social sharing options"
        icon={<ShareIcon className="w-5 h-5" />}
        infoTitle="Social Sharing"
        infoContent="Customize how your game appears when shared on social media platforms."
      >
        <div className="space-y-6">
          <FileUpload
            onSuccess={onOptionsUpdate}
            onDelete={onOptionsUpdate}
            field="sharingPhoto"
            params={params}
            id={params.id}
            collection="campaign"
            options={options}
            displayText="Social Share Card Photo"
            displayPreview={true}
            subtitle="This image will be shown when your game is shared on social media"
          />

          <ShareUrlForm 
            handleChange={(e) => onOptionsUpdate({ ...options, [e.target.name]: e.target.value })} 
            options={options} 
          />

          <div className="space-y-4">
            <h3 className="text-sm font-medium text-gray-700">Share Button</h3>
            <FormInput
              label="Button Text"
              name="shareText"
              onChange={(e) => onOptionsUpdate({ 
                ...options, 
                endCard: {
                  ...options.endCard,
                  shareText: e.target.value 
                }
              })}
              placeholder="Share"
              type="text"
              options={options.endCard || {}}
              maxLength={20}
            />
            <ConfigureStyleDisclosure title="Button Style">
              <ColorPicker
                label="Background"
                subtitle="Background color of the share button"
                color={options?.theme?.colors?.endCard?.shareBackground}
                onChange={(color) => handleEndCardUpdate({ shareBackground: color })}
                isActive={activeColorPicker === 'shareBackground'}
                onToggle={() => setActiveColorPicker(activeColorPicker === 'shareBackground' ? null : 'shareBackground')}
              />
              <ColorPicker
                label="Text Color"
                subtitle="Text color of the share button"
                color={options?.theme?.colors?.endCard?.shareText}
                onChange={(color) => handleEndCardUpdate({ shareText: color })}
                isActive={activeColorPicker === 'shareText'}
                onToggle={() => setActiveColorPicker(activeColorPicker === 'shareText' ? null : 'shareText')}
              />
              <CustomFontPicker 
                label="Button Font"
                subtitle="Font used for share button"
                value={options?.theme?.typography?.shareFont || 'Inter'}
                onChange={(font) => onOptionsUpdate({
                  ...options,
                  theme: {
                    ...options.theme,
                    typography: {
                      ...options.theme?.typography,
                      shareFont: font
                    }
                  }
                })}
              />
            </ConfigureStyleDisclosure>
          </div>
        </div>
      </FormSection>

      {errorLoadingComponent ? (
        <div className="text-red-500">Error loading results appearance for this game type.</div>
      ) : (
        ResultsAppearanceComponent && (
          <Suspense fallback={null}>
            <FormSection 
              title="Results Appearance" 
              subtitle="Configure game-specific result displays"
              icon={<DocumentTextIcon className="w-5 h-5" />}
              infoTitle="Game Results"
              infoContent="Customize how game-specific results and statistics are displayed to players."
            >
              <ResultsAppearanceComponent
                safeOptions={options}
                onOptionsUpdate={onOptionsUpdate}
                activeColorPicker={activeColorPicker}
                setActiveColorPicker={setActiveColorPicker}
              />
            </FormSection>
          </Suspense>
        )
      )}
    </div>
  );
};

export default ResultsScreenSection;