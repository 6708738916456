import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { ArrowTopRightOnSquareIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { gameTypes } from '../data/GameTypes'; // Import the gameTypes array

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Step1({ selectedGameType, setSelectedGameType, isFreeUser }) {
  return (
    <div>
      <RadioGroup value={selectedGameType} onChange={setSelectedGameType}>
        <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
          Select type
        </RadioGroup.Label>
        <p className="text-sm text-gray-500">
          For more information on types, see 
          <a 
            href="/games" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-indigo-600 hover:text-indigo-500 ml-1 inline-flex items-center"
          >
            game library
            <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" aria-hidden="true" />
          </a>
        </p>
        <div className="mt-4 grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-4">
          {gameTypes.map((gameType) => {
            const isDisabled = isFreeUser && gameType.role !== 'free';

            return (
              <RadioGroup.Option
                key={gameType.id}
                value={gameType}
                className={({ active }) =>
                  classNames(
                    active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                    isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                    'relative flex rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                  )
                }
                disabled={isDisabled}
              >
                {({ checked, active }) => (
                  <>
                    <div className="flex w-full items-center">
                    
                      <div className="flex flex-col w-full">
                        <div className="flex justify-between items-center w-full">
                          
                          <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900 flex items-center">
                          <div className="flex-shrink-0 mr-1 text-indigo-600">
                        <gameType.icon className="h-4 w-4" aria-hidden="true" />
                      </div>
                            {gameType.name}
                          </RadioGroup.Label>
                          {(isFreeUser && gameType.role && gameType.role!="free") && (
                            <span
                              className={classNames(
                                gameType.role === 'premium' ? 'bg-yellow-400' : 'bg-indigo-100',
                                'text-indigo-800 text-xs font-semibold px-2 py-0.5 rounded inline-flex items-center capitalize'
                              )}
                            >
                              {gameType.role}
                              {isDisabled && (
                                <LockClosedIcon className="ml-1 h-3 w-3" aria-hidden="true" />
                              )}
                            </span>
                          )}
                        </div>
                        <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                          {gameType.description}
                        </RadioGroup.Description>
                      </div>
                    </div>
                    <span
                      className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? 'border-indigo-600' : 'border-transparent',
                        'pointer-events-none absolute -inset-px rounded-lg'
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            );
          })}
        </div>
      </RadioGroup>
    </div>
  );
}