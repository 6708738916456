import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path based on your file structure

/**
 * Fetch campaign statistics by campaign ID, including today vs yesterday stats.
 *
 * @param {string} campaignId - The campaign ID.
 * @returns {Promise<Object>} - A promise that resolves to a stats object.
 */
export async function fetchCampaignStats(campaignId) {
  try {
    const gameSummaryRef = doc(db, 'gameSummary', campaignId);
    const gameSummaryDoc = await getDoc(gameSummaryRef);

    let totalPlayCount = 0;
    let totalShared = 0;
    let totalCta = 0;


    if (gameSummaryDoc.exists()) {
      const data = gameSummaryDoc.data();

      totalPlayCount = data.playCount || 0;
      totalShared = data.totalShared || 0;
      totalCta = data.totalCta || 0;
    }

    // Fetch daily stats for today and yesterday
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const dailyStatsRef = collection(db, `gameSummary/${campaignId}/dailyStats`);

    // Query for today's data
    const todayQuery = query(dailyStatsRef, where("__name__", "==", today.toISOString().split('T')[0]));
    const todayDocs = await getDocs(todayQuery);
    let todayPlayCount = 0, todayShared = 0, todayCta = 0;

    todayDocs.forEach(doc => {
      const data = doc.data();
      todayPlayCount += data.playCount || 0;
      todayShared += data.totalShared || 0;
      todayCta += data.totalCta || 0;
    });

    // Query for yesterday's data
    const yesterdayQuery = query(dailyStatsRef, where("__name__", "==", yesterday.toISOString().split('T')[0]));
    const yesterdayDocs = await getDocs(yesterdayQuery);
    let yesterdayPlayCount = 0, yesterdayShared = 0, yesterdayCta = 0;

    yesterdayDocs.forEach(doc => {
      const data = doc.data();
      yesterdayPlayCount += data.playCount || 0;
      yesterdayShared += data.totalShared || 0;
      yesterdayCta += data.totalCta || 0;
    });

 
    return {
      totalPlayCount: totalPlayCount.toLocaleString(),
      totalShared: totalShared.toLocaleString(),
      totalCta: totalCta.toLocaleString(),
    
      today: {
        playCount: todayPlayCount.toLocaleString(),
        shared: todayShared.toLocaleString(),
        cta: todayCta.toLocaleString(),
      },
      yesterday: {
        playCount: yesterdayPlayCount.toLocaleString(),
        shared: yesterdayShared.toLocaleString(),
        cta: yesterdayCta.toLocaleString(),
      },
      changes: {
        playCountChange: todayPlayCount - yesterdayPlayCount,
        sharedChange: todayShared - yesterdayShared,
        ctaChange: todayCta - yesterdayCta,
      }
    };
  } catch (error) {
    console.error('Error fetching stats: ', error);
    return getDefaultStats();
  }
}

/**
 * Get default statistics when no data is available.
 *
 * @returns {Object} - An object with default stats.
 */
export function getDefaultStats() {
  return {
    totalPlayCount: '0',
    totalShared: '0',
    totalCta: '0',
    today: {
      playCount: '0',
      shared: '0',
      cta: '0',
    },
    yesterday: {
      playCount: '0',
      shared: '0',
      cta: '0',
    },
    changes: {
      playCountChange: 0,
      sharedChange: 0,
      ctaChange: 0,
    }
  };
}

export async function fetchUserStats(campaignIds) {
  try {
    const today = new Date();
    const last7DaysDate = new Date(today);
    last7DaysDate.setDate(today.getDate() - 2);

    const todayString = today.toISOString().split('T')[0]; 
    const last7DaysString = last7DaysDate.toISOString().split('T')[0]; 

    // Create date range array first
    const dateRange = [];
    let currentDate = new Date(last7DaysDate);
    while (currentDate <= today) {
      dateRange.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Batch get all documents for all campaigns at once
    const batchPromises = campaignIds.map(campaignId => {
      const dailyStatsRef = collection(db, `gameSummary/${campaignId}/dailyStats`);
      // Query only the dates we need instead of getting all documents
      const q = query(
        dailyStatsRef,
        where('__name__', '>=', last7DaysString),
        where('__name__', '<=', todayString)
      );
      return getDocs(q);
    });

    // Wait for all queries to complete
    const snapshots = await Promise.all(batchPromises);

    // Initialize results
    const statsMap = new Map(
      dateRange.map(date => [date, { plays: 0, cta: 0, shares: 0 }])
    );

    // Process all snapshots
    snapshots.forEach(querySnapshot => {
      querySnapshot.forEach(doc => {
        const date = doc.id;
        const data = doc.data();
        
        if (statsMap.has(date)) {
          const currentStats = statsMap.get(date);
          currentStats.plays += data.playCount || 0;
          currentStats.cta += data.totalCta || 0;
          currentStats.shares += data.totalShared || 0;
        }
      });
    });

    // Convert Map to arrays for chart
    const labels = dateRange;
    const playCounts = dateRange.map(date => statsMap.get(date).plays);
    const ctaCounts = dateRange.map(date => statsMap.get(date).cta);
    const shareCounts = dateRange.map(date => statsMap.get(date).shares);

    return { labels, playCounts, ctaCounts, shareCounts };

  } catch (error) {
    console.error("Error fetching user stats: ", error);
    return { labels: [], playCounts: [], ctaCounts: [], shareCounts: [] };
  }
}