import React, { useState, useEffect, Suspense, useRef } from 'react';
import { PlayIcon, CogIcon, AdjustmentsVerticalIcon, ShareIcon, ArrowLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'; // Import meaningful icons
import { InformationCircleIcon } from '@heroicons/react/24/outline';  // Add this import
import Help from '../components/DropdownHelp';  // Add this import
import StartScreenSection from './StartScreenSection';
import ResultsScreenSection from './ResultsScreenSection';
import DefaultLoading from '../common/DefaultLoading';

const Form = ({ options, onOptionsUpdate, params, game }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [showText, setShowText] = useState(true); // To control text visibility during transition
  const [showContent, setShowContent] = useState(false); // To control content visibility after delay
  const [selectedFont, setSelectedFont] = useState(options.font || 'Default');
  const [GameSetupComponent, setGameSetupComponent] = useState(null);
  const contentRefs = useRef({}); // Store refs to each section's content
  const [contentHeights, setContentHeights] = useState({}); // Store heights of each section

  // Dynamically load the GameSetup component based on the gameType
  useEffect(() => {
    if (game?.gameType) {
      const loadGameSetup = async () => {
        try {
          const module = await import(`../games/${game.gameType}/GameSetup`);
          setGameSetupComponent(() => module.default);
        } catch (error) {
          console.error('Error loading GameSetup component', error);
        }
      };
      loadGameSetup();
    }
  }, [game?.gameType]);

  // Handle font change
  const handleFontChange = (nextFont) => {
    if (nextFont === null) {
      setSelectedFont('Default');
      onOptionsUpdate({ ...options, font: '' });
    } else {
      setSelectedFont(nextFont.family);
      onOptionsUpdate({ ...options, font: nextFont.family });
    }
  };

  // Handle color change complete (for color picker)
  const handleColorChangeComplete = (color) => {
    onOptionsUpdate({ ...options, colorButton: color.hex });
  };

  const handlePreview = () => {
    window.open(`/play/${params.id}`, '_blank');
  };

  // Toggle the active section
  const toggleSection = (id) => {
    // If switching between sections, reset showContent to false
    if (id !== activeSection) {
      setShowContent(false); // Hide current content immediately
      setTimeout(() => {
        setActiveSection(id); // Update the active section after hiding content
        setShowText(false); // Hide text when sidebar shrinks

        // Delay showing content for the new section by 150ms
        setTimeout(() => {
          setShowContent(true);
        }, 300);
      }, 0); // Ensure the current content is hidden before loading the new one
    }
  };

  // Back button to reset state to full width view
  const handleBack = () => {
    setShowContent(false); // Hide content immediately
    setActiveSection(null);
    setTimeout(() => {
      setShowText(true); // Delay showing text until after width transition
    }, 300); // Adjust timing based on the duration of your width transition
  };

  // Function to update heights of content when activeSection changes or content changes dynamically
  const updateHeights = () => {
    const newHeights = {};
    Object.keys(contentRefs.current).forEach((key) => {
      const element = contentRefs.current[key];
      if (element) {
        newHeights[key] = element.scrollHeight + 'px';
      }
    });
    setContentHeights(newHeights);
  };

  // Recalculate heights when activeSection changes
  useEffect(() => {
    updateHeights();
  }, [activeSection]);

  // Form sections definition
  const formSections = [
    {
      id: 'startScreen',
      title: 'Start Screen',
      description: 'Customize game title, description, and appearance of the start button',
      icon: <PlayIcon className="w-5 h-5" />,
      content: (
        <StartScreenSection
          options={options}
          onOptionsUpdate={onOptionsUpdate}
          params={params}
        />
      ),
    },
    {
      id: 'gameSetup',
      title: 'Game Setup',
      description: 'Configure game mechanics, difficulty settings, and gameplay options',
      icon: <CogIcon className="w-5 h-5" />,
      content: (
        <>
          {GameSetupComponent ? (
            <Suspense fallback={<DefaultLoading />}>
              <GameSetupComponent options={options} onOptionsUpdate={onOptionsUpdate} />
            </Suspense>
          ) : (
            <p>Loading game setup...</p>
          )}
        </>
      ),
    },
    {
      id: 'resultsScreen',
      title: 'Results Screen',
      description: 'Design end-game experience, call-to-action buttons, and sharing features',
      icon: <ShareIcon className="w-5 h-5" />,
      content: (
        <ResultsScreenSection
          options={options}
          onOptionsUpdate={onOptionsUpdate}
          params={params}
          gameType={game?.gameType}
        />
      ),
    },
  ];

  return (
    <div className="h-full relative flex flex-col">
      {!activeSection ? (
        // Full width list of options when no section is selected
        <div className="w-full min-h-full flex flex-col p-6">
          {/* Subtle intro message */}
          <div className="mb-6 text-sm text-gray-600 dark:text-gray-400 border-l-4 border-indigo-200 dark:border-indigo-800 pl-4 py-2">
            Configure your game by selecting one of the sections below. Each section allows you to customize different aspects of the player experience.
          </div>

          {/* Section Grid - Full width, with flex-1 to take available space */}
          <div className="space-y-4 flex-1">
            {formSections.map((section) => (
              <button
                key={section.id}
                onClick={(e) => {
                  e.preventDefault();
                  toggleSection(section.id);
                }}
                className="w-full group bg-white dark:bg-gray-800 p-5 rounded-xl transition-all duration-300
                  hover:shadow-lg hover:-translate-x-1
                  border border-gray-100 dark:border-gray-700
                  hover:border-indigo-200 dark:hover:border-indigo-800
                  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2
                  flex items-center gap-4"
              >
                {/* Icon */}
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 rounded-xl bg-indigo-50 dark:bg-indigo-900/30 flex items-center justify-center
                    group-hover:bg-indigo-100 dark:group-hover:bg-indigo-900/50 transition-colors">
                    <div className="text-indigo-600 dark:text-indigo-400">
                      {section.icon}
                    </div>
                  </div>
                </div>

                {/* Content */}
                <div className="flex-1 text-left min-w-0">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white group-hover:text-indigo-600 dark:group-hover:text-indigo-400">
                    {section.title}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400 leading-relaxed">
                    {section.description}
                  </p>
                </div>

                {/* Arrow */}
                <div className="flex-shrink-0 text-gray-400 dark:text-gray-500 group-hover:text-indigo-600 dark:group-hover:text-indigo-400">
                  <ChevronRightIcon className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </div>
              </button>
            ))}
          </div>

          {/* Footer with help text and icon */}
          <div className="mt-auto pt-6 border-t border-gray-100 dark:border-gray-700 flex items-center justify-end gap-2">
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Help us improve, provide feedback or submit your own ideas
            </span>
            <Help align="right" position="above" />
          </div>
        </div>
      ) : (
        // Content Area when section is selected
        <div className="h-full overflow-y-auto">
          <div className="p-4 sm:p-6">
            {/* Title and Back Button */}
            <div className="flex items-center mb-6">
              <button
                onClick={handleBack}
                className="flex items-center gap-1.5 px-2.5 py-1.5 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 rounded-md transition-colors"
              >
                <ArrowLeftIcon className="w-5 h-5" />
                <span className="text-sm font-medium">Back</span>
              </button>
              <div className="ml-4 pl-4 border-l border-gray-200 dark:border-gray-700">
                <h2 className="font-semibold text-xl text-gray-900 dark:text-white">
                  {formSections.find(s => s.id === activeSection)?.title}
                </h2>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {formSections.find(s => s.id === activeSection)?.description}
                </p>
              </div>
            </div>

            {/* Content of the selected section */}
            {formSections.map((section) => (
              <div
                key={section.id}
                className={`transition-all duration-300 ${
                  activeSection === section.id ? 'opacity-100' : 'hidden opacity-0'
                }`}
              >
                {section.content}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;